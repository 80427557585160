import type { MenuProps } from "antd"
import { FileDownIcon, FileText, Table2, TableIcon } from "lucide-react"

import { MimeType } from "../../types/mimetype"
import type { DownloadFormat } from "./api"

const SUPPORTED_ORIGINAL_MIMETYPES = [
  MimeType.XLSX,
  MimeType.XLSM,
  MimeType.CSV,
]

export const canImportAsQuestionnaire = (
  originalMimetype: MimeType,
): boolean => {
  // TODO(mgraczyk): Implement and remove this (replace with true)
  return SUPPORTED_ORIGINAL_MIMETYPES.includes(originalMimetype)
}

export const getDownloadMenuItems = (
  originalMimeType: MimeType,
  onDownloadConfirm: (item: { key: DownloadFormat }) => void,
  downloading?: boolean,
): (NonNullable<MenuProps["items"]>[number] & { key: DownloadFormat })[] => {
  return [
    ...(SUPPORTED_ORIGINAL_MIMETYPES.includes(originalMimeType)
      ? [
          {
            label: "Original",
            key: "original" as const,
            icon: <FileDownIcon size={16} />,
            disabled: downloading,
            onClick: onDownloadConfirm as MenuProps["onClick"],
          },
        ]
      : []),
    {
      label: "Simplified CSV",
      key: "csv",
      icon: <TableIcon size={16} />,
      disabled: downloading,
      onClick: onDownloadConfirm as MenuProps["onClick"],
    },
    {
      label: "Simplified DOCX",
      key: "docx",
      icon: <FileText size={16} />,
      disabled: downloading,
      onClick: onDownloadConfirm as MenuProps["onClick"],
    },
    {
      label: "Simplified XLSX",
      key: "xlsx",
      icon: <Table2 size={16} />,
      disabled: downloading,
      onClick: onDownloadConfirm as MenuProps["onClick"],
    },
  ]
}
