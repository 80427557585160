import { AxiosError } from "axios"
import type { User as AuthUser } from "firebase/auth"
import { useCallback, useState } from "react"

import { getOauthStateParam } from "../auth/api"
import { getGoogleAccessToken } from "../auth/google"
import { GOOGLE_PROVIDER } from "../credentials/providerTypes"
import { doOauthFlow } from "./useUserCredential/doOauthFlow"

const loadAccessToken = async (
  requiredScopes: string[],
  credentialKind: "USER" | "GROUP",
): Promise<string | null> => {
  // Check if the access token is expired.
  try {
    const {
      data: { accessToken },
    } = await getGoogleAccessToken({
      credentialKind,
      requiredScopes,
    })
    return accessToken
  } catch (error) {
    if (
      error instanceof AxiosError &&
      ((error.response?.data ?? {}) as { code?: number }).code === 404
    ) {
      return null
    }
    throw error
  }
}

interface UseGoogleAccessTokenResult {
  getAccessToken: () => Promise<string>
}

const useGoogleAccessToken = (
  authUser: AuthUser,
  requiredScopes: string[],
  credentialKind: "USER" | "GROUP" = "USER",
): UseGoogleAccessTokenResult => {
  const [googleAccessToken, setGoogleAccessToken] = useState<string | null>(
    null,
  )

  const getAccessToken = useCallback(async () => {
    if (googleAccessToken) {
      return googleAccessToken
    }

    // Try to get existing token.
    let accessToken: string | null
    if ((accessToken = await loadAccessToken(requiredScopes, credentialKind))) {
      setGoogleAccessToken(accessToken)
      return accessToken
    }

    const { data } = await getOauthStateParam({
      credentialKind,
    })
    await doOauthFlow(GOOGLE_PROVIDER, data, {
      // space-delimited list of scopes
      scope: requiredScopes.join(" "),
      ...(authUser.email ? { login_hint: authUser.email } : {}),
    })

    // Try again, should be available now.
    if ((accessToken = await loadAccessToken(requiredScopes, credentialKind))) {
      setGoogleAccessToken(accessToken)
      return accessToken
    }

    throw new Error("Failed to get access token")
  }, [googleAccessToken, requiredScopes, credentialKind, authUser.email])

  return {
    getAccessToken,
  }
}

export default useGoogleAccessToken
