import { Popover } from "antd"
import { useCallback, useState } from "react"

import DriveLogo from "../assets/img/drive_logo.svg"
import AddDriveDocumentForm from "../components/AddDriveDocumentForm"
import type { CredentialMetadata } from "../credentials/providerTypes"
import useDrivePicker from "../hooks/useDrivePicker"
import useErrorPopup from "../hooks/useErrorPopup"
import { removeSource, updateOrCreateSource } from "../sources/api"
import type { GoogleDriveSource } from "../sources/types"
import IntegrationBlock from "./IntegrationBlock"

interface DrivePopoverContentProps {
  onClose: () => void
}

const DrivePopoverContent: React.FC<DrivePopoverContentProps> = ({
  onClose,
}) => {
  return (
    <div className="w-[600px]">
      <AddDriveDocumentForm
        onClose={onClose}
        isCompletedQuestionnaireActions={true}
      />
    </div>
  )
}

interface Props {
  source: GoogleDriveSource | undefined
  loading: boolean
  credential: CredentialMetadata | undefined
  connectCredential: () => Promise<void>
  disconnectCredential: () => Promise<void>
  notEnabled?: boolean
}

// TODO(mgraczyk): Combine with FilePickerIntegrationBlock
const GoogleDriveIntegrationBlock: React.FC<Props> = ({
  source,
  loading,
  credential,
  connectCredential,
  disconnectCredential,
  notEnabled,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  // TODO(mgraczyk): Implement modify button by moving add from drive button
  // functionality here. Not trivial because of the workflow wizard.
  useDrivePicker()

  const { handleSuccess, handleError } = useErrorPopup()

  const onEdit = useCallback(() => {
    setPopoverOpen(true)
  }, [])

  const missingCredential = !credential
  const missingSource = !source

  const connectSource = useCallback(async () => {
    setProcessing(true)
    try {
      if (missingCredential) {
        await connectCredential()
      }
      if (missingSource) {
        await updateOrCreateSource({
          kind: "GOOGLE_DRIVE",
          source_meta: {},
        })
      }
      onEdit()
    } catch (error) {
      handleError({ error, prefix: "Failed to connect Google Drive" })
    } finally {
      setProcessing(false)
    }
  }, [connectCredential, missingCredential, missingSource, onEdit, handleError])

  const hidePopover = useCallback(() => {
    setPopoverOpen(false)
  }, [setPopoverOpen])

  const onDisconnect = useCallback(async () => {
    setProcessing(true)
    try {
      if (!missingSource) {
        await removeSource(source.oid)
      }
      if (!missingCredential) {
        await disconnectCredential()
      }
      handleSuccess(
        "Successfully disconnected. Your calls will be removed in the background",
      )
    } catch (error) {
      handleError({ error, prefix: "Failed to disconnect Google Drive" })
    } finally {
      setProcessing(false)
    }
  }, [
    source?.oid,
    missingSource,
    missingCredential,
    disconnectCredential,
    handleSuccess,
    handleError,
  ])

  return (
    <Popover
      content={<DrivePopoverContent onClose={hidePopover} />}
      onOpenChange={setPopoverOpen}
      open={popoverOpen}
      // This is to make sure the popover is lower than Google Drive picker which has a z-index of 1001
      zIndex={1000}
      trigger="click"
    >
      <IntegrationBlock
        loading={loading || processing}
        name="Google Drive"
        connected={!missingCredential && !missingSource}
        onEdit={onEdit}
        onConnect={connectSource}
        onDisconnect={onDisconnect}
        logo={DriveLogo}
        notEnabled={notEnabled}
        disabledReason={
          source?.removal_requested
            ? "Removal requested"
            : popoverOpen
              ? "Use the picker to modify the source"
              : undefined
        }
      />
    </Popover>
  )
}
export default GoogleDriveIntegrationBlock
