import type { User as AuthUser } from "firebase/auth"

import type { QuiltFirebaseClaims } from "../types/common"

export async function getUserClaims(
  user: AuthUser,
  forceRefresh: boolean = false,
): Promise<QuiltFirebaseClaims> {
  const tokenResult = await user.getIdTokenResult(forceRefresh)
  return tokenResult.claims as unknown as QuiltFirebaseClaims
}
