export const ATLASSIAN_PROVIDER = "atlassian"
export const GONG_PROVIDER = "gong"
export const GOOGLE_PROVIDER = "google.com"
export const NOTION_PROVIDER = "notion"
export const SALESFORCE_PROVIDER = "salesforce"
export const SLACK_PROVIDER = "slack"
export const ZOOM_PROVIDER = "zoom"
export const LINEAR_PROVIDER = "linear"

// Frontend view of the credentials, containing metadata with no secret values.
interface NotionCredentialMetadata {
  provider: typeof NOTION_PROVIDER
  subProvider: "default"
  createdAt: FirebaseFirestore.Timestamp
  workspaceName: string
  workspaceIcon?: string
}

interface SalesforceCredentialMetadata {
  provider: typeof SALESFORCE_PROVIDER
  subProvider: "default"
  createdAt: FirebaseFirestore.Timestamp
  instanceDomain: string
}

interface StandardCredentialMetadata {
  provider:
    | typeof ATLASSIAN_PROVIDER
    | typeof GONG_PROVIDER
    | typeof GOOGLE_PROVIDER
    | typeof SLACK_PROVIDER
    | typeof ZOOM_PROVIDER
    | typeof LINEAR_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

export type CredentialMetadata =
  | StandardCredentialMetadata
  | NotionCredentialMetadata
  | SalesforceCredentialMetadata
