import Tooltip from "antd/es/tooltip"
import { TriangleAlert } from "lucide-react"
import { Link } from "react-router"

import { getErrorMessage } from "../../errors"
import type { UserFacingErrorType } from "../../exceptions/userFacingErrorTypes"

const QuestionnaireError: React.FC<{
  error?: UserFacingErrorType | null
}> = ({ error }) => {
  if (!error) {
    return null
  }

  const errorObj = getErrorMessage(
    {
      prefix: "Couldn't fill out questionnaire",
      error,
    },
    false,
  )
  return (
    <div className="mt-2 inline-block">
      <Tooltip title={errorObj}>
        <Link
          to={error === "USER_HAS_NO_DOCUMENTS" ? "/source-documents" : ""}
          className="flex items-center text-xs text-red-500 hover:text-red-500 hover:underline"
        >
          <TriangleAlert size={12} color="red" className="mr-1" /> Error
        </Link>
      </Tooltip>
    </div>
  )
}

export default QuestionnaireError
