import { sendAnalyticsEvent } from "../../analytics"
import { createUserDocuments } from "../../api"
import uploadUserFile from "../../files/uploadUserFile"
import type {
  FileDocument,
  GoogleDriveDocument,
  NonQuestionnaireFileDocument,
} from "./state"
import { getQuestionAnswerLayouts } from "./utils"

export const indexFileDocument = async (
  doc: FileDocument | NonQuestionnaireFileDocument,
  tagOids: string[],
) => {
  const questionAnswerLayouts =
    doc.kind === "FILE" ? getQuestionAnswerLayouts(doc) : undefined
  const tags =
    tagOids.length > 0
      ? Object.fromEntries(tagOids.map((oid) => [oid, null]))
      : null

  if (
    doc.fileContents === undefined ||
    doc.fileType === undefined ||
    doc.fileName === undefined
  ) {
    throw new Error("No file")
  }

  const { id: uploadId, uri: objectUri } = await uploadUserFile({
    fileContents: doc.fileContents,
    fileType: doc.fileType,
    fileName: doc.fileName,
  })

  const documentsToCreate = [
    {
      external_document_id: uploadId,
      url: "",
      source_kind: "FILEUPLOAD" as const,
      uploaded_file_uri: objectUri,
      title: doc.fileName,
      mimetype: doc.mimeType,
      is_completed_questionnaire: doc.kind === "FILE",
      question_answer_layouts: questionAnswerLayouts,
      tags,
    },
  ]

  const documents = await createUserDocuments(documentsToCreate)
  documents.map((doc) => {
    sendAnalyticsEvent({
      surface: "WEB_SOURCE_DOCUMENTS",
      event_type: "CREATE_ENTITY",
      event_data: {
        entity_type: "SOURCE_DOCUMENT_FILE",
        entity_id: doc.oid,
      },
    })
  })
}

export const indexDriveDocument = async (doc: GoogleDriveDocument) => {
  const questionAnswerLayouts = getQuestionAnswerLayouts(doc)
  const documentsToCreate = [
    {
      external_document_id: doc.external_document_id,
      url: "",
      source_kind: "GOOGLE_DRIVE" as const,
      mimetype: doc.mimeType,
      title: doc.title,
      is_completed_questionnaire: doc.kind === "GOOGLE_DRIVE",
      question_answer_layouts: questionAnswerLayouts,
      source_oid: doc.source_oid,
      parent_external_id: doc.parent_external_id,
    },
  ]

  const documents = await createUserDocuments(documentsToCreate)
  documents.map((doc) => {
    sendAnalyticsEvent({
      surface: "WEB_SOURCE_DOCUMENTS",
      event_type: "CREATE_ENTITY",
      event_data: {
        entity_type: "SOURCE_DOCUMENT_GOOGLE_DRIVE",
        entity_id: doc.oid,
      },
    })
  })
}
