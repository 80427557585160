import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Tooltip from "antd/es/tooltip"
import { PlusIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { getErrorMessage } from "../../errors"
import { createGroupInvite } from "../../groups/api"
import type { CreateGroupInviteArgs, Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import { ROLE_INFOS } from "./constants"

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

interface AddInviteFormProps {
  onClose: () => void
  group: Group
}

type FormDataType = Pick<CreateGroupInviteArgs, "invitee_email" | "role">

const AddInviteForm: React.FC<AddInviteFormProps> = ({ onClose, group }) => {
  const [form] = Form.useForm<FormDataType>()

  const inviteeEmail = Form.useWatch("invitee_email", form)

  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  useKeydown(27 /*escape*/, onClose)
  const { handleSuccess } = useErrorPopup()

  const onSubmitForm = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        const inviteResult = (
          await createGroupInvite({
            group_oid: group.oid,
            invitee_email: formData.invitee_email,
            role: formData.role,
          })
        ).data

        if (inviteResult.result === "SENT") {
          handleSuccess("Invitation sent successfully!")
        } else if (inviteResult.result === "RESENT") {
          handleSuccess("Invitation resent successfully!")
        } else if (inviteResult.result === "ALREADY_MEMBER") {
          form.setFields([
            {
              name: "invitee_email",
              errors: [`${formData.invitee_email} is already in the group`],
            },
          ])
          return
        }
        onClose()
      } catch (error) {
        form.setFields([
          {
            name: "invitee_email",
            errors: [
              getErrorMessage({ error, prefix: "Couldn't send invite" }),
            ],
          },
        ])
      } finally {
        setSubmitting(false)
      }
    },
    [group.oid, form, handleSuccess, onClose],
  )

  return (
    <Form
      layout="vertical"
      className="w-64"
      onFinish={onSubmitForm}
      initialValues={{ role: "MEMBER" }}
      form={form}
    >
      <Form.Item
        name="invitee_email"
        label="Email"
        rules={[
          {
            type: "email",
            message: (
              <span className="text-xs text-red-400">
                {"Make sure you've entered a valid email"}
              </span>
            ),
          },
        ]}
      >
        <Input autoFocus placeholder="member@example.com" />
      </Form.Item>
      <Form.Item name="role" label="Role" rules={[{ required: true }]}>
        <Select
          placeholder="Role in the group"
          options={Object.values(ROLE_INFOS).map((roleInfo) => ({
            value: roleInfo.role,
            label: (
              <Tooltip title={roleInfo.description}>{roleInfo.name}</Tooltip>
            ),
          }))}
        />
      </Form.Item>

      <Form.Item name="submit">
        <Button
          type="primary"
          htmlType="submit"
          icon={<PlusIcon />}
          loading={isSubmitting}
          disabled={isSubmitting || !isValidEmail(inviteeEmail)}
        >
          Send Invite
        </Button>
      </Form.Item>
    </Form>
  )
}

interface AddMemberButtonProps {
  group: Group
}
const AddMemberButton: React.FC<AddMemberButtonProps> = ({ group }) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={<AddInviteForm onClose={hide} group={group} />}
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="primary"
        id="groups-invite-member-button"
        title="Invite Member"
        className="flex items-center justify-center"
      >
        Invite Member
      </Button>
    </Popover>
  )
}

export default AddMemberButton
