import { sendAnalyticsEvent } from "../../analytics"
import { createAnswerQuestionnaireJob } from "../../api"
import type { FileDataForUpload } from "../../documents/types"
import uploadUserFile from "../../files/uploadUserFile"

export const createJob = async (fileData: FileDataForUpload) => {
  const { uri: objectUri } = await uploadUserFile(fileData)

  await createAnswerQuestionnaireJob({
    question_answer_layouts: fileData.question_answer_layouts,
    uploaded_file_uri: objectUri,
    mimetype: fileData.fileType,
    original_filename: fileData.fileName,
    title: fileData.fileName,
    custom_prompt: fileData.custom_prompt,
  })

  sendAnalyticsEvent({
    event_type: "SUBMIT_QUESTIONNAIRE",
    surface: "WEB_QUESTIONNAIRE_ASSISTANT",
    event_data: {
      entity_type: "ANSWER_QUESTIONNAIRE_JOB",
      entity_id: null,
    },
  })
}
