import { utils, writeFile } from "xlsx"

/**
 * Copies text to clipboard
 */
export const copyToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    void error
    throw new Error("Failed to copy to clipboard")
  }
}

export const dataToCsv = (data: string[][]): string => {
  return data
    .map((row) =>
      row
        .map((cell) => {
          // Escape quotes and wrap in quotes if cell contains comma, newline or quote
          if (cell.includes(",") || cell.includes("\n") || cell.includes('"')) {
            return `"${cell.replace(/"/g, '""')}"`
          }
          return cell
        })
        .join(","),
    )
    .join("\n")
}

export const dataToExcelClipboard = (data: string[][]): string => {
  return data
    .map((row) =>
      row
        .map((cell) => {
          // Replace any tabs with spaces to avoid breaking the format
          return cell.replace(/\t/g, " ")
        })
        .join("\t"),
    )
    .join("\n")
}

export const copyDataToClipboard = async (
  data: string[][],
  format: "csv" | "excel",
): Promise<void> => {
  const content =
    format === "csv" ? dataToCsv(data) : dataToExcelClipboard(data)
  await copyToClipboard(content)
}

export const downloadCsvFile = (data: string[][], filename: string): void => {
  const content = dataToCsv(data)

  // Create a blob and download it
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", filename)
  link.style.visibility = "hidden"

  document.body.appendChild(link)
  try {
    link.click()
  } finally {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100)
  }
}

export const downloadXlsxFile = (
  data: Record<string, string[][]>,
  filename: string,
): void => {
  // Create a new workbook and worksheet
  const wb = utils.book_new()

  // Add the worksheet to the workbook
  for (const [sheetName, sheetData] of Object.entries(data)) {
    const ws = utils.aoa_to_sheet(sheetData)
    utils.book_append_sheet(wb, ws, sheetName)
  }

  // Write the workbook to a file
  writeFile(wb, filename)
}
