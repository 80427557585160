import Skeleton from "antd/es/skeleton"
import { ArrowRight, CircleDashed, CircleDot, ListTodo } from "lucide-react"

import SmartLink from "../../components/SmartLink"
import { EXTENSION_URL } from "../../constants"
import type {
  HomepageMetricDocument,
  OnboardingChecklist,
} from "../../metrics/types"

export const ONBOARDING_ITEMS: {
  name: string
  description: string
  key: keyof OnboardingChecklist
  url: string
  id: string
}[] = [
  {
    name: "Upload a document",
    description:
      "Quilt uses your documents to answer questions. Uploading your SOC2 report, help center, or other useful documents.",
    key: "document_uploaded",
    url: "/source-documents",
    id: "onboarding-task-document_uploaded",
  },
  {
    name: "Ask a question",
    description:
      "Quilt’s knowledge assistant can answer any questions you may have based on source documents.",
    key: "question_asked",
    url: "/chat",
    id: "onboarding-task-question_asked",
  },
  {
    name: "Try the Questionnaire Assistant",
    description: "Upload a questionnaire and Quilt will complete it for you",
    key: "questionnaire_uploaded",
    url: "/questionnaire-assistant",
    id: "onboarding-task-questionnaire_uploaded",
  },
  {
    name: "Invite a team member",
    description:
      "Working with a team? Invite them to your Quilt group to collaborate",
    key: "teammember_invited",
    url: "/group/settings/members",
    id: "onboarding-task-teammember_invited",
  },
  {
    name: "Download Quilt extension",
    description:
      "Have Quilt wherever you browse, and be able to ask Quilt questions on the spot.",
    key: "extension_loaded",
    url: EXTENSION_URL,
    id: "onboarding-task-extension_loaded",
  },
]

const defaultChecklist = {
  document_uploaded: false,
  questionnaire_uploaded: false,
  question_asked: false,
  teammember_invited: false,
  extension_loaded: false,
}

const OnboardingTasks: React.FC<{
  metric?: HomepageMetricDocument
  loading: boolean
}> = ({ metric, loading }) => {
  const onboarding_checklist = metric?.onboarding_checklist ?? defaultChecklist
  const numOnboardingItems = ONBOARDING_ITEMS.length
  const numCompleted = ONBOARDING_ITEMS.map(
    (item) => onboarding_checklist[item.key],
  ).filter(Boolean).length

  return (
    <div
      id="home-page-onboarding-tasks"
      className="mt-6 flex flex-1 flex-col gap-4 md:flex-row"
    >
      <div className="flex-grow rounded-2xl border border-gray-100 p-6">
        <div className="flex items-center text-sm font-bold text-gray-400">
          <ListTodo className="mr-2" size={18} /> <div>Onboarding Tasks</div>
        </div>
        <h4 className="mb-4 font-semibold">
          Get the most out of Quilt by completing these tasks:
        </h4>
        {loading ? (
          <Skeleton active loading />
        ) : (
          <>
            <div className="mb-4 flex items-end">
              <div className="text-7xl">{numCompleted}</div>
              <div className="mb-2 text-3xl text-slate-400">
                /{numOnboardingItems}
              </div>
            </div>
            {ONBOARDING_ITEMS.map((itemObj) => (
              <SmartLink
                key={itemObj.id}
                id={itemObj.id}
                className="bg-gray-25 mb-2 flex items-start rounded-md p-2 font-medium hover:bg-gray-50"
                href={itemObj.url}
              >
                <div className="mr-2 mt-0.5">
                  {onboarding_checklist[itemObj.key] ? (
                    <CircleDot size={18} className="text-primary" />
                  ) : (
                    <CircleDashed size={18} className="text-gray-400" />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="text-primary flex items-center">
                    <div className="mr-2">{itemObj.name}</div>
                    <ArrowRight size={16} className="text-primary" />
                  </div>
                  <div className="text-sm text-gray-400">
                    {itemObj.description}
                  </div>
                </div>
              </SmartLink>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default OnboardingTasks
