// Wrapper around the application that handles showing the signin page if the user is not signed in.
import { BrowserRouter, Route, Routes } from "react-router"

import { useQuiltAuthState } from "../contexts/useQuiltAuthState"
import AuthResponsePage from "../pages/AuthResponsePage"
import SignInPage from "../pages/SignInPage"
import TopLevelPageContainer from ".//TopLevelPageContainer"
import FullPageLoader from "./FullPageLoader"
import ImpersonatorLogin from "./ImpersonatorLogin"
import PageBackgroundContainer from "./PageBackgroundContainer"
import getLazy from "./getLazy"

const ExtensionGoogleAuth = getLazy(
  () => import("../pages/ExtensionGoogleAuth"),
)

const AppSignInWrapper: React.FC = () => {
  const { authUser, loading, error } = useQuiltAuthState()

  let page: React.ReactNode
  if (authUser) {
    page = <TopLevelPageContainer />
  } else {
    if (loading) {
      page = (
        <PageBackgroundContainer>
          <FullPageLoader />
        </PageBackgroundContainer>
      )
    } else if (error) {
      page = (
        <PageBackgroundContainer>
          <h2>Auth load Error:</h2> <div>{error.toString()}</div>
        </PageBackgroundContainer>
      )
    } else {
      page = <SignInPage />
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/extension-auth/google"
          element={<ExtensionGoogleAuth />}
        />
        <Route path="/auth/response" element={<AuthResponsePage />} />
        <Route path="/impersonator-login" element={<ImpersonatorLogin />} />
        <Route path="*" element={page} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppSignInWrapper
