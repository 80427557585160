import type {
  GetGoogleAccessTokenRequest,
  GetGoogleAccessTokenResponse,
} from "../google/types"
import { httpsCallable } from "../serverUtils"

export const getGoogleAccessToken = httpsCallable<
  GetGoogleAccessTokenRequest,
  GetGoogleAccessTokenResponse
>("getGoogleAccessToken")
