import type { SyncNode } from "../../sources/types"

export interface FilePickerState {
  nodes: SyncNode[]
  currentPath: string
  isLoading: boolean
  error?: string
  cursor?: string
  hasMore: boolean
  changedFiles: Map<string, "INCLUDE" | "EXCLUDE">
  searchPaused: boolean
}

export const initialState: FilePickerState = {
  nodes: [],
  currentPath: "",
  isLoading: false,
  hasMore: false,
  changedFiles: new Map(),
  searchPaused: false,
}
