import { pyBackendAxiosInstance } from "../../api"
import type {
  AnswerActor,
  AnswerQuestionnaireCellEditRequest,
  AnswerQuestionnaireViewConfigRequest,
  AnsweredQuestionAnswerTextContents,
  GeneratedAnsweredQuestion,
} from "../../types/jobs"
import { openWindow } from "../../utils"

export const createAnswerQuestionnaireJobDownloadUrl = async (
  oid: string,
): Promise<string> => {
  const response = await pyBackendAxiosInstance.post<{ download_url: string }>(
    `/answer_questionnaire_jobs/${oid}/complete-file/download_url`,
  )
  return response?.data.download_url
}

export type DownloadFormat = "original" | "csv" | "docx" | "xlsx"

export const downloadCompleteAnswerQuestionnaireJob = async (
  oid: string,
  format: DownloadFormat,
): Promise<void> => {
  if (format === "original") {
    // TODO(mgraczyk): Consolidate with the normal download endpoint.
    const downloadUrl = await createAnswerQuestionnaireJobDownloadUrl(oid)
    await openWindow(downloadUrl, "_parent")
    return
  }

  const response = await pyBackendAxiosInstance.get(
    `/answer_questionnaire_jobs/${oid}/complete-file/download?format=${format}`,
    { responseType: "blob" },
  )

  // Extract filename from content-disposition header
  const contentDisposition = response.headers["content-disposition"] as
    | string
    | undefined
  if (!contentDisposition) {
    throw new Error("No content disposition")
  }
  const filenameMatch = /filename="(.+)"/.exec(contentDisposition)
  if (!filenameMatch) {
    throw new Error("No filename")
  }
  const filename = filenameMatch[1]

  // Create a download link and trigger it.
  const url = window.URL.createObjectURL(response.data as Blob)
  try {
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    link.click()
  } finally {
    window.URL.revokeObjectURL(url)
  }
}

export const reviewAnswerQuestionnaireJob = async (
  oid: string,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${oid}/mark-complete`,
  )
}

export const getAnswerQuestionnaireJobBaseDownloadUrl = async (
  oid: string,
): Promise<string> => {
  const response = await pyBackendAxiosInstance.get<{ download_url: string }>(
    `/answer_questionnaire_jobs/${oid}/base-file/download_url`,
  )
  return response?.data.download_url
}

export const createQuestionnaireJobEdit = async (
  request: AnswerQuestionnaireCellEditRequest,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${request.job_oid}/edit`,
    request,
  )
}

export const setQuestionnaireViewOptions = async (
  request: AnswerQuestionnaireViewConfigRequest,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${request.job_oid}/view-config`,
    request,
  )
}

export const updateQuestionnaireJobUserAnswer = async (
  job_oid: string,
  answer_oid: string,
  answer_obj: Pick<
    GeneratedAnsweredQuestion,
    "primary_answer" | "secondary_answer"
  >,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${job_oid}/answer/${answer_oid}/user_answer`,
    answer_obj,
  )
}

export const unmarkCompletedAnswerQuestionnaireJob = async (
  oid: string,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${oid}/unmark-completed`,
  )
}

export const createSourceDocumentFromQuestionnaire = async (
  oid: string,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${oid}/export-to-source-document`,
  )
}

export const reviewQuestionnaireJobAnswers = async (
  job_oid: string,
  requests: {
    job_oid: string
    answer_oid: string
    expected_contents?: AnsweredQuestionAnswerTextContents
  }[],
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${job_oid}/answer/create-reviews`,
    { requests },
  )
}

export const unreviewQuestionnaireJobAnswers = async (
  job_oid: string,
  requests: { job_oid: string; answer_oid: string }[],
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${job_oid}/answer/remove-reviews`,
    { requests },
  )
}

export const removeQuestionnaireJobAnswers = async (
  job_oid: string,
  requests: { job_oid: string; answer_oid: string }[],
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${job_oid}/remove-answers`,
    { requests },
  )
}

export const assignQuestionnaireJobAnswers = async (
  job_oid: string,
  answer_oids: string[],
  assign_to: AnswerActor,
  message: string = "",
): Promise<void> => {
  const requests = answer_oids.map((answer_oid) => ({
    job_oid,
    answer_oid,
    assign_to,
    message,
  }))

  await pyBackendAxiosInstance.post(
    `/answer_questionnaire_jobs/${job_oid}/answer/assign-many`,
    { requests },
  )
}

interface AnswerQuestionnaireJobReanswerManyResponse {
  answers: GeneratedAnsweredQuestion[]
}

interface ReferenceIds {
  user_document_oid?: string | null
  piece_oid?: string | null
}
interface QuiltResponseRequest {
  job_oid: string
  answer_oid: string
  selected_references: ReferenceIds[] | null
  removed_references: ReferenceIds[] | null
}

export const reanswerQuestionnaireJobAnswers = async (
  job_oid: string,
  answer_oids: string[],
  selected_references: ReferenceIds[],
  removed_references: ReferenceIds[],
): Promise<AnswerQuestionnaireJobReanswerManyResponse> => {
  const requests: QuiltResponseRequest[] = answer_oids.map((answer_oid) => ({
    job_oid,
    answer_oid,
    selected_references,
    removed_references,
  }))

  return (
    await pyBackendAxiosInstance.post<AnswerQuestionnaireJobReanswerManyResponse>(
      `/answer_questionnaire_jobs/${job_oid}/reanswer-many`,
      { requests },
    )
  ).data
}
