import type { ColumnType } from "antd/es/table"
import type { Timestamp } from "firebase/firestore"

import { getInterpretableTimeString } from "../../timeUtils"

interface Args {
  title: string
  dataIndex: string
  width?: number
  sortable?: boolean
  defaultSortOrder?: "ascend" | "descend" | null
}

const getSorter =
  (dataIndex: string) =>
  (
    a: { [dataIndex: string]: FirebaseFirestore.Timestamp },
    b: { [dataIndex: string]: FirebaseFirestore.Timestamp },
  ): number => {
    const aD = a[dataIndex] ?? null
    const bD = b[dataIndex] ?? null
    if (aD === null && bD === null) {
      return 0
    } else if (aD === null) {
      return -1
    } else if (bD === null) {
      return 1
    } else {
      return aD._compareTo(bD)
    }
  }

const renderTimestamp = (timestamp?: Timestamp) =>
  timestamp && (
    <div className="text-gray-700">{getInterpretableTimeString(timestamp)}</div>
  )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateColumnProps = <T extends Record<PropertyKey, any>>({
  title,
  dataIndex,
  width,
  sortable = false,
  defaultSortOrder = "descend",
}: Args): ColumnType<T> => {
  return {
    title,
    dataIndex,
    width,
    defaultSortOrder,
    sorter: sortable && getSorter(dataIndex),
    render: renderTimestamp,
  }
}

export default dateColumnProps
