import { Suspense, lazy, useEffect, useState } from "react"

import LoadingSpinner from "../../components/LoadingSpinner"
import { getDocumentUrl } from "../../documents/api"
import type { DocumentReference } from "../../documents/types"
import { MimeType } from "../../types/mimetype"
import type { UserDocument } from "../../types/userDocument"
import ConfluenceViewer from "./ConfluenceViewer"
import DocNotSupported from "./DocNotSupported"
import DocPreviewError from "./DocPreviewError"
import IframeExternalLink from "./IframeExternalLink"
import SheetsViewer from "./SheetsViewer"
import SlackThreadPreview from "./SlackThreadPreview"

const PREVIEWABLE_MIMETYPES = new Set([
  MimeType.CSV,
  MimeType.HTML,
  MimeType.MARKDOWN,
  MimeType.PDF,
  MimeType.SLACK_THREAD,
  MimeType.TXT,
  MimeType.XLS,
  MimeType.XLSM,
  MimeType.XLSX,
])

// Lazy import since it is large.
const PDFViewer = lazy(() => import("./PDFViewer"))
const MarkdownViewer = lazy(() => import("./MarkdownViewer"))
const NotionViewer = lazy(() => import("./NotionViewer"))

const SourceDocumentViewer: React.FC<{
  userDocument: UserDocument
  reference: DocumentReference | undefined | null
}> = ({ userDocument, reference }) => {
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()
  const [downloadUrlFetchError, setDownloadUrlFetchError] = useState<
    string | undefined
  >()
  const [loading, setLoading] = useState<boolean>(true)

  // TODO (ishan) dont use userDocument.mimetype after migrating
  const previewMimetype = userDocument.preview_mimetype ?? userDocument.mimetype
  const renderLivePage =
    userDocument.source_kind === "PUBLIC_WEB_PAGE" &&
    previewMimetype === MimeType.HTML

  const shouldLoadPreviewData =
    previewMimetype &&
    PREVIEWABLE_MIMETYPES.has(previewMimetype) &&
    // Intercepting here because we render public web pages as iframes.
    !renderLivePage

  useEffect(() => {
    if (!shouldLoadPreviewData) {
      setLoading(false)
      return
    }

    void (async () => {
      try {
        const signedUrl = await getDocumentUrl({
          oid: userDocument.oid,
          isDownload: false,
        })
        setDownloadUrl(signedUrl.data)
        setDownloadUrlFetchError(undefined)
      } catch (e) {
        console.error(e)
        if (e instanceof Error) {
          setDownloadUrlFetchError(e.message)
        } else {
          setDownloadUrlFetchError("Something went wrong, try again later.")
        }
      } finally {
        setLoading(false)
      }
    })()
  }, [userDocument.oid, shouldLoadPreviewData])

  if (renderLivePage) {
    return <IframeExternalLink href={userDocument.document_url} />
  }

  if (loading) {
    return (
      <span className="m-4">
        <LoadingSpinner />
      </span>
    )
  }

  if (downloadUrlFetchError) {
    return (
      <DocPreviewError
        message={`Failed to load document: ${downloadUrlFetchError}`}
      />
    )
  }

  if (previewMimetype === MimeType.PDF) {
    const boundingBoxReference =
      reference != null && reference.locationKind === "BOUNDING_BOX"
        ? reference
        : undefined

    if (!downloadUrl) {
      return <DocPreviewError message="Failed to load document" />
    }

    return (
      <Suspense fallback={<LoadingSpinner />}>
        <PDFViewer url={downloadUrl} referenceLocation={boundingBoxReference} />
      </Suspense>
    )
  } else if (userDocument.source_kind === "NOTION") {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <NotionViewer document={userDocument} />
      </Suspense>
    )
  } else if (previewMimetype === MimeType.CONFLUENCE_PAGE) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <ConfluenceViewer
          pageId={userDocument.external_document_id}
          pageUrl={userDocument.document_url}
        />
      </Suspense>
    )
  } else if (
    previewMimetype === MimeType.CSV ||
    previewMimetype === MimeType.XLS ||
    previewMimetype === MimeType.XLSM ||
    previewMimetype === MimeType.XLSX
  ) {
    const sheetReference =
      reference != null && reference.locationKind === "SHEET_RANGE"
        ? reference
        : undefined

    if (!downloadUrl) {
      return <DocPreviewError message="Failed to load document" />
    }

    return (
      <SheetsViewer
        url={downloadUrl}
        previewMimetype={previewMimetype}
        referenceLocation={sheetReference}
      />
    )
  } else if (previewMimetype === MimeType.SLACK_THREAD) {
    if (!downloadUrl) {
      return <DocPreviewError message="Failed to load document" />
    }
    return <SlackThreadPreview url={downloadUrl} />
  } else if (
    previewMimetype === MimeType.TXT ||
    previewMimetype === MimeType.HTML
  ) {
    // TODO(mgraczyk): Locations.
    if (!downloadUrl) {
      return <DocPreviewError message="Failed to load document" />
    }

    if (previewMimetype === MimeType.TXT) {
      return (
        <div className="w-full grow p-4">
          <embed
            src={downloadUrl}
            className="h-full w-full grow border border-gray-200"
          />
        </div>
      )
    } else {
      return (
        <div className="w-full grow p-4">
          <iframe
            src={downloadUrl}
            className="h-full w-full grow border border-gray-200"
            sandbox=""
          />
        </div>
      )
    }
  } else if (previewMimetype === MimeType.MARKDOWN) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <MarkdownViewer url={downloadUrl} />
      </Suspense>
    )
  } else {
    return <DocNotSupported documentUrl={userDocument.document_url} />
  }
}

export default SourceDocumentViewer
