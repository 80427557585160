import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  List,
  Popconfirm,
  Radio,
  Row,
  Typography,
} from "antd"
import { doc, orderBy, query, where, writeBatch } from "firebase/firestore"
import {
  Building,
  Database,
  FileSpreadsheet,
  StickyNoteIcon,
  Trash2,
  Users,
} from "lucide-react"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link, useNavigate } from "react-router"

import { sendAnalyticsEvent } from "../../analytics"
import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { getInterpretableTimeString } from "../../timeUtils"
import { randomId } from "../../utils"
import { colRef, createAgentTable, deleteAgentTable, rowsColRef } from "./crud"
import type { AgentTable, ColumnConfig } from "./types"

const { Title } = Typography

interface TemplateTable {
  id: string
  name: string
  description: string
  icon: React.ReactNode
  columns: ColumnConfig[]
}

const templates: TemplateTable[] = [
  {
    id: "blank",
    name: "Blank",
    description: "An empty table that can be customized",
    icon: <StickyNoteIcon size={24} />,
    columns: [],
  },
  {
    id: "company-research",
    name: "Company Research",
    description: "Research companies and find their websites and key people",
    icon: <Building size={24} />,
    columns: [
      {
        id: "input-company-name",
        type: "input",
        title: "Company Name",
        description: "Name of the company",
      },
      {
        id: "output-website",
        type: "output",
        title: "Website URL",
        description: "The company website URL",
        prompt:
          "The URL of the website, there should be no other content besides the URL.\nIf the company is not very obvious, search the web to make sure you have the right URL for the specific company.",
        tools: {
          web_search: true,
        },
      },
      {
        id: "key-people",
        type: "output",
        title: "Key People",
        description: "The key people at the company",
        prompt:
          "List the key people at the company, including the CEO, CTO, and other important people or buyers. Include names and email address if available.",
        tools: {
          web_search: true,
        },
      },
    ],
  },
  {
    id: "prospect-qualification",
    name: "Prospect Qualification",
    description: "Qualify sales prospects based on company information",
    icon: <Users size={24} />,
    columns: [
      {
        id: "input-company-name",
        type: "input",
        title: "Company Name",
        description: "Name of the company",
      },
      {
        id: "input-company-size",
        type: "input",
        title: "Company Size",
        description: "Approximate number of employees",
      },
      {
        id: "input-industry",
        type: "input",
        title: "Industry",
        description: "The company's industry",
      },
      {
        id: "output-qualification",
        type: "output",
        title: "Qualification Score",
        description: "Score from 1-10 with explanation",
        prompt:
          "Based on the company information, provide a qualification score from 1-10 (10 being the best prospect). Include a brief explanation of why you gave this score. Consider factors like company size, industry fit, and potential budget.",
        tools: {
          web_search: true,
        },
      },
      {
        id: "output-approach",
        type: "output",
        title: "Recommended Approach",
        description: "How to approach this prospect",
        prompt:
          "Provide a recommended sales approach for this prospect. Include key talking points, potential pain points to address, and any specific value propositions that might resonate with this type of company.",
        tools: {
          web_search: true,
        },
      },
    ],
  },
  {
    id: "competitive-analysis",
    name: "Competitive Analysis",
    description: "Analyze competitors and their offerings",
    icon: <Database size={24} />,
    columns: [
      {
        id: "input-competitor",
        type: "input",
        title: "Competitor Name",
        description: "Name of the competitor",
      },
      {
        id: "output-products",
        type: "output",
        title: "Product Offerings",
        description: "Main products and services",
        prompt:
          "List the main products and services offered by this competitor. Include pricing information if available.",
        tools: {
          web_search: true,
        },
      },
      {
        id: "output-strengths",
        type: "output",
        title: "Strengths",
        description: "Key strengths of the competitor",
        prompt:
          "Identify the key strengths of this competitor. What do they do well? What are their unique selling points?",
        tools: {
          web_search: true,
        },
      },
      {
        id: "output-weaknesses",
        type: "output",
        title: "Weaknesses",
        description: "Key weaknesses of the competitor",
        prompt:
          "Identify the key weaknesses or gaps in this competitor's offerings. Where are they vulnerable?",
        tools: {
          web_search: true,
        },
      },
    ],
  },
]

export default function AgentTableList() {
  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)
  const { authUser: user, activeGroupOid } =
    useActiveUserAuthorizationFromContext()
  const { handleError } = useErrorPopup()
  const [filterOption, setFilterOption] = useState<"mine" | "all">("mine")

  const [userTables, userTablesLoading, userTablesError] =
    useCollectionData<AgentTable>(
      filterOption === "mine"
        ? query(
            colRef(activeGroupOid),
            where("created_by.uid", "==", user.uid),
            orderBy("created_at", "desc"),
          )
        : query(colRef(activeGroupOid), orderBy("created_at", "desc")),
    )

  const handleCreateFromTemplate = async (template: TemplateTable) => {
    sendAnalyticsEvent({
      event_type: "CLICK",
      event_data: {
        entity_id: "agent-table-create-from-template",
      },
      surface: "WEB_AGENT_TABLE_LIST",
    })

    setCreating(true)
    try {
      // Use the activeGroupOid from context
      const tableOid = await createAgentTable(user, activeGroupOid, {
        name: template.name,
        description: template.description,
        columns: template.columns,
      })

      const rowBatch = writeBatch(db)
      for (let i = 0; i < 10; i++) {
        rowBatch.set(doc(rowsColRef(activeGroupOid, tableOid), randomId()), {
          oid: randomId(),
          sort_index: i,
          inputs: {},
          outputs: {},
        })
      }
      await rowBatch.commit()

      // Navigate to the new table
      void navigate(`/agent-tables/${tableOid}`)
    } catch (error) {
      handleError({
        error,
        prefix: "Failed to create table from template",
      })
    } finally {
      setCreating(false)
    }
  }

  const handleDeleteTable = async (tableOid: string, e?: React.MouseEvent) => {
    e?.stopPropagation()
    e?.preventDefault()

    setCreating(true)
    try {
      await deleteAgentTable(activeGroupOid, tableOid)
    } catch (error) {
      handleError({
        error,
        prefix: "Failed to delete table",
      })
    } finally {
      setCreating(false)
    }
  }

  return (
    <>
      <Header
        title="Agent Tables"
        subtitle="Create and manage tables that use AI to process data"
      />
      <div className="flex flex-col gap-8 p-8">
        <div className="mb-4">
          <Radio.Group
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value as "mine" | "all")}
            buttonStyle="solid"
          >
            <Radio.Button value="mine">Show Mine Only</Radio.Button>
            <Radio.Button value="all">Show All</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <List
            className="min-h-[80px]"
            loading={userTablesLoading || creating}
            grid={{ gutter: 16, xs: 1, sm: 2, md: 3 }}
            dataSource={userTables}
            locale={{
              emptyText: userTablesError ? (
                <Alert
                  message="Error loading tables"
                  description={userTablesError.message}
                  type="error"
                />
              ) : (
                <Alert
                  message="No existing tables found. Create one below to get started!"
                  type="info"
                />
              ),
            }}
            renderItem={(table: AgentTable) => (
              <List.Item>
                <Link to={`/agent-tables/${table.oid}`}>
                  <Card hoverable className="group relative h-full">
                    <div className="absolute right-2 top-2 opacity-0 transition-opacity group-hover:opacity-100">
                      <Popconfirm
                        title="Delete this table?"
                        description="This action cannot be undone."
                        onConfirm={(e) => handleDeleteTable(table.oid, e)}
                        onCancel={(e) => e?.stopPropagation()}
                        okText="Yes"
                        cancelText="No"
                        disabled={creating}
                      >
                        <Button
                          type="text"
                          icon={<Trash2 size={16} />}
                          className="text-red-500 hover:text-red-700"
                          disabled={creating}
                          onClick={(e) => e.preventDefault()} // Prevent navigation
                        />
                      </Popconfirm>
                    </div>
                    <Card.Meta
                      avatar={
                        <div className="text-blue-500">
                          {<FileSpreadsheet size={24} />}
                        </div>
                      }
                      title={table.name}
                      description={table.description}
                    />
                    <div className="mt-4 flex flex-col items-start gap-2">
                      <div>
                        {table.columns.map((column) => (
                          <span
                            key={column.id}
                            className={`mb-1 mr-2 inline-block rounded px-2 py-1 text-xs ${
                              column.type === "input"
                                ? "bg-blue-100 text-blue-800"
                                : "bg-green-100 text-green-800"
                            }`}
                          >
                            {column.title}
                          </span>
                        ))}
                      </div>
                      <div className="text-xs text-gray-400">
                        Created by {table.created_by.email}
                      </div>
                      <div className="text-xs text-gray-400">
                        Updated{" "}
                        {table.updated_at
                          ? getInterpretableTimeString(table.updated_at)
                          : "..."}
                      </div>
                    </div>
                  </Card>
                </Link>
              </List.Item>
            )}
          />
        </div>
        <Divider />

        <div className="mb-8">
          <Title level={4}>Create from template</Title>
          <Row gutter={[16, 16]} className="mt-4">
            {templates.map((template) => (
              <Col xs={24} sm={12} md={8} key={template.id}>
                <Card
                  hoverable
                  className={`h-full ${creating ? "opacity-50" : ""}`}
                  onClick={
                    creating
                      ? undefined
                      : () => handleCreateFromTemplate(template)
                  }
                >
                  <Card.Meta
                    avatar={
                      <div className="text-blue-500">{template.icon}</div>
                    }
                    title={template.name}
                    description={template.description}
                  />
                  <div className="mt-4">
                    {template.columns.map((column) => (
                      <span
                        key={column.id}
                        className={`mb-1 mr-2 inline-block rounded px-2 py-1 text-xs ${
                          column.type === "input"
                            ? "bg-blue-100 text-blue-800"
                            : "bg-green-100 text-green-800"
                        }`}
                      >
                        {column.title}
                      </span>
                    ))}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  )
}
