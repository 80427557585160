import AddDocumentPickerButton from "../components/AddDocumentPickerButton"
import Header from "../components/Header"
import KnowledgeItemsManager from "../components/KnowledgeItemsManager"

const UserDocumentsPage: React.FC = () => {
  return (
    <>
      <Header
        title="Source Documents"
        subtitle="We search these documents for answers to your questions"
      >
        <div className="flex items-center justify-end">
          <AddDocumentPickerButton
            buttonId="source-documents-actions"
            isCompletedQuestionnaireActions={false}
          />
        </div>
      </Header>
      <KnowledgeItemsManager isUserDocumentMode />
    </>
  )
}

export default UserDocumentsPage
