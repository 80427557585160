import App from "antd/es/app"
import ConfigProvider from "antd/es/config-provider"
import message from "antd/es/message"

// Set default alert message configurtion.
message.config({ duration: 10 })

const antdTheme = {
  components: {
    Popover: {
      // Higher than tooltip.
      zIndexPopup: 1080,
    },
    Tooltip: {
      zIndexPopup: 1070,
    },
    Layout: {
      bodyBg: "#ffffff",
      headerBg: "#ffffff",
    },
    Upload: {
      colorFillAlter: "none",
    },
    Mentions: {
      zIndexPopup: 1090,
    },
    Form: {
      itemMarginBottom: 20,
    },
  },
  token: {
    colorPrimary: "#7e5bed",
    colorLink: "#7e5bed",
    colorLinkHover: "#6549be",
    // Used to represent the visual elements of the operation failure, such as the error Button, error Result component, etc.
    colorError: "#ff4d4f",

    // The background color of the error state.
    colorErrorBg: "#fff2f0",

    // The border color of the error state.
    colorErrorBorder: "#ffccc7",

    // Weak action. Such as `allowClear` or Alert close button
    colorIcon: "#7e9cb7",

    // Weak action hover color. Such as `allowClear` or Alert close button
    colorIconHover: "#527a9f",

    // Used to represent the operation information of the Token sequence, such as Alert, Tag, Progress, and other components use these map tokens.
    colorInfo: "#2171ec",

    // Light background color of information color.
    colorInfoBg: "#e8f0fd",

    // Border color of information color.
    colorInfoBorder: "#bcd4f9",

    // Used to represent the token sequence of operation success, such as Result, Progress and other components will use these map tokens.
    colorSuccess: "#41ad45",

    // Light background color of success color, used for Tag and Alert success state background color
    colorSuccessBg: "#ecf6ec",

    // Border color of success color, used for Tag and Alert success state border color
    colorSuccessBorder: "#c6e6c7",

    // Default text color which comply with W3C standards, and this color is also the darkest neutral color.
    colorText: "#1d2b38",

    // Control the font color of heading.
    colorTextHeading: "#293d50",

    // Used to represent the warning map token, such as Notification, Alert, etc. Alert or Control component(like Input) will use these map tokens.
    colorWarning: "#f88216",

    // The background color of the warning state.
    colorWarningBg: "#fef2e7",

    // The border color of the warning state.
    colorWarningBorder: "#fcd9b9",

    //colorPrimaryBg: "#ffffff",
  },
}

interface Props {
  children: string | React.JSX.Element | React.JSX.Element[]
}

const AntdApp: React.FC<Props> = ({ children }) => {
  return (
    <ConfigProvider theme={antdTheme}>
      <App component={false}>{children}</App>
    </ConfigProvider>
  )
}

export default AntdApp
