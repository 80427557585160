import { ref as storageRef, uploadBytes } from "firebase/storage"

import { getUserClaims } from "../auth/utils"
import type { FileData } from "../documents/types"
import { auth, firebaseStorage } from "../firebaseApp"
import { randomId } from "../utils"

const uploadUserFile = async (fileData: FileData) => {
  const user = auth.currentUser
  if (!user) {
    throw new Error("Not logged in")
  }
  const { activeGroupOid: groupOid } = await getUserClaims(user)

  // Generate a random upload id.
  const uploadId = randomId()

  // TODO(mgraczyk): Use hook in parent to monitor upload progress.
  const metadata = {
    contentType: fileData.fileType,
    contentDisposition: `inline; filename="${fileData.fileName ?? uploadId}"`,
  }

  const bucketPath = `/groups/${groupOid}/user_uploads/${user.uid}/${uploadId}`
  const objectRef = storageRef(firebaseStorage, bucketPath)

  const uploadRes = await uploadBytes(
    objectRef,
    fileData.fileContents,
    metadata,
  )
  console.debug("Upload result", uploadRes)
  return { id: uploadId, uri: objectRef.toString() }
}

export default uploadUserFile
