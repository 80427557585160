import { Skeleton } from "antd"
import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Mentions from "antd/es/mentions"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { makeConverter } from "../../dbUtils"
import { createComment, deleteComment } from "../../discussions/api"
import {
  COMMENTS_SUBCOLLECTION,
  DISCUSSION_COLLECTION,
} from "../../discussions/types"
import type {
  Comment,
  Discussion,
  KnowledgeDiscussionProps,
  SheetDiscussionProps,
  UserDocumentDiscussionProps,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import type { GroupMembership } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import { getColumnLetter } from "../../sheets/utils"
import { GROUPS_COLLECTION } from "../../types/common"
import { CommentComponent } from "./Comment"

type DiscussionProps =
  | KnowledgeDiscussionProps
  | UserDocumentDiscussionProps
  | SheetDiscussionProps

type Props = {
  group_oid: string
  members?: GroupMembership[]
  className?: string
  discussion?: Discussion
  comments?: Comment[]
} & DiscussionProps

const DiscussionComponent: React.FC<Props> = ({
  group_oid,
  members,
  discussion,
  comments,
  className,
  ...args
}) => {
  const { handleError } = useErrorPopup()
  const [draftComment, setDraftComment] = useState<string>("")
  const [isMentionedAssigned, setIsMentionedAssigned] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  // Only load comments if they weren't passed in.
  const [commentsFromDb, commentsFromDbLoading] = useCollectionData(
    discussion && !comments
      ? query(
          collection(
            db,
            GROUPS_COLLECTION,
            group_oid,
            DISCUSSION_COLLECTION,
            discussion.oid,
            COMMENTS_SUBCOLLECTION,
          ),
          where("deleted", "==", false),
          orderBy("created_at", "asc"),
          limit(200),
        ).withConverter(makeConverter<Comment>())
      : null,
  )
  const allComments = comments ?? commentsFromDb
  const commentsLoading = commentsFromDbLoading

  const mentionedMember = members?.find((member) =>
    Mentions.getMentions(draftComment)
      .map((m) => m.value)
      .includes(member.email),
  )

  const onSubmitComment = async () => {
    if (!draftComment || !group_oid) {
      return
    }
    const assignedMember = isMentionedAssigned ? mentionedMember : undefined
    const createCommentArgs = {
      group_oid,
      discussion_oid: discussion?.oid,
      text: draftComment,
      assignment: assignedMember
        ? {
            uid: assignedMember.uid,
            email: assignedMember.email,
          }
        : undefined,
      ...args,
    }
    setSubmitting(true)

    try {
      await createComment(createCommentArgs)
      setDraftComment("")
    } catch (error) {
      handleError({ error })
    } finally {
      setSubmitting(false)
    }
  }

  const locationLabel =
    args.kind === "SHEET" && args.location
      ? `${getColumnLetter(args.location.firstColIndex)}${args.location.firstRowIndex}`
      : undefined

  return (
    <Skeleton active loading={commentsLoading} avatar={false} title={false}>
      <div className={className}>
        {allComments?.map((comment, index) => (
          <CommentComponent
            key={index}
            comment={comment}
            onDelete={async () => {
              if (!discussion) return
              await deleteComment({
                group_oid,
                discussion_oid: discussion.oid,
                comment_oid: comment.oid,
              })
            }}
          />
        ))}
      </div>
      <Mentions
        autoSize
        allowClear
        placeholder={
          discussion
            ? "Write a reply..."
            : locationLabel
              ? `Start a new discussion at ${locationLabel}...`
              : "Start a new discussion..."
        }
        options={members?.map((member) => ({
          value: member.email,
          label: member.email,
        }))}
        value={draftComment}
        onChange={(text) => setDraftComment(text)}
      />
      <Button
        className="mr-2 mt-4"
        type="primary"
        onClick={onSubmitComment}
        loading={submitting}
        disabled={submitting || !draftComment}
      >
        {submitting ? "Commenting..." : "Comment"}
      </Button>
      {mentionedMember && (
        <Checkbox
          key={mentionedMember.uid}
          checked={isMentionedAssigned}
          onChange={(e) => {
            setIsMentionedAssigned(e.target.checked)
          }}
          className="text-xs"
        >
          Assign to {mentionedMember.email}
        </Checkbox>
      )}
    </Skeleton>
  )
}

export default DiscussionComponent
