import { Input } from "antd"

import type { SheetText } from "../../types/answerer"
import type { SheetsRange } from "../../types/sheets"
import ConfidenceScore from "../ConfidenceScore"
import { EmptyResponse } from "./EmptyResponse"
import type { AnswerWithDiscussion } from "./types"
import { getCellLabel } from "./utils"

const { TextArea } = Input

const LocationLabel: React.FC<{
  location: SheetsRange | null | undefined
  jobOid: string
  questionId: string | null | undefined
}> = ({ location, jobOid, questionId }) => {
  if (!location) return null

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`/questionnaire-assistant/review/${jobOid}?sheetName=${location?.sheetName}&firstRowIndex=${location?.firstRowIndex}&firstColIndex=${location?.firstColIndex}`}
      className="hover:bg-primary ml-2 cursor-pointer rounded-md bg-gray-50 px-1 text-sm font-bold text-gray-500 transition-all duration-200 hover:text-white"
    >
      {questionId ?? getCellLabel(location)}
    </a>
  )
}
const renderQuestion = (
  jobOid: string,
  question: SheetText,
  isSecondary: boolean,
  questionId: string | null | undefined,
) => {
  return (
    <div className="mb-4">
      <div className="flex items-center">
        <span className="text-[14px] font-semibold text-gray-700">
          {isSecondary ? "Question Details" : "Question"}
        </span>
        <LocationLabel
          location={question.location}
          jobOid={jobOid}
          questionId={questionId}
        />
      </div>
      <p className="mt-1 whitespace-pre-wrap text-[14px] text-gray-700">
        {question.text}
      </p>
    </div>
  )
}

const renderAnswer = (
  jobOid: string,
  answer: SheetText,
  editing: boolean,
  value: string,
  setValue: (value: string) => void,
  isSecondary = false,
) => (
  <div className="mt-2">
    <div className="flex items-center">
      <span className="text-[14px] font-semibold text-gray-700">
        {isSecondary ? "Answer Explanation" : "Answer"}
      </span>
      <LocationLabel
        location={answer.location}
        jobOid={jobOid}
        questionId={null}
      />
    </div>
    {editing ? (
      <TextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoSize={{ minRows: 2 }}
        className="mt-1 w-full"
      />
    ) : answer.text || isSecondary ? (
      <p className="mt-1 whitespace-pre-wrap text-[14px] text-gray-700">
        {answer.text ?? ""}
      </p>
    ) : (
      <EmptyResponse />
    )}
  </div>
)

export const renderQuestionAnswer = (
  jobOid: string,
  answer: AnswerWithDiscussion,
  editing: boolean,
  editedPrimaryAnswer: string,
  setEditedPrimaryAnswer: (value: string) => void,
  editedSecondaryAnswer: string,
  setEditedSecondaryAnswer: (value: string) => void,
) => (
  <>
    {renderQuestion(jobOid, answer.primary_question, false, answer.question_id)}
    {answer.secondary_question &&
      renderQuestion(jobOid, answer.secondary_question, true, null)}
    {renderAnswer(
      jobOid,
      answer.primary_answer,
      editing,
      editedPrimaryAnswer,
      setEditedPrimaryAnswer,
    )}
    {answer.secondary_answer &&
      renderAnswer(
        jobOid,
        answer.secondary_answer,
        editing,
        editedSecondaryAnswer,
        setEditedSecondaryAnswer,
        true,
      )}
    {!answer.last_reviewed_by && (
      <ConfidenceScore confidence={answer.confidence} />
    )}
  </>
)
