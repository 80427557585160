import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Upload from "antd/es/upload"
import { CloudUploadIcon } from "lucide-react"
import { useState } from "react"

import createSheetsJob from "../../components/QuestionnaireWorkflowWizard/createSheetsJob"
import { QuestionnaireWorkflowWizardFromFiles } from "../../components/QuestionnaireWorkflowWizard/index"
import { readFileAsync } from "../../components/QuestionnaireWorkflowWizard/utils"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import type { FileData } from "../../documents/types"
import type { QuiltFlags } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import { MimeType } from "../../types/mimetype"
import { QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES } from "../../types/mimetype"
import { createJob } from "./createJob"

const { Dragger } = Upload

const getAcceptableMimetypes = (flags: QuiltFlags): MimeType[] => {
  return [
    ...QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES,
    MimeType.PDF,
    ...(flags.showDocxQuestionnaires ? [MimeType.DOCX] : []),
  ]
}

const getAcceptableMimetypesString = (flags: QuiltFlags): string =>
  flags.showDocxQuestionnaires
    ? "Excel, CSV, PDF, or DOCX"
    : "Excel, CSV, or PDF"

const getInvalidFileMessageOrNone = (
  mimetype: MimeType,
  acceptableMimetypes: MimeType[],
  acceptableMimetypesString: string,
): string | null => {
  if (!acceptableMimetypes.includes(mimetype)) {
    return `Invalid file type. Please upload an ${acceptableMimetypesString} file`
  }
  return null
}

export const fileDataFromFile = async (file: File): Promise<FileData> => {
  const fileContents = await readFileAsync(file)

  if (fileContents === null) {
    throw new Error("Unable to read file")
  }

  const fileType = file.type as MimeType
  return {
    fileContents,
    fileType,
    fileName: file.name,
  }
}

// TODO (ishan) pull createJob api into this file

const AddQuestionnaireWorkflowButton: React.FC = () => {
  const [files, setFiles] = useState<FileData[]>([])
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()

  const { flags } = useActiveGroup()

  const acceptableMimetypes = getAcceptableMimetypes(flags)
  const acceptableMimetypesString = getAcceptableMimetypesString(flags)

  const handleFileWithWizard = (fileData: FileData) => {
    setFiles((prevFiles) => [...prevFiles, fileData])
    setOpen(false)
    setModalOpen(true)
    return true
  }

  const handleFileWithoutWizard = async (
    fileData: FileData,
  ): Promise<boolean> => {
    try {
      await createJob({ ...fileData, question_answer_layouts: {} })
      handleSuccess("File uploaded successfully")
    } catch (error) {
      handleError({ error, prefix: "Couldn't upload file" })
    }
    return false
  }

  const parseDocumentFile = async (file: File): Promise<FileData | null> => {
    try {
      const fileData = await fileDataFromFile(file)
      handleSuccess("File parsed successfully")
      return fileData
    } catch (error) {
      handleError({ error, prefix: "Couldn't parse file" })
      return null
    }
  }

  const handleFileChange = async (file: File): Promise<boolean> => {
    if (file === undefined) {
      handleError({ error: null, message: "No file selected" })
      return false
    }
    const fileData = await parseDocumentFile(file)
    if (!fileData) {
      return false
    }
    const invalidFileMessage = getInvalidFileMessageOrNone(
      fileData.fileType,
      acceptableMimetypes,
      acceptableMimetypesString,
    )
    if (invalidFileMessage) {
      console.log("Invalid file uploaded", fileData.fileName, fileData.fileType)
      handleError({
        error: null,
        message: invalidFileMessage,
      })
      return false
    }
    if (QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES.includes(fileData.fileType)) {
      handleFileWithWizard(fileData)
      return true
    } else if (
      fileData.fileType === MimeType.PDF ||
      (flags.showDocxQuestionnaires && fileData.fileType === MimeType.DOCX)
    ) {
      await handleFileWithoutWizard(fileData)
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {modalOpen && (
        <QuestionnaireWorkflowWizardFromFiles
          onClose={() => {
            setModalOpen(false)
            setFiles([])
          }}
          files={files}
          handleDocumentProcessing={createSheetsJob}
          isCompletedQuestionnaire={false}
        />
      )}
      <Popover
        placement="bottomLeft"
        open={open}
        onOpenChange={(newOpen) => setOpen(newOpen)}
        trigger="click"
        content={
          <div className="m-4">
            <Dragger
              className="h-full"
              beforeUpload={handleFileChange}
              showUploadList={false}
            >
              <div className="p-2">
                <CloudUploadIcon
                  className="text-primary mx-auto my-4"
                  size="48"
                />
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  {`${acceptableMimetypesString} files are supported.`}
                </p>
              </div>
            </Dragger>
          </div>
        }
      >
        <Button
          type="primary"
          onClick={() => setOpen(!open)}
          icon={<CloudUploadIcon />}
          className="h-9 font-bold"
        >
          Upload Questionnaire
        </Button>
      </Popover>
    </>
  )
}

export default AddQuestionnaireWorkflowButton
