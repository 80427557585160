import { useEffect, useState } from "react"

import { getDocumentById } from "../../dbUtils"
import { type UserFacingError, getUserFacingError } from "../../errors"
import type { AnyUserDocument } from "../../knowledge/types"
import { type AnswerReference } from "../../types/answerer"
import { USER_DOCUMENTS_COLLECTION } from "../../types/common"
import { getReferenceProps } from "./getReferenceProps"

export const getReferenceTagColorClass = (reference: AnswerReference) => {
  // NOTE: Make sure this matches getReferenceTagColorClass.
  return reference.kind === "KNOWLEDGE_ITEM"
    ? "bg-blue-50 text-blue-600 hover:bg-blue-200 hover:text-blue-500"
    : "bg-purple-50 text-purple-500 hover:bg-purple-200 hover:text-purple-500"
}

export const getReferenceColorClass = (reference: AnswerReference) => {
  return reference.kind === "KNOWLEDGE_ITEM"
    ? "bg-blue-50 text-blue-600 hover:bg-blue-200 hover:text-blue-500"
    : "bg-purple-50 text-purple-500 hover:bg-purple-200 hover:text-purple-500"
}

export const isVerbatimFromAnswerBank = (
  references: AnswerReference[],
): boolean => {
  // TODO(mgraczyk): Fix, add to returned AnswerReference.
  return references.length === 1 && references[0].kind === "KNOWLEDGE_ITEM"
}

export const useEnrichedReference = (
  reference: AnswerReference,
  designVersion: "v2" | "popover",
  loadEagerly?: boolean,
) => {
  const [userDocument, setUserDocument] = useState<AnyUserDocument>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<UserFacingError | null>(null)

  useEffect(() => {
    void (async () => {
      if (userDocument || !loadEagerly || error) {
        return
      }

      setIsLoading(true)
      try {
        const userDocument = await getDocumentById<AnyUserDocument>(
          USER_DOCUMENTS_COLLECTION,
          reference.document_oid,
        )
        setUserDocument(userDocument)
      } catch (e) {
        console.error(e)
        setError(getUserFacingError(e))
      } finally {
        setIsLoading(false)
      }
    })()
  }, [reference.document_oid, loadEagerly, error, userDocument])

  const enrichedReference = getReferenceProps(
    reference,
    designVersion,
    userDocument,
  )

  return { enrichedReference, isLoading, error }
}
