import { pyBackendAxiosInstance } from "../api"

interface UpdateSyncNodesOnlyRequest {
  source_oid: string
  update_nodes: {
    oid: string
    sync_selection: "INCLUDE" | "EXCLUDE" | null
  }[]
}

export const updateSyncNodes = async (request: UpdateSyncNodesOnlyRequest) => {
  await pyBackendAxiosInstance.post("/sources/update_sync_nodes", request)
}
