import { Button, Dropdown } from "antd"
import { Download } from "lucide-react"
import { useCallback, useMemo } from "react"

import { sendAnalyticsEvent } from "../analytics"
import type { AnalyticsEventSurface } from "../analytics/types"
import {
  copyDataToClipboard,
  downloadCsvFile,
  downloadXlsxFile,
} from "../export/exportFiles"
import useErrorPopup from "../hooks/useErrorPopup"

interface ExportButtonProps {
  tableName?: string
  dataGetter: () => string[][]
  disabled?: boolean

  surface: AnalyticsEventSurface
  id: string
}

const ExportButton: React.FC<ExportButtonProps> = ({
  tableName,
  dataGetter,
  surface,
  id,
  disabled = false,
}) => {
  const { handleError, handleSuccess } = useErrorPopup()

  const handleExport = useCallback(
    async (key: string) => {
      if (!tableName) {
        return
      }

      sendAnalyticsEvent({
        event_type: "CLICK",
        event_data: {
          entity_id: id,
        },
        surface,
      })

      const filename = `${tableName.toLowerCase().replace(/\s+/g, "-")}-${new Date().toISOString().split("T")[0]}`

      const data = dataGetter()
      try {
        switch (key) {
          case "clipboard-csv":
            await copyDataToClipboard(data, "csv")
            handleSuccess("CSV copied to clipboard")
            break
          case "clipboard-excel":
            await copyDataToClipboard(data, "excel")
            handleSuccess("Excel format copied to clipboard")
            break
          case "file-csv":
            downloadCsvFile(data, `${filename}.csv`)
            handleSuccess("CSV file downloaded")
            break
          case "file-xlsx":
            downloadXlsxFile({ Sheet1: data }, `${filename}.xlsx`)
            handleSuccess("Excel file downloaded")
            break
          default:
            break
        }
      } catch (error) {
        console.error("Export error:", error)
        handleError({
          error,
          prefix: "Failed to export data",
        })
      }
    },
    [tableName, handleError, handleSuccess, id, surface, dataGetter],
  )

  const exportMenuItems = useMemo(
    () => [
      {
        key: "clipboard-csv",
        label: "To Clipboard (csv)",
      },
      {
        key: "clipboard-excel",
        label: "To Clipboard (excel)",
      },
      {
        key: "file-csv",
        label: "As .csv file",
      },
      {
        key: "file-xlsx",
        label: "As .xlsx file",
      },
    ],
    [],
  )

  return (
    <Dropdown
      menu={{
        items: exportMenuItems,
        onClick: ({ key }) => {
          void handleExport(key)
        },
      }}
      trigger={["click"]}
    >
      <Button
        id={id}
        icon={<Download size={14} />}
        disabled={disabled || !tableName}
      >
        Export...
      </Button>
    </Dropdown>
  )
}

export default ExportButton
