import type { UserDocumentSourceKind } from "../types/userDocument"

export const SOURCE_KIND_DESCRIPTION: Record<UserDocumentSourceKind, string> = {
  COMPLETED_QUESTIONNAIRE: "Imported from Completed Questionnaire",
  CONFLUENCE: "Imported from Confluence",
  FILEUPLOAD: "Uploaded to Quilt",
  GONG: "Imported from Gong",
  GOOGLE_DRIVE: "Imported from Google Drive",
  KNOWLEDGE_ITEM: "Imported from Completed Questionnaire", // TODO(mgraczyk): Migrate these to COMPLETED_QUESTIONNAIRE
  MANUAL: "Manually Created",
  NOTION: "Imported from Notion",
  PUBLIC_WEB_PAGE: "Public Web Page",
  QUESTIONNAIRE_ASSISTANT_IMPORT: "Imported from Questionnaire Assistant",
  SLACK: "Imported from Slack",
  UNKNOWN: "Unknown",
}

export const SOURCE_KIND_SHORT_DESCRIPTION: Record<
  UserDocumentSourceKind,
  string
> = {
  COMPLETED_QUESTIONNAIRE: "Completed Questionnaire",
  CONFLUENCE: "Confluence",
  FILEUPLOAD: "Uploaded to Quilt",
  GONG: "Gong",
  GOOGLE_DRIVE: "Google Drive",
  KNOWLEDGE_ITEM: "Completed Questionnaire", // TODO(mgraczyk): Migrate these to COMPLETED_QUESTIONNAIRE
  MANUAL: "Manually Created",
  NOTION: "Notion",
  PUBLIC_WEB_PAGE: "Web Page",
  QUESTIONNAIRE_ASSISTANT_IMPORT: "Questionnaire Assistant",
  SLACK: "Slack",
  UNKNOWN: "Unknown",
}
