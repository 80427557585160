import axios from "axios"
import { useCallback, useState } from "react"
import { utils as excelUtils, read as readXlsx } from "xlsx"

import { EMPTY_ARRAY } from "../../constants"
import type { IndexableDocument } from "../../types/common"
import { EXCEL_SUPPORTED_MIMETYPES, MimeType } from "../../types/mimetype"
import type { GoogleDriveDocument } from "./state"
import {
  initialDocumentSheetState,
  initialGoogleDriveDocumentState,
} from "./state"
import {
  getDataFromWorkbook,
  getXlsxSheetNameFromGoogleWorksheet,
  readFileAsync,
} from "./utils"

interface GoogleSheetsDataResponse {
  valueRanges: {
    range: string
    majorDimension: string
    values?: string[][]
  }[]
}

export const getGoogleSheetsDocuments = async (
  googleAccessToken: string,
  documents: IndexableDocument[],
): Promise<GoogleDriveDocument[]> => {
  const googleSheetDocs = documents.filter(
    (doc) => doc.mimetype === MimeType.GOOGLE_SHEETS,
  )
  const otherQuestionanaireMimetypes = documents.filter((doc) =>
    EXCEL_SUPPORTED_MIMETYPES.includes(doc.mimetype),
  )
  const wizardGoogleSheetsDocs = await Promise.all(
    googleSheetDocs.map(async (doc) => {
      const { external_document_id: docId } = doc
      const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${encodeURIComponent(
        docId,
      )}`
      const { data: sheetsInfo } = await axios.get<{
        spreadsheetId: string
        spreadsheetUrl: string
        properties: {
          title: string
        }
        sheets: {
          properties: {
            title: string
            sheetId: number
            sheetType: string
            index: number
          }
        }[]
      }>(sheetUrl, {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "Content-Type": "application/json",
        },
      })
      const rangeUrl = sheetsInfo.sheets.reduce(
        (acc: string, sheet, idx: number) => {
          return `${acc}${idx ? "&" : "?"}ranges=${encodeURIComponent(
            sheet.properties.title,
          )}!A1:AA1000`
        },
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetsInfo.spreadsheetId}/values:batchGet`,
      )
      const { data: rangesResponse } =
        await axios.get<GoogleSheetsDataResponse>(rangeUrl, {
          headers: {
            Authorization: `Bearer ${googleAccessToken}`,
            "Content-Type": "application/json",
          },
        })
      const workbook = excelUtils.book_new()
      rangesResponse.valueRanges.forEach((valueRange, idx) => {
        const sheetName = getXlsxSheetNameFromGoogleWorksheet(
          sheetsInfo.sheets[idx],
        )
        const sheet = valueRange.values
          ? excelUtils.aoa_to_sheet(valueRange.values)
          : {}
        excelUtils.book_append_sheet(workbook, sheet, sheetName)
      })

      const workbookData = getDataFromWorkbook(workbook)
      return {
        ...initialGoogleDriveDocumentState,
        oid: doc.oid,
        mimeType: doc.mimetype,
        title: doc.title,
        external_document_id: doc.external_document_id,
        parent_external_id: doc.parent_external_id,
        source_oid: doc.source_oid,
        url: "",
        workbookData,
        sheets: workbookData.sheetNames.map((sheetName) => ({
          ...initialDocumentSheetState,
          sheetName,
        })),
      }
    }),
  )

  const wizardExcelDocs = await Promise.all(
    otherQuestionanaireMimetypes.map(async (doc) => {
      const url = `https://www.googleapis.com/drive/v3/files/${encodeURIComponent(
        doc.external_document_id,
      )}?alt=media`
      const { data: blob } = await axios.get<Blob>(url, {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      })
      const arrayBuffer = await readFileAsync(blob)
      const workbook = readXlsx(arrayBuffer, {
        sheetRows: 200,
        raw: false,
      })
      const workbookData = getDataFromWorkbook(workbook)
      return {
        ...initialGoogleDriveDocumentState,
        mimeType: doc.mimetype,
        title: doc.title,
        external_document_id: doc.external_document_id,
        parent_external_id: doc.parent_external_id,
        source_oid: doc.source_oid,
        url: "",
        workbookData,
        sheets: workbookData.sheetNames.map((sheetName) => ({
          ...initialDocumentSheetState,
          sheetName,
        })),
      }
    }),
  )

  return [...wizardGoogleSheetsDocs, ...wizardExcelDocs]
}

const useIndexableGoogleDriveDocument = (): {
  documents: GoogleDriveDocument[]
  processDocuments: (
    googleAccessToken: string,
    documents: IndexableDocument[],
  ) => Promise<void>
  clearDocuments: () => void
} => {
  const [documents, setDocuments] = useState<GoogleDriveDocument[]>(EMPTY_ARRAY)

  const processDocuments = useCallback(
    async (
      googleAccessToken: string,
      documents: IndexableDocument[],
    ): Promise<void> => {
      const driveDocuments = await getGoogleSheetsDocuments(
        googleAccessToken,
        documents,
      )
      setDocuments(driveDocuments)
    },
    [],
  )

  const clearDocuments = useCallback((): void => {
    setDocuments(EMPTY_ARRAY)
  }, [])

  return {
    documents,
    processDocuments,
    clearDocuments,
  }
}

export default useIndexableGoogleDriveDocument
