import Button from "antd/es/button"
import Popconfirm from "antd/es/popconfirm"
import Tooltip from "antd/es/tooltip"
import { LoaderCircleIcon } from "lucide-react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"

interface IntegrationBlockProps {
  name: string
  connected: boolean
  onConnect: () => void
  onDisconnect: () => void
  disconnectMessage?: string
  loading?: boolean
  disabledReason?: string
  logo?: string
  onEdit?: () => void
  notEnabled?: boolean
}

const IntegrationBlock: React.FC<IntegrationBlockProps> = ({
  name,
  connected,
  onConnect,
  onDisconnect,
  loading,
  disabledReason,
  notEnabled,
  logo,
  onEdit,
  disconnectMessage = "This will remove all synced documents.",
}) => {
  const { hasPerm } = useActiveUserAuthorizationFromContext()

  const hasPermission = hasPerm("admin")

  const notEnabledText =
    "Contact Quilt Sales to enable this integration. Email support@quilt.app or click the question mark on the left sidebar to chat with us."
  const noPermissionText =
    "Contact your admin or Quilt support to manage integrations"

  const connectedDisabledReason = !hasPermission
    ? noPermissionText
    : notEnabled
      ? notEnabledText
      : disabledReason
  const editDisabledReason = connectedDisabledReason

  const editButtonJsx =
    !onEdit && connected ? null : (
      <Tooltip title={editDisabledReason}>
        <Button
          type="default"
          onClick={onEdit}
          disabled={loading || !connected || !!editDisabledReason || notEnabled}
        >
          Modify
        </Button>
      </Tooltip>
    )

  const rightButtonJsx = connected ? (
    <Popconfirm
      title="Disconnect?"
      description={
        <>
          Are you sure you want to disconnect {name}?<br />
          {disconnectMessage}
        </>
      }
      onConfirm={onDisconnect}
      okText="Disconnect"
      cancelText="Cancel"
    >
      <Button type={"default"} danger>
        Disconnect
      </Button>
    </Popconfirm>
  ) : (
    <Tooltip title={connectedDisabledReason}>
      <Button
        type={"primary"}
        onClick={onConnect}
        disabled={!!connectedDisabledReason || notEnabled}
      >
        {notEnabled ? "Contact Sales" : "Connect"}
      </Button>
    </Tooltip>
  )

  return (
    <div className="flex w-full flex-row items-center justify-between rounded-md border p-4">
      <>
        <div className="flex items-center">
          {logo ? (
            <img src={logo} alt={`${name} logo`} className="mr-4 h-8 w-8" />
          ) : null}
          <div>{name}</div>
        </div>
        {loading ? (
          <LoaderCircleIcon className="text-primary animate-spin" />
        ) : (
          <div className="flex gap-2">
            {editButtonJsx}
            {rightButtonJsx}
          </div>
        )}
      </>
    </div>
  )
}

export default IntegrationBlock
