import Button from "antd/es/button"
import Skeleton from "antd/es/skeleton"
import { Trash2 } from "lucide-react"

import { sendAnalyticsEvent } from "../../analytics"
import { useChat } from "../../hooks/useChat"

const SessionSkeleton = () => (
  <div className="flex w-full cursor-pointer border-b border-t border-gray-50 p-3">
    <div className="flex-1">
      <Skeleton
        title={false}
        paragraph={{ rows: 2, width: ["60%", "40%"] }}
        active
      />
    </div>
    <div className="h-8 w-12">
      <Skeleton.Button active size="small" shape="square" block />
    </div>
  </div>
)

const SessionsManager = ({
  onSessionChange,
}: {
  onSessionChange: () => void
}) => {
  const {
    sessions,
    sessionLoading,
    selectedSessionId,
    setSelectedSessionId,
    selectNewSession,
    deleteSession,
  } = useChat()

  return (
    <>
      <Button
        type="primary"
        className="mb-4 h-9 w-full font-bold"
        onClick={() => {
          sendAnalyticsEvent({
            surface: "WEB_CHAT",
            event_type: "CLICK",
            event_data: {
              entity_id: "WEB_CHAT_SIDEPANEL_NEW_SESSION",
            },
          })
          selectNewSession()
          onSessionChange()
        }}
      >
        + New Thread
      </Button>
      <div>
        {sessionLoading ? (
          <>
            <SessionSkeleton />
            <SessionSkeleton />
            <SessionSkeleton />
          </>
        ) : (
          sessions.map((session) => (
            <div
              key={session.oid}
              onClick={() => {
                setSelectedSessionId(session.oid)
                onSessionChange()
              }}
              className={`group flex w-full cursor-pointer items-center justify-between rounded-md p-3 hover:bg-gray-50 ${
                selectedSessionId === session.oid
                  ? "border border-gray-300 bg-gray-50"
                  : ""
              }`}
            >
              <div className="min-w-0 flex-1 pr-2">
                <div className="truncate font-semibold">
                  {session.summary_text || "New Thread"}
                </div>
                <div className="text-xs text-gray-300">
                  {new Date(session.created_at.seconds * 1000).toDateString()}
                </div>
              </div>
              <Button
                className="hidden flex-shrink-0 group-hover:inline-flex"
                title="Delete Chat"
                danger
                onClick={async (e) => {
                  e.stopPropagation()
                  await deleteSession(session.oid)
                  sendAnalyticsEvent({
                    surface: "WEB_CHAT",
                    event_type: "DELETE_CHAT_SESSION",
                    event_data: {
                      entity_type: "CHAT_SESSION",
                      entity_id: session.oid,
                    },
                  })
                }}
                icon={<Trash2 size={16} />}
              />
            </div>
          ))
        )}
      </div>
    </>
  )
}

export default SessionsManager
