import type { TableProps } from "antd"
import { Alert, Table, Tabs } from "antd"
import { Timestamp } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"

import TimelineChart from "../../components/Charts/Timeline"
import type { SimpleDataPoint } from "../../components/Charts/types"
import ExportButton from "../../components/ExportButton"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { getInterpretableTimeString } from "../../timeUtils"
import { OverviewStats, calculateOverviewMetrics } from "./OverviewStats"
import type { ReportingData } from "./api"
import { getReportingData } from "./api"

interface UserWeeklyUsageRow {
  email: string
  week: string
  freq_ask_question: number
  freq_submit_questionnaire: number
  freq_extension_questionnaire: number
}

interface ActiveUsersRow {
  email: string
  n_questions_30_days: number
  n_questions_7_days: number
  L30: number
  L7: number
}

interface AnsweredQuestionsRow {
  week: string
  total_questions: number
  answered_questions: number
}

const userWeeklyUsageColumns: TableProps<UserWeeklyUsageRow>["columns"] = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    defaultSortOrder: null,
  },
  {
    title: "Week",
    dataIndex: "week",
    key: "week",
    sorter: (a, b) => a.week.localeCompare(b.week),
    render: (text: string) => new Date(text).toLocaleDateString(),
    defaultSortOrder: "descend",
  },
  {
    title: "Questions Asked",
    dataIndex: "freq_ask_question",
    key: "freq_ask_question",
    sorter: (a, b) => a.freq_ask_question - b.freq_ask_question,
  },
  {
    title: "Questionnaires Submitted",
    dataIndex: "freq_submit_questionnaire",
    key: "freq_submit_questionnaire",
    sorter: (a, b) => a.freq_submit_questionnaire - b.freq_submit_questionnaire,
  },
  {
    title: "Extension Questionnaires",
    dataIndex: "freq_extension_questionnaire",
    key: "freq_extension_questionnaire",
    sorter: (a, b) =>
      a.freq_extension_questionnaire - b.freq_extension_questionnaire,
  },
]

const activeUsersColumns: TableProps<ActiveUsersRow>["columns"] = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Questions (30 days)",
    dataIndex: "n_questions_30_days",
    key: "n_questions_30_days",
    sorter: (a, b) => a.n_questions_30_days - b.n_questions_30_days,
    defaultSortOrder: "descend",
  },
  {
    title: "Days Active (30 days)",
    dataIndex: "L30",
    key: "L30",
    sorter: (a, b) => a.L30 - b.L30,
  },
  {
    title: "Questions (7 days)",
    dataIndex: "n_questions_7_days",
    key: "n_questions_7_days",
    sorter: (a, b) => a.n_questions_7_days - b.n_questions_7_days,
  },
  {
    title: "Days Active (7 days)",
    dataIndex: "L7",
    key: "L7",
    sorter: (a, b) => a.L7 - b.L7,
  },
]

const answeredQuestionsColumns: TableProps<AnsweredQuestionsRow>["columns"] = [
  {
    title: "Week",
    dataIndex: "week",
    key: "week",
    sorter: (a, b) => a.week.localeCompare(b.week),
    render: (text: string) => new Date(text).toLocaleDateString(),
    defaultSortOrder: "descend",
  },
  {
    title: "Total Questions",
    dataIndex: "total_questions",
    key: "total_questions",
    sorter: (a, b) => a.total_questions - b.total_questions,
  },
  {
    title: "Answered Questions",
    dataIndex: "answered_questions",
    key: "answered_questions",
    sorter: (a, b) => a.answered_questions - b.answered_questions,
  },
]

const transformToQuestionnairesTimeline = (
  userWeeklyUsage: UserWeeklyUsageRow[] | undefined,
): SimpleDataPoint[] => {
  if (!userWeeklyUsage || !Array.isArray(userWeeklyUsage)) {
    return []
  }

  // Group by week and sum the questionnaires
  const weeklyTotals: Record<string, number> = {}

  userWeeklyUsage.forEach((item) => {
    const week = item.week
    if (!weeklyTotals[week]) {
      weeklyTotals[week] = 0
    }
    weeklyTotals[week] += item.freq_submit_questionnaire
  })

  // Convert to SimpleDataPoint format and sort by week
  const result = Object.entries(weeklyTotals).map(([weekStart, count]) => {
    // Convert ISO date to the required format: m/d/yyyy
    const date = new Date(weekStart)
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

    return {
      weekStart: formattedDate,
      count,
      isoDate: weekStart,
    }
  })

  return result.sort((a, b) => a.isoDate.localeCompare(b.isoDate))
}

const tableDataToArray = <T extends object>(
  columns: TableProps<T>["columns"],
  data?: T[],
  includeHeaders = true,
): string[][] => {
  if (!columns || !data) {
    return []
  }

  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const headers = columns.map((col) => col.title?.toString() ?? "")
  const dataRows = data.map((row) => {
    return columns.map((col) => {
      return row[col.key as keyof T]?.toString() ?? ""
    })
  })

  return includeHeaders ? [headers, ...dataRows] : dataRows
}

const useReportingData = (
  groupOid: string,
): [ReportingData | null, boolean, Error | null] => {
  const [data, setData] = useState<ReportingData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchReportingData = async () => {
      try {
        const response = await getReportingData(groupOid)
        setData(response)
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false)
      }
    }
    void fetchReportingData()
  }, [groupOid])

  return [data, loading, error]
}

type Props = Record<string, never>

const GroupReportingPage: React.FC<Props> = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [reportingData, reportingDataLoading, reportingDataError] =
    useReportingData(activeGroupOid)

  const questionnairesTimelineData = useMemo(
    () => transformToQuestionnairesTimeline(reportingData?.user_weekly_usage),
    [reportingData?.user_weekly_usage],
  )

  const overviewMetrics = useMemo(
    () => calculateOverviewMetrics(reportingData),
    [reportingData],
  )

  if (reportingDataError) {
    return (
      <Alert
        type="error"
        message="Unable to load data, try refreshing the page or contact support"
      />
    )
  }

  const createdAtLabel = reportingDataLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      Report Generated:{" "}
      {getInterpretableTimeString(
        Timestamp.fromDate(new Date(reportingData?.created_at ?? "")),
      )}
    </div>
  )

  const items = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <div>
          {createdAtLabel}
          <OverviewStats
            metrics={overviewMetrics}
            loading={reportingDataLoading}
          />
          <TimelineChart
            data={questionnairesTimelineData}
            loading={reportingDataLoading}
            title="Questionnaires Submitted Weekly"
            downloadFileName="quilt_questionnaires_submitted_weekly.csv"
          />
          <TimelineChart
            data={reportingData?.answered_questions.map((r) => ({
              weekStart: new Date(r.week).toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              }),
              questionsAnswered: r.answered_questions,
              questionsAsked: r.total_questions,
            }))}
            loading={reportingDataLoading}
            title="Questions Asked Weekly"
            downloadFileName="quilt_questions_asked_weekly.csv"
            isStacked
          />
        </div>
      ),
    },
    {
      key: "weekly-usage",
      label: "Weekly Usage",
      children: (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            {createdAtLabel}
            <ExportButton
              tableName="quilt_weekly_usage"
              dataGetter={() =>
                tableDataToArray(
                  userWeeklyUsageColumns,
                  reportingData?.user_weekly_usage,
                )
              }
              surface="WEB_GROUP_REPORTING"
              id="reporting-weekly-usage-export"
            />
          </div>
          <Table
            scroll={{ y: 500 }}
            virtual
            pagination={false}
            bordered
            columns={userWeeklyUsageColumns}
            dataSource={reportingData?.user_weekly_usage}
            loading={reportingDataLoading}
            size="middle"
            rowKey={(r: UserWeeklyUsageRow) => `${r.email}-${r.week}`}
          />
        </div>
      ),
    },
    {
      key: "active-users",
      label: "Active Users",
      children: (
        <div className="flex grow flex-col gap-4">
          <div className="flex flex-row justify-between">
            {createdAtLabel}
            <ExportButton
              tableName="quilt_active_users"
              dataGetter={() =>
                tableDataToArray(
                  activeUsersColumns,
                  reportingData?.active_users,
                )
              }
              surface="WEB_GROUP_REPORTING"
              id="reporting-active-users-export"
            />
          </div>
          <Table
            scroll={{ y: 500 }}
            virtual
            pagination={false}
            bordered
            columns={activeUsersColumns}
            dataSource={reportingData?.active_users}
            loading={reportingDataLoading}
            size="middle"
            rowKey={(r: ActiveUsersRow) => r.email}
          />
        </div>
      ),
    },
    {
      key: "answered-questions",
      label: "Answered Questions",
      children: (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            {createdAtLabel}
            <ExportButton
              tableName="quilt_answered_questions"
              dataGetter={() =>
                tableDataToArray(
                  answeredQuestionsColumns,
                  reportingData?.answered_questions,
                )
              }
              surface="WEB_GROUP_REPORTING"
              id="reporting-answered-questions-export"
            />
          </div>
          <Table
            scroll={{ y: 500 }}
            virtual
            pagination={false}
            bordered
            columns={answeredQuestionsColumns}
            dataSource={reportingData?.answered_questions}
            loading={reportingDataLoading}
            size="middle"
            rowKey={(r: AnsweredQuestionsRow) => r.week}
          />
        </div>
      ),
    },
  ]

  return (
    <div className="flex grow flex-col">
      <h3>Reporting and Metrics</h3>
      <Tabs className="grow" items={items} />
    </div>
  )
}

export default GroupReportingPage
