import Button from "antd/es/button"
import Radio from "antd/es/radio"
import type { RadioChangeEvent } from "antd/es/radio"
import { useCallback, useState } from "react"

import { updateUserGroupRole } from "../../groups/api"
import type { Group, GroupMembership, GroupRole } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import { ROLE_INFOS } from "./constants"

const roleOptions = [
  ROLE_INFOS.ADMIN,
  ROLE_INFOS.MEMBER,
  ROLE_INFOS.VIEWER,
  ROLE_INFOS.CONTENT_MANAGER,
].map((roleInfo) => ({
  style: {
    marginBottom: "1rem",
    padding: "0.5rem",
    border: "1px solid #cbd7e2",
    borderRadius: "0.75rem",
  },
  label: (
    <>
      <div className="font-semibold">{roleInfo.name}</div>
      <div className="text-gray-800">{roleInfo.description}</div>
    </>
  ),
  value: roleInfo.role,
}))

interface UpdateMemberConfirmationProps {
  group: Group
  membership: GroupMembership
  onClose: () => void
}

const UpdateMemberConfirmation: React.FC<UpdateMemberConfirmationProps> = ({
  group,
  onClose,
  membership,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<GroupRole>(membership.role)

  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onChange = useCallback((e: RadioChangeEvent) => {
    setSelectedRole(e.target.value as GroupRole)
  }, [])

  const onSubmit = async () => {
    if (!selectedRole) {
      return
    }
    setSubmitting(true)
    try {
      await updateUserGroupRole({
        uid: membership.uid,
        group_oid: group.oid,
        role: selectedRole,
      })
      handleSuccess("Updated group member")
      onClose()
    } catch (error) {
      handleError({ error, prefix: "Couldn't update member role" })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="max-w-[500px]">
      <h4>Edit Role for {membership.email}</h4>
      <Radio.Group
        onChange={onChange}
        options={roleOptions}
        value={selectedRole}
      />
      <div className="flex gap-2">
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          {isSubmitting ? "Updating..." : "Confirm"}
        </Button>
        <Button type="default" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
export default UpdateMemberConfirmation
