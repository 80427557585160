import type { UserDocumentState } from "../../types/userDocument"

interface StateUI {
  name: string
  classNames: string
  tooltip: string
}

export const STATE_MAP: Record<UserDocumentState, StateUI> = {
  START: {
    name: "Starting",
    classNames: "text-gray-300 bg-gray-25",
    tooltip: "Your document is queued and will be indexed soon.",
  },
  LAYOUT_REQUIRED: {
    // TODO(mgraczyk): Add specific tooltip and link to set layout page.
    name: "Processing",
    classNames: "text-gray-300 bg-gray-25",
    tooltip: "We are extracting information and indexing your document.",
  },
  PROCESSING: {
    name: "Processing",
    classNames: "text-gray-300 bg-gray-25",
    tooltip: "We are extracting information and indexing your document.",
  },
  REPROCESSING: {
    name: "Reprocessing",
    classNames: "text-gray-300 bg-gray-25",
    tooltip:
      "We are extracting information and reindexing your document with the latest changes.",
  },
  READY: {
    name: "Ready",
    classNames: "text-teal-500 bg-teal-50",
    tooltip: "Your document will be used for answers and in search results.",
  },
  PERMANENT_ERROR: {
    name: "Failed",
    classNames: "text-red-500 bg-red-100",
    tooltip: "There was an error processing your document.",
  },
  REMOVING: {
    name: "Removing",
    classNames: "text-red-500 bg-red-100",
    tooltip: "Your document will be removed soon.",
  },
  REMOVED: {
    name: "Removed",
    classNames: "text-gray-300 bg-gray-25",
    tooltip: "Your document has been.",
  },
  UNKNOWN: {
    name: "Unknown",
    classNames: "text-red-500 bg-red-100",
    tooltip: "",
  },
}
