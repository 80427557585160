import { signInWithCustomToken } from "firebase/auth"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"

import { auth } from "../firebaseApp"
import useSearchParamValue from "../hooks/useSearchParamValue"

const ImpersonatorLogin = () => {
  const navigate = useNavigate()
  const [token] = useSearchParamValue("token")
  const [err, setError] = useState<Error | null>(null)
  useEffect(() => {
    async function tokenLogin() {
      if (!token) {
        return
      }
      try {
        await signInWithCustomToken(auth, token)
      } catch (error) {
        setError(error as Error)
      }
      void navigate("/")
    }
    void tokenLogin()
  }, [token, navigate])

  if (err) {
    return <div>Error: {err.message}</div>
  }
  return <div className="m-24 text-2xl">Logging you in...</div>
}

export default ImpersonatorLogin
