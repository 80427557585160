import { getColumnNumber } from "./utils"

type RowData = Record<string, unknown>

export function getNonemptyCols(rows: RowData[]): string[] {
  const nonEmptyCols = new Set<string>()

  for (const row of rows) {
    for (const key in row) {
      if (row[key]) {
        nonEmptyCols.add(key)
      }
    }
  }

  return Array.from(nonEmptyCols)
}

export function removeEmptyColumns<T extends RowData>(rows: T[]): T[] {
  const cols = getNonemptyCols(rows)
  return rows.map(
    (row) => Object.fromEntries(cols.map((k) => [k, row[k]])) as T,
  )
}

export function removeEmptyColumnsFromEnd<T extends RowData>(rows: T[]): T[] {
  const colNums = getNonemptyCols(rows).map(getColumnNumber)
  const maxNonemptyColNum = Math.max(...colNums)

  return rows.map(
    (row) =>
      Object.fromEntries(
        Object.entries(row).filter(
          ([k]) => getColumnNumber(k) <= maxNonemptyColNum,
        ),
      ) as T,
  )
}

export function removeEmptyRowsFromEnd<T extends RowData>(rows: T[]): T[] {
  let lastIndex = rows.length - 1

  while (lastIndex >= 0) {
    const row = rows[lastIndex]
    const isEmptyRow = Object.values(row).every((value) => !value)

    if (isEmptyRow) {
      lastIndex--
    } else {
      break
    }
  }

  return rows.slice(0, lastIndex + 1)
}
