/*
A full page React component that implements an "Agent Table".

The agent table is a way to run an LLM on many inputs with many outputs.
It should look like a spreadsheet or table, where the columns have special configurable headers.

Never shift the layout of the table too much when the results come in.
*/
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Progress,
  Table,
  Tooltip,
} from "antd"
import type { FormInstance } from "antd"
import type { ColumnGroupType, ColumnType } from "antd/es/table"
import { doc } from "firebase/firestore"
import {
  FoldVerticalIcon,
  ListEndIcon,
  Loader,
  PencilIcon,
  Play,
  PlusIcon,
  RotateCcw,
  Trash2,
} from "lucide-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useParams } from "react-router"

import { sendAnalyticsEvent } from "../../analytics"
import AutoExpandingTextArea from "../../components/AutoExpandingTextArea"
import CopyToClipboardButton from "../../components/CopyToClipboardButton"
import ExportButton from "../../components/ExportButton"
import Header from "../../components/Header"
import useTableScroll from "../../components/table/useTableScroll"
import { EMPTY_ARRAY } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useErrorPopup from "../../hooks/useErrorPopup"
import { randomId } from "../../utils"
import { runAgentTablePrompts } from "./api"
import { colRef, rowsColRef, updateAgentTable } from "./crud"
import { tableDataToArray } from "./exportUtils"
import { syncLocalRowsWithDbRows } from "./sync"
import type { AgentTableRow, ColumnConfig } from "./types"

interface UseAgentTableRowsReturn {
  rows: AgentTableRow[]
  loading: boolean
  saving: boolean
  error: Error | undefined
  handleAddRow: () => void
  handleRemoveEmptyRows: () => void
  handleClearOutputs: () => void
  handlePaste: (e: React.ClipboardEvent) => void
  setRows: React.Dispatch<React.SetStateAction<AgentTableRow[]>>
  progress: number
  setProgress: React.Dispatch<React.SetStateAction<number>>
  focusedCell: { rowOid: string; columnId: string } | null
  setFocusedCell: React.Dispatch<
    React.SetStateAction<{ rowOid: string; columnId: string } | null>
  >
}

interface UseAgentTableRunReturn {
  processing: boolean
  handleRun: () => Promise<void>
}

const useAgentTableRows = (
  groupOid: string,
  tableOid: string,
  columns: ColumnConfig[],
): UseAgentTableRowsReturn => {
  const initialLoad = useRef(true)

  // TODO(mgraczyk): Maybe only load once, and track db state locally?
  const [dbRows, dbRowsLoading, error] = useCollectionData(
    rowsColRef(groupOid, tableOid),
  )

  const [syncError, setSyncError] = useState<Error | undefined>(undefined)
  const [saving, setSaving] = useState(false)
  const [rows, setRows] = useState<AgentTableRow[]>(EMPTY_ARRAY)
  const [progress, setProgress] = useState(0)
  const [focusedCell, setFocusedCell] = useState<{
    rowOid: string
    columnId: string
  } | null>(null)
  const pendingWriteTask = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    // Copy database data to local state on initial load.
    if (dbRows !== undefined && initialLoad.current) {
      setRows(dbRows)
    }
  }, [dbRows])

  const syncLocalRowsWithDbRowsInner = useCallback(
    async (dbRows: AgentTableRow[], rows: AgentTableRow[]) => {
      setSaving(true)
      try {
        await syncLocalRowsWithDbRows(groupOid, tableOid, dbRows, rows)
      } catch (error) {
        console.error("Error syncing rows with database:", error)
        setSyncError(error as Error)
      } finally {
        setSaving(false)
      }
    },
    [groupOid, tableOid],
  )

  useEffect(() => {
    // Update local state when the database data changes.
    if (dbRows === undefined) return
    if (error || syncError) return
    if (initialLoad.current) {
      initialLoad.current = false
      return
    }
    if (pendingWriteTask.current) {
      clearTimeout(pendingWriteTask.current)
    }
    pendingWriteTask.current = setTimeout(() => {
      pendingWriteTask.current = null
      void syncLocalRowsWithDbRowsInner(dbRows, rows)
    }, 1000)
  }, [rows, dbRows, syncLocalRowsWithDbRowsInner, error, syncError])

  const handleAddRow = useCallback(() => {
    setProgress(0)
    setRows((prev) => [
      ...prev,
      {
        oid: randomId(),
        sort_index: prev.length > 0 ? prev[prev.length - 1].sort_index + 1 : 0,
        inputs: {},
        outputs: {},
      },
    ])
  }, [])

  const handleRemoveEmptyRows = useCallback(() => {
    setRows((prev) =>
      prev.filter((row) => {
        // Check if any input or output has a value
        return (
          Object.values(row.inputs).some((value) => value?.trim()) ||
          Object.values(row.outputs).some((output) => output?.value?.trim())
        )
      }),
    )
  }, [])

  const handleClearOutputs = useCallback(() => {
    setProgress(0)
    setRows((prev) =>
      prev.map((row) => ({
        ...row,
        outputs: {},
      })),
    )
  }, [])

  const handlePaste = useCallback(
    (e: React.ClipboardEvent) => {
      e.preventDefault()
      const text = e.clipboardData?.getData("text/plain")
      if (!text || !columns.length) return

      // Clean up the text - handle both Windows and Mac line endings
      const cleanText = text.replace(/\r\n/g, "\n").trim()

      // Split into rows, handling both tab-separated and Excel-copied content
      const pastedRows = cleanText
        .split("\n")
        .map((row) => row.split("\t").map((cell) => cell.trim()))

      setRows((prev) => {
        const newRows = [...prev]

        // Find the starting column index based on the focused cell
        let startColumnIndex = 0
        if (focusedCell) {
          const focusedColumnIndex = columns.findIndex(
            (col) => col.id === focusedCell.columnId,
          )
          if (focusedColumnIndex !== -1) {
            startColumnIndex = focusedColumnIndex
          }
        }

        // Find the starting row index based on the focused cell
        let startRowIndex = 0
        if (focusedCell) {
          const focusedRowIndex = newRows.findIndex(
            (row) => row.oid === focusedCell.rowOid,
          )
          if (focusedRowIndex !== -1) {
            startRowIndex = focusedRowIndex
          }
        }

        // Add new rows if needed to accommodate all pasted rows
        const totalRowsNeeded = startRowIndex + pastedRows.length
        while (newRows.length < totalRowsNeeded) {
          const lastRow = newRows[newRows.length - 1]
          const newSortIndex = lastRow ? lastRow.sort_index + 1 : 0
          newRows.push({
            oid: randomId(),
            sort_index: newSortIndex,
            inputs: {},
            outputs: {},
          })
        }

        // For each pasted row
        pastedRows.forEach((pastedRow, rowIndex) => {
          const targetRowIndex = startRowIndex + rowIndex

          // For each cell in the pasted row
          pastedRow.forEach((cellValue, colIndex) => {
            const targetColumnIndex = startColumnIndex + colIndex
            // Skip if we've run out of columns
            if (targetColumnIndex >= columns.length) return

            const targetColumn = columns[targetColumnIndex]
            if (targetColumn.type === "input") {
              newRows[targetRowIndex] = {
                ...newRows[targetRowIndex],
                inputs: {
                  ...newRows[targetRowIndex].inputs,
                  [targetColumn.id]: cellValue,
                },
              }
            } else {
              newRows[targetRowIndex] = {
                ...newRows[targetRowIndex],
                outputs: {
                  ...newRows[targetRowIndex].outputs,
                  [targetColumn.id]: { value: cellValue },
                },
              }
            }
          })
        })

        return newRows
      })
    },
    [columns, focusedCell],
  )

  return {
    rows,
    loading: dbRowsLoading,
    saving,
    error: error ?? syncError,
    handleAddRow,
    handleRemoveEmptyRows,
    handleClearOutputs,
    handlePaste,
    setRows,
    progress,
    setProgress,
    focusedCell,
    setFocusedCell,
  }
}

interface UseAgentTableRunReturn {
  processing: boolean
  handleRun: () => Promise<void>
}

const useAgentTableRun = (
  columns: ColumnConfig[],
  rows: AgentTableRow[],
  setRows: React.Dispatch<React.SetStateAction<AgentTableRow[]>>,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
): UseAgentTableRunReturn => {
  const { handleError } = useErrorPopup()
  const [processing, setProcessing] = useState(false)

  const handleRun = async () => {
    setProcessing(true)
    setProgress(0)

    sendAnalyticsEvent({
      event_type: "CLICK",
      event_data: {
        entity_id: "agent-table-run",
      },
      surface: "WEB_AGENT_TABLE",
    })

    const rowPromises = rows.map(async (row, index) => {
      // If all of the inputs are empty, skip this row
      if (Object.values(row.inputs).every((input) => !input)) {
        return
      }

      const payload = {
        inputs: row.inputs,
        columns: columns.map((col) => ({
          id: col.id,
          type: col.type,
          title: col.title,
          description: col.description,
          ...(col.type === "output" && {
            prompt: col.prompt,
            tools: col.tools,
          }),
        })),
      }

      // Mark this row's outputs as loading
      setRows((prev) =>
        prev.map((r) =>
          r.oid === row.oid
            ? {
                ...r,
                outputs: Object.fromEntries(
                  columns
                    .filter((c) => c.type === "output")
                    .map((c) => [c.id, { loading: true }]),
                ),
              }
            : r,
        ),
      )

      try {
        const results = await runAgentTablePrompts(payload)
        setRows((prev) =>
          prev.map((r) =>
            r.oid === row.oid
              ? {
                  ...r,
                  outputs: Object.fromEntries(
                    columns
                      .filter((c) => c.type === "output")
                      .map((c) => [c.id, { value: results[c.id] }]),
                  ),
                }
              : r,
          ),
        )
        setProgress((prev) => prev + 1)
        return { success: true, rowOid: row.oid }
      } catch (error) {
        handleError({
          error,
          prefix: `Failed to process row ${index + 1}`,
        })
        // Reset loading state for this row
        setRows((prev) =>
          prev.map((r) =>
            r.oid === row.oid
              ? {
                  ...r,
                  outputs: Object.fromEntries(
                    columns
                      .filter((c) => c.type === "output")
                      .map((c) => [c.id, { value: "" }]),
                  ),
                }
              : r,
          ),
        )
        return { success: false, rowOid: row.oid, error }
      }
    })

    try {
      await Promise.all(rowPromises)
    } finally {
      setProcessing(false)
    }
  }

  return { processing, handleRun }
}

type FormDataType = Omit<ColumnConfig, "id">

interface ColumnFormProps {
  form: FormInstance<FormDataType>
  editingColumn: ColumnConfig | null
  onFinish: (values: Omit<ColumnConfig, "id">) => void
  onCancel: () => void
  visible: boolean
}

const ColumnConfigurationForm: React.FC<ColumnFormProps> = ({
  form,
  editingColumn,
  onFinish,
  onCancel,
  visible,
}) => {
  const columnType = Form.useWatch("type", form)
  const webSearchEnabled = Form.useWatch<boolean>(["tools", "web_search"], form)

  const initialValues: FormDataType = {
    type: columnType,
    title: editingColumn?.title ?? "",
    description: editingColumn?.description ?? "",
    prompt: editingColumn?.prompt ?? "",
    tools: {
      web_search: editingColumn?.tools?.web_search ?? false,
      site_whitelist: editingColumn?.tools?.site_whitelist ?? "",
    },
  }

  return (
    <Modal
      title={`${editingColumn ? "Edit" : "Add"} Column`}
      open={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>

        {columnType === "output" && (
          <>
            <Form.Item
              label="Prompt"
              name="prompt"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name={["tools", "web_search"]} valuePropName="checked">
              <Checkbox>Enable Web Search</Checkbox>
            </Form.Item>
            {webSearchEnabled && (
              <Form.Item
                label="Site Whitelist"
                name={["tools", "site_whitelist"]}
              >
                <Input placeholder="example.com, another.org" />
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </Modal>
  )
}

interface CreateColumnTableArgs {
  column: ColumnConfig
  onEdit: (column: ColumnConfig) => void | Promise<void>
  onDelete: (columnId: string) => void | Promise<void>
  setRows: React.Dispatch<React.SetStateAction<AgentTableRow[]>>
  disabled: boolean
  setFocusedCell: React.Dispatch<
    React.SetStateAction<{ rowOid: string; columnId: string } | null>
  >
  setProgress: (value: number) => void
}

const createTableColumn = ({
  column,
  onEdit,
  onDelete,
  setRows,
  disabled,
  setFocusedCell,
  setProgress,
}: CreateColumnTableArgs):
  | ColumnType<AgentTableRow>
  | ColumnGroupType<AgentTableRow> => {
  return {
    title: (
      <div className="group flex items-center gap-2">
        <span>{column.title}</span>
        <div className="opacity-0 transition-opacity group-hover:opacity-100">
          <Button
            icon={<PencilIcon size={14} />}
            type="text"
            onClick={() => onEdit(column)}
            disabled={disabled}
          />
          <Button
            icon={<Trash2 size={14} />}
            type="text"
            danger
            onClick={() => onDelete(column.id)}
            disabled={disabled}
          />
        </div>
      </div>
    ),
    dataIndex:
      column.type === "input" ? ["inputs", column.id] : ["outputs", column.id],
    key: column.id,
    render: (_: string | undefined, record) => {
      const isOutput = column.type === "output"
      const value =
        (isOutput
          ? record.outputs[column.id]?.value
          : record.inputs[column.id]) ?? ""
      return (
        <div className="group relative">
          {record.outputs[column.id]?.loading && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/80">
              <Loader className="animate-spin text-blue-500" size={16} />
            </div>
          )}
          <AutoExpandingTextArea
            value={value}
            className="w-full resize-none p-1"
            disabled={
              disabled || (isOutput && !!record.outputs[column.id]?.loading)
            }
            lineHeight={24}
            onChange={(e) => {
              setProgress(0)
              setRows((prev) =>
                prev.map((row) =>
                  row.oid === record.oid
                    ? {
                        ...row,
                        ...(isOutput
                          ? {
                              outputs: {
                                ...row.outputs,
                                [column.id]: { value: e.target.value },
                              },
                            }
                          : {
                              inputs: {
                                ...row.inputs,
                                [column.id]: e.target.value,
                              },
                            }),
                      }
                    : row,
                ),
              )
            }}
            onFocus={() =>
              setFocusedCell({ rowOid: record.oid, columnId: column.id })
            }
          />
          {isOutput && (
            <div className="absolute right-2 top-[7px] opacity-0 transition-opacity group-hover:opacity-100">
              <CopyToClipboardButton
                text={value}
                height={16}
                customCopyTooltip="Copy cell contents"
              />
            </div>
          )}
        </div>
      )
    },
  }
}

export default function AgentTablePage() {
  const { tableOid = "" } = useParams<{ tableOid: string }>()
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()

  // TODO(mgraczyk): Add error handling
  const [table, tableLoading, tableError] = useDocumentData(
    doc(colRef(activeGroupOid), tableOid),
  )
  const [addColumnModalVisible, setAddColumnModalVisible] = useState(false)
  const [editingColumn, setEditingColumn] = useState<ColumnConfig | null>(null)
  const [form] = Form.useForm<FormDataType>()
  const [updating, setUpdating] = useState(false)
  const { handleError } = useErrorPopup()
  const [tableContainerRef, tableScrollProp] = useTableScroll()

  const columns = useMemo(() => table?.columns ?? [], [table])

  const {
    rows,
    loading: dbRowsLoading,
    saving: dbRowsSaving,
    error: dbRowsError,
    handleAddRow,
    handleRemoveEmptyRows,
    handleClearOutputs,
    handlePaste,
    setRows,
    progress,
    setProgress,
    setFocusedCell,
  } = useAgentTableRows(activeGroupOid, tableOid, columns)

  const { processing, handleRun } = useAgentTableRun(
    columns,
    rows,
    setRows,
    setProgress,
  )
  const loading = tableLoading || dbRowsLoading

  const updateTableColumns = useCallback(
    async (newColumns: ColumnConfig[]) => {
      if (!table || !authUser) return

      try {
        setUpdating(true)
        await updateAgentTable(authUser, activeGroupOid, {
          ...table,
          columns: newColumns,
        })
      } catch (error) {
        handleError({
          error,
          prefix: "Failed to update table columns",
        })
      } finally {
        setUpdating(false)
      }
    },
    [table, authUser, activeGroupOid, handleError],
  )

  const tableColumns = useMemo(() => {
    return columns.map((col) =>
      createTableColumn({
        column: col,
        onEdit: (column) => {
          if (updating) return
          setEditingColumn(column)
          form.setFieldsValue(column)
          setAddColumnModalVisible(true)
        },
        onDelete: async (columnId) => {
          if (updating) return
          const newColumns = columns.filter((c) => c.id !== columnId)
          try {
            await updateTableColumns(newColumns)
          } catch (error) {
            handleError({
              error,
              prefix: "Failed to delete column",
            })
          }
        },
        setRows,
        disabled: updating,
        setFocusedCell,
        setProgress,
      }),
    )
  }, [
    columns,
    form,
    setRows,
    updateTableColumns,
    updating,
    handleError,
    setFocusedCell,
    setProgress,
  ])

  const handleAddColumn = async (values: Omit<ColumnConfig, "id">) => {
    if (updating) return

    const newColumn: ColumnConfig = {
      id: `col-${Date.now()}`,
      ...values,
      type: editingColumn ? editingColumn.type : values.type,
    }

    let newColumns: ColumnConfig[] = []

    if (editingColumn) {
      newColumns = columns.map((col) =>
        col.id === editingColumn.id ? newColumn : col,
      )
    } else {
      if (newColumn.type === "input") {
        // Find the index of the first output column
        const firstOutputIndex = columns.findIndex(
          (col) => col.type === "output",
        )
        if (firstOutputIndex === -1) {
          // If no output columns, append to the end
          newColumns = [...columns, newColumn]
        } else {
          // Insert before the first output column
          newColumns = [
            ...columns.slice(0, firstOutputIndex),
            newColumn,
            ...columns.slice(firstOutputIndex),
          ]
        }
      } else {
        // For output columns, append to the end as before
        newColumns = [...columns, newColumn]
      }
    }

    try {
      await updateTableColumns(newColumns)
    } catch (error) {
      handleError({
        error,
        prefix: "Failed to add column",
      })
    }
    setAddColumnModalVisible(false)
    setEditingColumn(null)
    form.resetFields()
  }

  const hasRequiredColumns = useMemo(() => {
    const hasInput = columns.some((col) => col.type === "input")
    const hasOutput = columns.some((col) => col.type === "output")
    return hasInput && hasOutput
  }, [columns])

  const numRowsWithInputs = useMemo(() => {
    return rows.filter((row) =>
      Object.values(row.inputs).some((input) => input),
    ).length
  }, [rows])

  return (
    <>
      <Header
        title={`Agent Table: ${table?.name ?? "Loading..."}`}
        subtitle={
          loading
            ? "..."
            : (table?.description ??
              "A table for running agents on a list of items")
        }
        breadcrumbs={[
          { title: "Tables", href: "/agent-tables" },
          {
            title: table?.name ?? "Loading...",
            href: `/agent-tables/${tableOid}`,
          },
        ]}
      />
      <div className="flex min-h-0 grow flex-col p-4">
        <div className="mb-4 flex items-center gap-4">
          <Button
            onClick={() => {
              form.resetFields()
              form.setFieldsValue({ type: "input" })
              setAddColumnModalVisible(true)
            }}
            disabled={updating || loading}
            icon={<PlusIcon size={14} />}
          >
            Input Column
          </Button>
          <Button
            onClick={() => {
              form.resetFields()
              form.setFieldsValue({ type: "output" })
              setAddColumnModalVisible(true)
            }}
            disabled={updating || loading}
            icon={<PlusIcon size={14} />}
          >
            Output Column
          </Button>
          <div className="flex-grow" />
          {(updating || dbRowsSaving) && (
            <Loader className="mr-2 animate-spin text-blue-500" size={16} />
          )}
          <Tooltip title="Add a new row at the bottom of the table">
            <Button onClick={handleAddRow} icon={<ListEndIcon size={14} />} />
          </Tooltip>
          <Tooltip title="Remove all rows that have no contents">
            <Button
              onClick={handleRemoveEmptyRows}
              icon={<FoldVerticalIcon size={14} />}
            />
          </Tooltip>
          <Tooltip title="Clear all outputs for all rows">
            <Button
              onClick={handleClearOutputs}
              icon={<RotateCcw size={14} />}
            />
          </Tooltip>
          <ExportButton
            tableName={table?.name}
            dataGetter={() => tableDataToArray(columns, rows)}
            surface="WEB_AGENT_TABLE"
            id="agent-table-export"
          />
          <Tooltip
            title={
              !hasRequiredColumns
                ? "You need at least one input and one output column to run"
                : "Runs all rows, filling out all output columns using the provided prompts"
            }
          >
            <Button
              type="primary"
              onClick={handleRun}
              loading={processing}
              icon={<Play size={14} />}
              disabled={!hasRequiredColumns}
            >
              Run All
            </Button>
          </Tooltip>
        </div>

        {tableError && (
          <Alert
            message="Error loading table"
            description={tableError.message}
            type="error"
          />
        )}
        {dbRowsError && (
          <Alert
            message="Error loading table rows"
            description={dbRowsError.message}
            type="error"
          />
        )}

        <Progress
          percent={Math.round(
            Math.min((progress / numRowsWithInputs) * 100, 100),
          )}
          status={processing ? "active" : "normal"}
          className="mb-4"
        />

        <div
          onPaste={handlePaste}
          className="flex grow focus:outline-none"
          tabIndex={0}
        >
          <Table
            ref={tableContainerRef}
            size="small"
            className="max-w-full grow overflow-y-hidden"
            columns={tableColumns}
            dataSource={rows}
            loading={loading}
            pagination={false}
            bordered
            scroll={tableScrollProp}
            rowClassName="align-top editable-row"
            rowKey="oid"
          />
        </div>

        <ColumnConfigurationForm
          form={form}
          editingColumn={editingColumn}
          onFinish={handleAddColumn}
          onCancel={() => {
            setAddColumnModalVisible(false)
            setEditingColumn(null)
            form.resetFields()
          }}
          visible={addColumnModalVisible}
        />
      </div>
    </>
  )
}
