import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Empty from "antd/es/empty"
import Input from "antd/es/input"
import Tooltip from "antd/es/tooltip"
import { CheckIcon, LinkIcon, SearchIcon, TrashIcon, XIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router"
import { VList } from "virtua"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { resolveDiscussions } from "../../discussions/api"
import useComponentHeight from "../../hooks/useComponentHeight"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useFeedFilters } from "../../hooks/useQuestionnaireFeedFilters"
import useSearchParamValue from "../../hooks/useSearchParamValue"
import { removeQuestionnaireJobAnswers } from "../../pages/QuestionnaireAssistant/api"
import type {
  AnswerQuestionnaireJob,
  StoredGeneratedAnsweredQuestion,
} from "../../types/jobs"
import { getUserFacingErrorType } from "../../userFacingErrorHelpers"
import { sleep } from "../../utils"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import BulkCommentButton from "../BulkCommentButton"
import FeedFilterPopover from "./FeedFilterPopover"
import FocusModeView from "./FocusModeView"
import QuestionnaireReviewFeedCard from "./QuestionnaireReviewFeedCard"
import SortByButton from "./SortByButton"
import { getSorters } from "./sorters"
import type { AnswerWithDiscussion, QuestionnaireFeedAction } from "./types"
import { isApproved, reviewAnswer } from "./utils"
import { updateAnswer } from "./utils"

interface QuestionnaireReviewFeedProps {
  answers: AnswerWithDiscussion[]
  job: AnswerQuestionnaireJob
  discussionsError?: Error
}

const QuestionnaireReviewFeed: React.FC<QuestionnaireReviewFeedProps> = ({
  answers,
  job,
  discussionsError,
}) => {
  const {
    filterAnswers,
    filterState,
    resetFilters,
    setFilterValue,
    activeFilterCount,
  } = useFeedFilters()
  const [searchTerm, setSearchTerm] = useState("")
  const [sortByKind, setSortByKind] = useSearchParamValue<
    "priority" | "confidence"
  >("sortBy")
  const [selectedAnswerOids, setSelectedAnswerOids] = useState<Set<string>>(
    new Set(),
  )
  const { handleError, handleSuccess } = useErrorPopup()
  const [approving, setApproving] = useState(false)
  const [resolving, setResolving] = useState(false)
  const [removing, setRemoving] = useState(false)
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [updatingAnswer, setUpdatingAnswer] = useState(false)
  const [, setSearchParams] = useSearchParams()
  const [focusedOid] = useSearchParamValue("focusedOid")

  const prefilteredAnswers = useMemo(() => {
    const sorters = getSorters(authUser.uid)
    return filterAnswers(answers).sort(sorters[sortByKind ?? "default"])
  }, [answers, filterAnswers, sortByKind, authUser.uid])

  const searchStrings = useMemo(() => {
    return prefilteredAnswers.map((a) =>
      [
        a.primary_question.text.toLowerCase(),
        a.secondary_question?.text.toLowerCase() ?? "",
        a.primary_answer.text.toLowerCase(),
        a.secondary_answer?.text.toLowerCase() ?? "",
        a.question_id?.toLowerCase() ?? "",
      ].join("%%"),
    )
  }, [prefilteredAnswers])

  const filteredAnswers = useMemo(() => {
    if (searchTerm === "") return prefilteredAnswers
    const searchLower = searchTerm.toLowerCase()
    return prefilteredAnswers.filter((_, i) =>
      searchStrings[i].includes(searchLower),
    )
  }, [prefilteredAnswers, searchStrings, searchTerm])

  const selectedAnswers = useMemo(
    () => filteredAnswers.filter((a) => selectedAnswerOids.has(a.oid)),
    [filteredAnswers, selectedAnswerOids],
  )

  const assignedTo = useMemo(() => {
    if (selectedAnswers.length === 0) return undefined

    const firstAssignedTo = selectedAnswers[0]?.last_assigned_to

    return selectedAnswers.every(
      (a) => a.last_assigned_to?.uid === firstAssignedTo?.uid,
    )
      ? firstAssignedTo
      : undefined
  }, [selectedAnswers])

  const filteredAnswerIndices = useMemo(() => {
    const result = new Map<string, number>()
    for (const [i, a] of filteredAnswers.entries()) {
      result.set(a.oid, i)
    }
    return result
  }, [filteredAnswers])

  const handleSelect = useCallback(
    (answer: AnswerWithDiscussion, event: React.MouseEvent) => {
      const index = filteredAnswerIndices.get(answer.oid)
      if (index === undefined) return

      setSelectedAnswerOids((prev) => {
        const newSet = new Set(prev)
        if (event.shiftKey && prev.size > 0) {
          document.getSelection?.()?.removeAllRanges()
          const lastSelectedIndex = filteredAnswers.findIndex((a) =>
            prev.has(a.oid),
          )
          if (lastSelectedIndex !== -1) {
            const start = Math.min(lastSelectedIndex, index)
            const end = Math.max(lastSelectedIndex, index)
            for (let i = start; i <= end; i++) {
              newSet.add(filteredAnswers[i].oid)
            }
          } else {
            newSet.add(answer.oid)
          }
        } else {
          if (newSet.has(answer.oid)) {
            newSet.delete(answer.oid)
          } else {
            newSet.add(answer.oid)
          }
        }
        return newSet
      })
    },
    [filteredAnswers, filteredAnswerIndices],
  )

  const handleSelectAll = useCallback(() => {
    setSelectedAnswerOids((prev) =>
      prev.size === 0 ? new Set(filteredAnswers.map((a) => a.oid)) : new Set(),
    )
  }, [filteredAnswers])

  const handleApproveMany = useCallback(
    async (
      answers: StoredGeneratedAnsweredQuestion[],
      skipContentCheck: boolean,
    ) => {
      try {
        setApproving(true)
        await reviewAnswer("REVIEW", job.oid, answers, skipContentCheck)
      } catch (error) {
        if (getUserFacingErrorType(error) === "OUT_OF_DATE") {
          handleError({
            error,
            message: (
              <>
                One of the answers is out of date.
                <br />
                Save the update or refresh and reread to approve
              </>
            ),
            duration: 6000,
          })
        } else {
          handleError({ error, prefix: "Couldn't approve answers" })
        }
        throw error
      } finally {
        setApproving(false)
      }
    },
    [job.oid, handleError],
  )

  const handleResolveMany = useCallback(
    async (discussionOids: string[]) => {
      try {
        setResolving(true)
        await resolveDiscussions({
          group_oid: activeGroupOid,
          discussion_oids: discussionOids,
        })
      } catch (error) {
        handleError({ error, prefix: "Couldn't resolve discussions" })
        throw error
      } finally {
        setResolving(false)
      }
    },
    [handleError, activeGroupOid],
  )

  const handleApproveAll = useCallback(async () => {
    await handleApproveMany(selectedAnswers, false)
    handleSuccess("Selected answers approved successfully!")
    setSelectedAnswerOids(new Set())
  }, [selectedAnswers, handleApproveMany, handleSuccess])

  const handleUnapproveAll = useCallback(async () => {
    setApproving(true)
    try {
      await reviewAnswer("UNREVIEW", job.oid, selectedAnswers)
      handleSuccess("Selected answers unapproved successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't unapprove selected answers" })
    }
    setApproving(false)
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const handleRemoveAll = useCallback(async () => {
    setRemoving(true)
    try {
      await removeQuestionnaireJobAnswers(
        job.oid,
        selectedAnswers.map((a) => ({
          job_oid: job.oid,
          answer_oid: a.oid,
        })),
      )
      handleSuccess("Selected answers removed successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove selected answers" })
    } finally {
      setRemoving(false)
    }
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const handleUpdateAnswer = useCallback(
    async (
      answer: StoredGeneratedAnsweredQuestion,
      primaryText: string,
      secondaryText: string,
    ) => {
      setUpdatingAnswer(true)
      try {
        await updateAnswer({
          answer,
          job_oid: job.oid,
          primaryText,
          secondaryText,
        })
        handleSuccess("Answers updated successfully!")
      } catch (error) {
        handleError({ error, prefix: "Couldn't update answers" })
      } finally {
        setUpdatingAnswer(false)
      }
    },
    [job.oid, handleSuccess, handleError],
  )

  const takeAction = useCallback(
    async (action: QuestionnaireFeedAction) => {
      switch (action.type) {
        case "update-answer":
          await handleUpdateAnswer(
            action.answer,
            action.primaryAnswer,
            action.secondaryAnswer,
          )
          break
        case "goto-answer": {
          if (action.index >= filteredAnswers.length || action.index < 0) return
          const nextAnswer = filteredAnswers[action.index]
          setSearchParams((prev) => {
            prev.set("focusedOid", nextAnswer.oid)
            return prev
          })
          break
        }
        case "approve": {
          await handleApproveMany([action.answer], action.skipContentCheck)
          break
        }
        case "resolve": {
          if (action.discussionOids.length > 0) {
            await handleResolveMany(action.discussionOids)
          }
          break
        }
      }
    },
    [
      handleUpdateAnswer,
      handleApproveMany,
      filteredAnswers,
      setSearchParams,
      handleResolveMany,
    ],
  )

  const filterBySelection = useCallback(async () => {
    // TODO(mgraczyk): Remove?
    const answerOids = Array.from(selectedAnswerOids)
    setFilterValue("oid", answerOids)
    // Wait for the filter to be applied.
    await sleep(1)
    await navigator.clipboard.writeText(window.location.href)
    handleSuccess("Copied shareable link to clipboard!")
  }, [selectedAnswerOids, handleSuccess, setFilterValue])

  const [listRef, listHeight] = useComponentHeight()

  const isJobOwner = job.creator.uid === authUser.uid
  const isOwnerOfSelected =
    isJobOwner ||
    selectedAnswers.every(
      (answer) => answer.last_assigned_to?.uid === authUser.uid,
    )
  const numApprovedAnswers = filteredAnswers.filter(isApproved).length
  const nothingSelected = selectedAnswerOids.size === 0
  const numSelectedApproved = selectedAnswers.filter(isApproved).length
  const allSelectedNotApproved = numSelectedApproved === 0
  const allSelectedApproved = numSelectedApproved === selectedAnswers.length
  const canRemoveApproval =
    isJobOwner ||
    selectedAnswers.every(
      (answer) =>
        !answer.last_reviewed_by ||
        answer.last_assigned_to?.uid === authUser.uid ||
        answer.last_reviewed_by.uid === authUser.uid,
    )
  const changing = approving || resolving || removing

  const focusedIndex = focusedOid
    ? filteredAnswerIndices.get(focusedOid)
    : undefined
  const focusedAnswer =
    focusedIndex != null ? filteredAnswers[focusedIndex] : null
  const isOwnerOfFocused =
    isJobOwner || focusedAnswer?.last_assigned_to?.uid === authUser.uid

  return (
    <div className="flex w-full flex-col">
      <div className="border-gray-25 w-full border-b bg-white pb-4">
        <div className="my-4 mr-6 flex items-center gap-2">
          <Input
            placeholder="Search questions and answers"
            prefix={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="grow"
          />
          <FeedFilterPopover
            answers={answers}
            setFilterValue={setFilterValue}
            filterState={filterState}
            resetFilters={resetFilters}
            activeFilterCount={activeFilterCount}
          />
          <SortByButton sortByKind={sortByKind} setSortByKind={setSortByKind} />
        </div>
        <div className="flex items-center">
          <div className="flex grow flex-wrap items-center gap-x-2 gap-y-2">
            <Checkbox
              checked={
                filteredAnswers.length > 0 &&
                selectedAnswerOids.size === filteredAnswers.length
              }
              indeterminate={
                selectedAnswerOids.size > 0 &&
                selectedAnswerOids.size < filteredAnswers.length
              }
              onChange={handleSelectAll}
            >
              Select All
            </Checkbox>
            <span className="ml-2 mr-2 min-w-[6rem] max-w-[6rem] text-gray-600">
              {selectedAnswerOids.size} selected
            </span>

            <Tooltip
              title={
                nothingSelected
                  ? "Select items to approve"
                  : allSelectedApproved
                    ? "All selected answers are already approved"
                    : "Approve selected answers"
              }
            >
              <Button
                size="small"
                type="primary"
                icon={<CheckIcon />}
                onClick={handleApproveAll}
                disabled={
                  changing ||
                  updatingAnswer ||
                  nothingSelected ||
                  allSelectedApproved
                }
              >
                Approve
              </Button>
            </Tooltip>
            <Tooltip
              title={
                nothingSelected
                  ? "Select items to remove approval"
                  : allSelectedNotApproved
                    ? "None of the selected answers are approved"
                    : !canRemoveApproval
                      ? "You do not have permission to remove these approvals"
                      : "Remove approval from selected answers"
              }
            >
              <Button
                size="small"
                icon={<XIcon />}
                onClick={handleUnapproveAll}
                disabled={
                  changing ||
                  updatingAnswer ||
                  nothingSelected ||
                  allSelectedNotApproved ||
                  !canRemoveApproval
                }
              >
                Unapprove
              </Button>
            </Tooltip>
            <BulkCommentButton
              small
              jobOid={job.oid}
              answerOids={Array.from(selectedAnswerOids)}
            />
            <AssignQuestionnaireAnswerButton
              jobOid={job.oid}
              answerOids={Array.from(selectedAnswerOids)}
              assignedTo={assignedTo}
              disabled={!isOwnerOfSelected || changing || updatingAnswer}
              small
            />
            <Tooltip
              title={
                isOwnerOfSelected
                  ? "Remove selected answers"
                  : "You are not the owner of these responses."
              }
            >
              <Button
                size="small"
                danger
                icon={<TrashIcon />}
                onClick={handleRemoveAll}
                disabled={
                  changing ||
                  updatingAnswer ||
                  nothingSelected ||
                  !isOwnerOfSelected
                }
              />
            </Tooltip>
            <Button
              size="small"
              icon={<LinkIcon />}
              onClick={filterBySelection}
              disabled={changing || nothingSelected}
            >
              Link
            </Button>
          </div>

          <div className="ml-auto mr-4 flex items-center">
            <span className="mr-2 font-semibold text-gray-700">
              {numApprovedAnswers} / {filteredAnswers.length} approved
            </span>
          </div>
        </div>
      </div>
      <div ref={listRef} className="grow overflow-y-hidden">
        {filteredAnswers.length === 0 && (
          <Empty
            className="m-auto flex h-96 flex-col items-center justify-center rounded-md border"
            description={
              activeFilterCount > 0
                ? "No answers match the selected filters"
                : "No answers available"
            }
          />
        )}
        <VList style={{ height: listHeight }}>
          {filteredAnswers.map((answer: AnswerWithDiscussion) => (
            <QuestionnaireReviewFeedCard
              key={answer.oid}
              answer={answer}
              job={job}
              updatingAnswer={updatingAnswer}
              takeAction={takeAction}
              discussionsError={discussionsError}
              focused={filterState.oid.includes(answer.oid)}
              isSelected={selectedAnswerOids.has(answer.oid)}
              onSelect={handleSelect}
              loading={changing && selectedAnswerOids.has(answer.oid)}
            />
          ))}
        </VList>
      </div>
      {focusedAnswer && (
        <FocusModeView
          jobOid={job.oid}
          answerObj={focusedAnswer}
          updatingAnswer={updatingAnswer}
          takeAction={takeAction}
          loading={changing}
          answerIndex={focusedIndex ?? 0}
          totalAnswers={filteredAnswers.length}
          isApprover={isOwnerOfFocused}
        />
      )}
    </div>
  )
}

export default QuestionnaireReviewFeed
