import { LoaderCircleIcon } from "lucide-react"
import { Suspense, lazy } from "react"

const getLazy = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  importFunc: () => Promise<{ default: React.ComponentType<any> }>,
) => {
  const LazyComponent = lazy(importFunc)
  return function WrappedLazyComponent() {
    return (
      <Suspense
        fallback={
          <LoaderCircleIcon
            height={64}
            className="text-primary mt-32 w-full animate-spin"
          />
        }
      >
        <LazyComponent />
      </Suspense>
    )
  }
}

export default getLazy
