import { Button, Result } from "antd"

import url500Image from "../assets/img/quilt_500_sticker.jpg"
import Header from "./Header"

interface Props {
  noHeader?: boolean
}

const Error500Page: React.FC<Props> = ({ noHeader = false }) => {
  // Refresh instead of using react-router to clear any errors.
  const body = (
    <Result
      icon={
        <img
          className="m-auto h-[256px] w-[256px]"
          src={url500Image}
          alt="Internal Server Error - 500"
          width={1024}
          height={1024}
        />
      }
      subTitle={
        <>
          Sorry, something went wrong.
          <br />
          Please refresh the page or contact support at support@quilt.app
        </>
      }
      extra={
        <a href="/">
          <Button type="primary">Go Home</Button>
        </a>
      }
    />
  )

  if (noHeader) {
    return body
  }

  return (
    <>
      <Header title="Something went wrong"></Header>
      {body}
    </>
  )
}

export default Error500Page
