import Modal from "antd/es/modal"

import type { Source } from "../../sources/types"
import { FilePickerContent } from "./FilePickerContent"

interface FilePickerProps {
  isModal?: boolean
  onCancel?: () => void
  source?: Source
  open: boolean
}

const FilePicker: React.FC<FilePickerProps> = ({
  isModal,
  onCancel,
  source,
  open,
}) => {
  if (!open) return null

  if (isModal) {
    return (
      <Modal
        open={true}
        footer={false}
        centered
        width={800}
        closeIcon
        onClose={onCancel}
        maskClosable
        onCancel={onCancel}
        className="rounded-lg"
      >
        <div className="flex h-[600px] w-full flex-col sm:max-h-full">
          <FilePickerContent source={source} onCancel={onCancel} />
        </div>
      </Modal>
    )
  }

  return (
    <div className="w-max-full flex h-[600px] w-[800px] flex-col sm:max-h-full">
      <FilePickerContent source={source} onCancel={onCancel} />
    </div>
  )
}

export default FilePicker
