import type { FirebaseApp, FirebaseOptions } from "firebase/app"
import {
  type PopupRedirectResolver,
  browserLocalPersistence,
  browserSessionPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth"
import type { Auth } from "firebase/auth"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import type { Firestore } from "firebase/firestore"
import type { FirebaseStorage } from "firebase/storage"
import { connectStorageEmulator, getStorage } from "firebase/storage"

export function getFirebaseConfig(): FirebaseOptions {
  // Load config from environment.

  // If we're using the local frontend, also use it for auth.
  // TODO(mgraczyk): Fix this, the page doesn't load.
  //const authDomain = import.meta.env.VITE_USE_LOCAL_FRONTEND
  //? new URL("http://localhost:5173").host
  //: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN

  const config = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  }
  Object.entries(config).forEach(([key, value]) => {
    if (value === undefined) {
      throw new Error(`Missing firebase config value: ${key}`)
    }
  })
  return config
}

export function getFirebaseFirestore(app: FirebaseApp): Firestore {
  const db = getFirestore(app)
  if (
    import.meta.env.VITE_USE_FIREBASE_EMULATORS ||
    import.meta.env.VITE_USE_FIRESTORE_EMULATOR
  ) {
    connectFirestoreEmulator(db, "127.0.0.1", 8079)
  }
  return db
}

/*
 * TODO(mgraczyk): Maybe enable caching.
function getFirestoreSettings(): FirestoreSettings {
  return {
    localCache: memoryLocalCache({
      garbageCollector: memoryLruGarbageCollector(),
    }),
  }
}
*/
export function getFirebaseAuth(
  app: FirebaseApp,
  popupRedirectResolver?: PopupRedirectResolver,
): Auth {
  // Disable popup in node (testing).
  try {
    const isNode = window?.process
    if (isNode) {
      // Disable popup in node (testing).
      popupRedirectResolver = undefined
    }
  } catch (error) {
    // Ignore.
    void error
  }

  const auth = initializeAuth(app, {
    persistence: [
      indexedDBLocalPersistence,
      browserLocalPersistence,
      browserSessionPersistence,
    ],
    popupRedirectResolver,
  })
  if (
    import.meta.env.VITE_USE_FIREBASE_EMULATORS ||
    import.meta.env.VITE_USE_AUTH_EMULATOR
  ) {
    connectAuthEmulator(auth, "http://127.0.0.1:9099", {
      disableWarnings: import.meta.env.MODE === "test",
    })
  }
  return auth
}

export function getFirebaseStorage(app: FirebaseApp): FirebaseStorage {
  const storage = getStorage(app)
  if (
    import.meta.env.VITE_USE_FIREBASE_EMULATORS ||
    import.meta.env.VITE_USE_STORAGE_EMULATOR
  ) {
    connectStorageEmulator(storage, "127.0.0.1", 9199)
  }
  return storage
}
