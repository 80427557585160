import type { DocumentReference } from "../documents/types.js"
import type { AnswerReference } from "../types/answerer.js"
import type { BoundingBox } from "../types/userDocument.js"

// NOTE: Do not import because importing utils.ts on the frontend breaks the
// build.
const shorten = (text: string, maxLength: number): string => {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength - 3) + "..."
  }
  return text
}

export const getSourceDocumentUrl = (
  sourceOid: string,
  reference?: DocumentReference | null,
) => {
  const baseUrl = `/source-documents/${sourceOid}`
  if (reference) {
    const referenceJson = JSON.stringify(reference)
    const params = new URLSearchParams({ ref: referenceJson })
    return baseUrl + "?" + params.toString()
  } else {
    return baseUrl
  }
}

const shortenBoundingBoxLocation = (boxes: BoundingBox[]): BoundingBox[] => {
  // TODO(mgraczyk): Do this on the backend.
  // HACK: Shorten the URL by taking the first two bounding boxes.
  // Long URLs break everything,
  if (boxes.length === 0) {
    return []
  }
  const topLeft = boxes[0].top_left
  if (!topLeft || !boxes[0].height || !boxes[0].width) {
    return []
  }

  const pageNumber = boxes[0].page_number
  let bottom = topLeft.y + boxes[0].height
  let right = topLeft.x + boxes[0].width
  for (const box of boxes) {
    if (box.page_number !== pageNumber) {
      break
    }
    if (box.top_left?.y && box.top_left.y + (box.height ?? 0) > bottom) {
      bottom = box.top_left.y + (box.height ?? 0)
    }
    if (box.top_left?.x && box.top_left.x + (box.width ?? 0) > right) {
      right = box.top_left.x + (box.width ?? 0)
    }
  }
  return [
    {
      page_number: pageNumber,
      top_left: boxes[0].top_left,
      height: bottom - topLeft.y,
      width: right - topLeft.x,
    },
  ]
}

export const getSourceDocumentUrlFromReference = (
  answerReference: AnswerReference,
  maxContentLength: number = 200,
  disableReference: boolean = false,
) => {
  if (answerReference.kind === "KNOWLEDGE_ITEM") {
    return `/knowledge-items/${answerReference.document_oid}`
  }

  if (!answerReference.document_oid) {
    return "/source-documents"
  }

  if (disableReference) {
    return getSourceDocumentUrl(answerReference.document_oid)
  }

  const content =
    maxContentLength > 0
      ? shorten(answerReference.content, maxContentLength)
      : ""

  // TODO(mgraczyk): Fix types, splitting by location_kind shouldn't be required here.
  const reference: DocumentReference =
    answerReference.location_kind === "BOUNDING_BOX"
      ? {
          content,
          locationKind: answerReference.location_kind,
          location: shortenBoundingBoxLocation(answerReference.location),
        }
      : answerReference.location_kind === "SHEET_RANGE"
        ? {
            content,
            locationKind: answerReference.location_kind,
            location: answerReference.location,
          }
        : {
            content,
          }
  return getSourceDocumentUrl(answerReference.document_oid, reference)
}
