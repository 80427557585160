import { Button, Dropdown, Popconfirm } from "antd"
import type { MenuProps } from "antd"
import { Download } from "lucide-react"
import { useState } from "react"

import { getDownloadMenuItems } from "../../pages/QuestionnaireAssistant/DownloadMenu"
import type { DownloadFormat } from "../../pages/QuestionnaireAssistant/api"
import type { MimeType } from "../../types/mimetype"

interface Props {
  allAnswersApproved: boolean
  onDownload: (format: DownloadFormat) => void
  disabled?: boolean
  loading: boolean
  downloading?: boolean
  getUnapprovedCount: () => number
  questionnaireMimeType: MimeType
}

const DownloadButton: React.FC<{
  disabled?: boolean
  loading: boolean
  downloading?: boolean
  onClick?: () => void
}> = ({ disabled, loading, downloading, onClick }) => (
  <Button
    className="text-primary flex h-9 items-center justify-center rounded-md bg-purple-50 px-4 font-bold"
    title="Download"
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    icon={<Download size={16} className="mr-1" />}
  >
    {downloading ? "Downloading..." : "Download"}
  </Button>
)

const DownloadQuestionnaireButton: React.FC<Props> = ({
  allAnswersApproved,
  disabled,
  loading,
  onDownload,
  getUnapprovedCount,
  downloading,
  questionnaireMimeType,
}) => {
  const [selectedOption, setSelectedOption] =
    useState<DownloadFormat>("original")
  const [popconfirmVisible, setPopconfirmVisible] = useState(false)

  const handleMenuClick = ({ key }: { key: string }) => {
    let option: DownloadFormat
    if (
      key === "original" ||
      key === "csv" ||
      key === "docx" ||
      key === "xlsx"
    ) {
      option = key
    } else {
      console.error("Invalid option: ", key)
      option = "original"
    }

    if (allAnswersApproved) {
      onDownload(option)
    } else {
      setSelectedOption(option)
      setPopconfirmVisible(true)
    }
  }

  const handlePopconfirmConfirm = () => {
    onDownload(selectedOption)
    setPopconfirmVisible(false)
  }

  const handlePopconfirmCancel = () => {
    setPopconfirmVisible(false)
  }

  const menuProps: MenuProps = {
    items: getDownloadMenuItems(
      questionnaireMimeType,
      handleMenuClick,
      downloading,
    ),
    onClick: handleMenuClick,
  }

  return (
    <>
      <Dropdown
        menu={menuProps}
        disabled={disabled || loading}
        trigger={["click"]}
      >
        <DownloadButton
          disabled={disabled}
          loading={loading}
          downloading={downloading}
        />
      </Dropdown>

      <Popconfirm
        title="Answers Not Yet Approved"
        description={
          <>
            {getUnapprovedCount()} answers have not been approved by their
            owner.
            <br />
            Are you sure you want to continue downloading?
          </>
        }
        open={popconfirmVisible}
        onConfirm={handlePopconfirmConfirm}
        onCancel={handlePopconfirmCancel}
        okText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default DownloadQuestionnaireButton
