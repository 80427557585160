import type { TabsProps } from "antd"
import Button from "antd/es/button"
import Drawer from "antd/es/drawer"
import Popconfirm from "antd/es/popconfirm"
import Skeleton from "antd/es/skeleton"
import Tabs from "antd/es/tabs"
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { ListIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router"
import { Link, useSearchParams } from "react-router"

import Discussions from "../../components/Discussions/Discussions"
import NotFoundPage from "../../components/NotFoundPage"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type KnowledgeDiscussion,
} from "../../discussions/types"
import type { DocumentReference } from "../../documents/types"
import { db } from "../../firebaseApp"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { useGroupTags } from "../../hooks/useGroupTags"
import KnowledgeItemHistoryCard from "../../knowledge/KnowledgeItemHistoryCard"
import { deleteUserDocumentsApi } from "../../knowledge/api"
import { knowledgeItemHistoryConverter } from "../../knowledge/db"
import {
  GROUPS_COLLECTION,
  USER_DOCUMENTS_COLLECTION,
  USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
} from "../../types/common"
import type { UserDocument } from "../../types/userDocument"
import { sleep } from "../../utils"
import { shorten } from "../../utils"
import Header from "./../../components/Header"
import SourceDocumentViewer from "./SourceDocumentViewer"
import UserDocumentForm from "./UserDocumentForm"

const LoadingComponent: React.FC<{
  children?: React.JSX.Element
  loading: boolean
}> = ({ children, loading }) => (
  <Skeleton
    active
    className="mt-4 w-36"
    title={false}
    paragraph={{ className: "m-0", rows: 1 }}
    loading={loading}
  >
    {children}
  </Skeleton>
)

const ReferenceTextSnippet: React.FC<{
  reference: DocumentReference | undefined | null
}> = ({ reference }) => {
  if (!reference) {
    return null
  }

  return (
    <>
      <h3 className="font-bold text-gray-800">Referenced Text</h3>
      <p className="bg-gray-25 rounded-xl p-2 italic text-gray-800">
        {shorten(reference.content, 400)}
      </p>
    </>
  )
}

const DiscussionsTab: React.FC<{
  activeGroupOid: string
  docOid: string
}> = ({ activeGroupOid, docOid }) => {
  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<KnowledgeDiscussion>(
      query(
        collection(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          DISCUSSION_COLLECTION,
        ).withConverter(makeConverter<KnowledgeDiscussion>()),
        where("user_document_oid", "==", docOid),
        where("deleted", "==", false),
        orderBy("created_at", "desc"),
        limit(100),
      ),
    )

  return (
    <LoadingComponent loading={discussionsLoading}>
      <Discussions
        group_oid={activeGroupOid}
        discussions={discussions}
        user_document_oid={docOid}
        kind="USER_DOCUMENT"
        discussionsError={discussionsError}
      />
    </LoadingComponent>
  )
}

const HistoryTab: React.FC<{
  activeGroupOid: string
  docOid: string
}> = ({ activeGroupOid, docOid }) => {
  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)

  const [history, historyLoading, historyError] = useCollectionData(
    query(
      collection(
        db,
        USER_DOCUMENTS_COLLECTION,
        docOid,
        USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
      ).withConverter(knowledgeItemHistoryConverter),
      where("group_oid", "==", activeGroupOid),
      orderBy("updated_at", "desc"),
    ),
  )
  return (
    <LoadingComponent loading={historyLoading}>
      <div className="border-t border-gray-100">
        {historyError && <div>Error loading history</div>}
        {(!history || history.length === 0) && <div>No history yet</div>}
        {history?.map((h, index) => (
          <KnowledgeItemHistoryCard
            key={index}
            item={h}
            groupMembers={groupMembers}
            tags={tags ?? []}
            tagsLoading={tagsLoading}
            groupMembersLoading={groupMembersLoading}
          />
        ))}
      </div>
    </LoadingComponent>
  )
}

const UserDocumentPage: React.FC = () => {
  const { docOid = "" } = useParams<{ docOid: string }>()
  const docRef = doc(db, USER_DOCUMENTS_COLLECTION, docOid).withConverter(
    makeConverter<UserDocument>(),
  )
  const [searchParams] = useSearchParams()
  const discussionOid = searchParams.get("discussion_oid")
  const [sidebarOpen, setSidebarOpen] = useState(!!discussionOid)
  const [userDoc, loading, error] = useDocumentData(docRef)
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [removing, setRemoving] = useState<boolean>(false)
  const [removed, setRemoved] = useState<boolean>(false)
  const navigate = useNavigate()

  const referenceJson = searchParams.get("ref")
  const reference = useMemo(() => {
    if (!referenceJson) return null
    try {
      return JSON.parse(referenceJson) as DocumentReference
    } catch (error) {
      console.error(error)
      return null
    }
  }, [referenceJson])

  const removeUserDocument = useCallback(async () => {
    setRemoving(true)
    try {
      await deleteUserDocumentsApi({ oids: [docOid] })
      setRemoved(true)
      await sleep(3000)
      void navigate("/source-documents")
    } finally {
      setRemoving(false)
    }
  }, [docOid, navigate])

  // TODO(mgraczyk): Combine drawer logic with KnowledgeItemPage and KnowledgeItemForm.
  // TODO(mgraczyk): Use URL path to control sidebar and tab.
  const drawerTabs: TabsProps["items"] = [
    {
      key: "discussions",
      label: "Discussions",
      children: (
        <DiscussionsTab activeGroupOid={activeGroupOid} docOid={docOid} />
      ),
    },
    {
      key: "history",
      label: "History",
      children: <HistoryTab activeGroupOid={activeGroupOid} docOid={docOid} />,
    },
  ]

  if (!docOid) {
    return <NotFoundPage />
  }

  let body: React.ReactNode
  if (removed) {
    body = (
      <div>
        <div className="m-4">Document marked for removal</div>
        <div>
          Redirecting to{" "}
          <Link to="/source-documents">your Source Documents</Link> in a few
          seconds...
        </div>
      </div>
    )
  } else if (removing) {
    body = (
      <div>
        <div className="m-4">Removing document...</div>
      </div>
    )
  } else if (loading) {
    body = <Skeleton active loading className="p-6" />
  } else if (error) {
    if (error.code === "permission-denied") {
      return <NotFoundPage />
    }
    return (
      <span className="m-4">
        Failed to load document: {JSON.stringify(error)}
      </span>
    )
  } else if (!userDoc) {
    return <NotFoundPage />
  } else {
    body = (
      <div className="flex flex-grow flex-row gap-8 overflow-x-hidden p-6">
        <div className="w-72">
          <ReferenceTextSnippet reference={reference} />
          <UserDocumentForm doc={userDoc} />
        </div>
        <div className="bg-gray-25 flex flex-auto grow flex-col overflow-x-hidden rounded-lg">
          <SourceDocumentViewer userDocument={userDoc} reference={reference} />
        </div>
        <Drawer
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          title="Options"
        >
          <Tabs
            rootClassName="quilt-questionnaire-sidebar"
            items={drawerTabs}
          />
        </Drawer>
      </div>
    )
  }

  const titleText = loading
    ? "Loading..."
    : userDoc?.title
      ? shorten(userDoc.title, 50)
      : "Source Document Details"

  return (
    <>
      <Header
        title={titleText}
        breadcrumbs={[
          {
            title: "Source Documents",
            href: "/source-documents",
          },
          {
            title: `Document ${docOid}`,
            href: `/source-documents/${docOid}`,
          },
        ]}
      >
        <Popconfirm
          title="Are you sure?"
          description="This will permanently remove the document from Quilt."
          placement="bottomLeft"
          onConfirm={removeUserDocument}
          okText="Yes"
          cancelText="No"
        >
          <Button className="font-bold" danger>
            {removing ? "Removing" : "Remove"}
          </Button>
        </Popconfirm>
        <Button
          icon={<ListIcon />}
          className="ml-4"
          onClick={() => setSidebarOpen(true)}
        />
      </Header>
      {body}
    </>
  )
}

export default UserDocumentPage
