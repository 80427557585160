import { Button, Card, Skeleton } from "antd"

import type { GroupMembership } from "../groups/types"
import QuiltTag from "../tags/Tag"
import type { GroupTag } from "../tags/types"
import { toHumanReadableString } from "../utils"
import { HistoryLastUpdatedBy } from "./LastUpdatedBy"
import type { KnowledgeItemHistory } from "./types"

const KnowledgeItemHistoryCard: React.FC<{
  item: KnowledgeItemHistory
  groupMembers: GroupMembership[] | undefined
  tags: GroupTag[]
  tagsLoading: boolean
  groupMembersLoading: boolean
  onReuse?: (item: KnowledgeItemHistory) => void
}> = ({
  item,
  tags,
  groupMembers,
  groupMembersLoading,
  tagsLoading,
  onReuse,
}) => {
  let cardContent: React.ReactNode
  let cardExtra: React.ReactNode

  if (item.kind === "REVIEW") {
    cardContent = <div>Marked as reviewed</div>
  } else if (item.kind === "UNREVIEW") {
    cardContent = <div>Marked as unreviewed</div>
  } else if (item.kind === "ASSIGN") {
    cardContent = (
      <div>Assigned to {item.assign_to.email || "Unknown User"}</div>
    )
  } else if (item.kind === "UNASSIGN") {
    cardContent = <div>Removed assignee</div>
  } else if (
    item.kind === "EDIT" ||
    !item.kind ||
    item.kind === "EDIT_WITHOUT_CONTENT"
  ) {
    cardContent = (
      <>
        {(item.kind == "EDIT" || !item.kind) && (
          <>
            {item.question ? <p>Q: {item.question.primary}</p> : null}
            {item.answer ? <p>A: {item.answer.primary}</p> : null}
          </>
        )}
        <Skeleton active loading={tagsLoading}>
          <div className="flex flex-wrap">
            {Object.keys(item.tags ?? {}).map((oid) => {
              const tagObj = tags.find((t) => t.oid === oid)
              return tagObj ? (
                <QuiltTag key={oid} tag={tagObj} className="mb-1" />
              ) : null
            })}
          </div>
        </Skeleton>
      </>
    )
    cardExtra =
      item.kind == "EDIT" || !item.kind ? (
        <Button type="text" onClick={() => onReuse?.(item)}>
          Reuse
        </Button>
      ) : null
  }

  return (
    <Card
      size="small"
      className="my-4"
      title={
        <div className="py-2">
          <div>{toHumanReadableString(item.updated_at)}</div>
          <div className="font-normal">
            By{" "}
            <HistoryLastUpdatedBy
              item={item}
              groupMembers={groupMembers}
              groupMembersLoading={groupMembersLoading}
            />
          </div>
        </div>
      }
      extra={cardExtra}
    >
      {cardContent}
    </Card>
  )
}

export default KnowledgeItemHistoryCard
