import type { AgentTableRow, ColumnConfig } from "./types"

/**
 * Converts table data to a 2D array format suitable for export
 */
export const tableDataToArray = (
  columns: ColumnConfig[],
  rows: AgentTableRow[],
  includeHeaders = true,
): string[][] => {
  // Create header row
  const headers = columns.map((col) => col.title)

  // Create data rows
  const dataRows = rows.map((row) => {
    return columns.map((col) => {
      if (col.type === "input") {
        return row.inputs[col.id] || ""
      } else {
        return row.outputs[col.id]?.value || ""
      }
    })
  })

  return includeHeaders ? [headers, ...dataRows] : dataRows
}
