import LoadingSpinner from "./LoadingSpinner"
import QuiltLogo from "./QuiltLogo"

const FullPageLoader: React.FC = () => {
  return (
    <div className="flex w-full grow flex-col items-center">
      <QuiltLogo className="mt-64" width="16rem" />
      <LoadingSpinner className="!h-20 w-full" />
    </div>
  )
}

export default FullPageLoader
