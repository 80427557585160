import { Checkbox } from "antd"

import { VOID_FUNCTION } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import ChatReferenceList from "../ChatReferenceList"
import Discussions from "../Discussions/Discussions"
import FeedFooterBadge from "./FeedFooterBadge"
import QuestionAnswerBlock from "./QuestionAnswerBlock"
import type { AnswerWithDiscussion, QuestionnaireFeedAction } from "./types"

interface QuestionnaireReviewFeedCardProps {
  answer: AnswerWithDiscussion
  isSelected: boolean
  loading: boolean
  onSelect: (answer: AnswerWithDiscussion, event: React.MouseEvent) => void
  job: AnswerQuestionnaireJob
  updatingAnswer: boolean
  takeAction: (action: QuestionnaireFeedAction) => Promise<void>
  discussionsError?: Error
  focused?: boolean
}

const QuestionnaireReviewFeedCard: React.FC<
  QuestionnaireReviewFeedCardProps
> = ({
  answer,
  job,
  discussionsError,
  isSelected,
  onSelect,
  focused,
  loading,
  updatingAnswer,
  takeAction,
}) => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const handleCardClick = (event: React.MouseEvent) => {
    onSelect(answer, event)
  }

  return (
    <div
      className={`border-gray-25 flex items-start rounded-md border-b border-t py-4 pl-2 pr-4 transition-all duration-200 ${focused ? "bg-purple-50" : ""}`}
    >
      <div
        className={`mr-3 flex w-full flex-1 rounded-lg border-2 bg-white shadow-sm transition-all duration-300 ${isSelected ? "border-primary" : "border-gray-200"}`}
      >
        <div
          onClick={handleCardClick}
          className={`flex w-16 min-w-[4rem] max-w-[4rem] cursor-pointer items-center justify-center overflow-hidden rounded-bl-lg rounded-tl-lg ${isSelected ? "bg-purple-100" : "bg-gray-25 hover:bg-gray-50"}`}
        >
          <Checkbox style={{ transform: "scale(1.2)" }} checked={isSelected} />
        </div>
        <div className="flex w-full flex-col border-l border-gray-100">
          <div className="grow p-3">
            <QuestionAnswerBlock
              jobOid={job.oid}
              answerObj={answer}
              updatingAnswer={updatingAnswer}
              takeAction={takeAction}
              loading={loading}
            />
            <ChatReferenceList
              references={answer.references}
              className="mt-4"
            />
          </div>
          {answer.last_assigned_to ? (
            <div className="bg-gray-25 mt-2 flex items-center p-3 text-xs text-gray-500">
              <FeedFooterBadge answer={answer} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-[30%] min-w-[20rem] max-w-[30rem]">
        <Discussions
          kind="SHEET"
          group_oid={activeGroupOid}
          discussions={answer.discussions}
          job_id={job.oid}
          location={answer.primary_answer.location}
          response_oid={answer.oid}
          discussionsError={discussionsError}
          onClickLocation={VOID_FUNCTION}
          useFeedCTA
          hideTitle
        />
      </div>
    </div>
  )
}

export default QuestionnaireReviewFeedCard
