import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router"

import { useActiveUserAuthorizationFromContext } from "../../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type SheetDiscussion,
} from "../../../discussions/types"
import { db } from "../../../firebaseApp"
import useErrorPopup from "../../../hooks/useErrorPopup"
import {
  type DownloadFormat,
  downloadCompleteAnswerQuestionnaireJob,
  getAnswerQuestionnaireJobBaseDownloadUrl,
  reviewAnswerQuestionnaireJob,
} from "../../../pages/QuestionnaireAssistant/api"
import { GROUPS_COLLECTION } from "../../../types/common"
import type { StoredGeneratedAnsweredQuestion } from "../../../types/jobs"
import type {
  AnswerQuestionnaireCellEdit,
  AnswerQuestionnaireJob,
} from "../../../types/jobs"
import {
  ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_EDITS_SUBCOLLECTION,
} from "../../../types/jobs"

interface UseQuestionnaireResult {
  jobData: AnswerQuestionnaireJob | undefined
  answerDocs: StoredGeneratedAnsweredQuestion[] | undefined
  cellEdits: AnswerQuestionnaireCellEdit[] | undefined
  discussions: SheetDiscussion[] | undefined
  discussionsError: Error | undefined
  baseFileUrl: string
  loading: boolean
  error: Error | undefined
  downloading: boolean
  modifying: boolean
  onDownload: (format: DownloadFormat) => Promise<void>
  markComplete: () => Promise<void>
}

export const useQuestionnaire = (oid: string): UseQuestionnaireResult => {
  const [baseFileUrl, setBaseFileUrl] = useState<string>()
  const [baseFileError, setBaseFileError] = useState<Error>()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [modifying, setModifying] = useState(false)
  const navigate = useNavigate()

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const { handleError } = useErrorPopup()

  // Fetch job data
  const [jobData, jobLoading, jobError] = useDocumentData(
    doc(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
      oid,
    ).withConverter(makeConverter<AnswerQuestionnaireJob>()),
  )

  // Fetch answers
  const [answerDocs, answersLoading, answersError] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
      oid,
      ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
    ).withConverter(makeConverter<StoredGeneratedAnsweredQuestion>()),
  )

  // Fetch cell edits
  const [cellEdits, cellEditsLoading, cellEditsError] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
      oid,
      ANSWER_QUESTIONNAIRE_JOBS_EDITS_SUBCOLLECTION,
    ).withConverter(makeConverter<AnswerQuestionnaireCellEdit>()),
  )

  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<SheetDiscussion>(
      query(
        collection(
          db,
          GROUPS_COLLECTION,
          activeGroupOid,
          DISCUSSION_COLLECTION,
        ).withConverter(makeConverter<SheetDiscussion>()),
        where("job_id", "==", oid),
        where("deleted", "==", false),
        orderBy("created_at", "asc"),
        limit(500),
      ),
    )

  // Fetch base file URL
  useEffect(() => {
    if (!oid) {
      return
    }
    const fetchUrl = async () => {
      try {
        const baseFileUrl = await getAnswerQuestionnaireJobBaseDownloadUrl(oid)
        setBaseFileUrl(baseFileUrl)
      } catch (error) {
        console.error(error)
        setBaseFileError(error as Error)
      }
    }
    void fetchUrl()
  }, [oid])

  // Download questionnaire
  const onDownload = useCallback(
    async (format: DownloadFormat) => {
      setDownloading(true)
      try {
        await downloadCompleteAnswerQuestionnaireJob(oid, format)
      } catch (error) {
        handleError({
          prefix: "Couldn't download answer questionnaire job",
          error,
        })
      } finally {
        setDownloading(false)
      }
    },
    [oid, handleError],
  )

  // Mark questionnaire as complete
  const markComplete = useCallback(async () => {
    if (!oid) {
      return
    }
    setModifying(true)
    try {
      await reviewAnswerQuestionnaireJob(oid)
      void navigate("/questionnaire-assistant")
    } catch (error) {
      console.error("Error reviewing job:", error)
      handleError({ error })
      setModifying(false)
    }
  }, [oid, handleError, navigate])

  // Combine loading states
  const loading =
    jobLoading ||
    answersLoading ||
    cellEditsLoading ||
    discussionsLoading ||
    (!baseFileUrl && !baseFileError)

  // Combine error states
  const error = jobError ?? answersError ?? cellEditsError ?? baseFileError

  return {
    jobData,
    answerDocs: answerDocs,
    cellEdits: cellEdits,
    discussions: discussions,
    baseFileUrl: baseFileUrl ?? "",
    discussionsError: discussionsError,
    loading,
    error,
    downloading,
    modifying,
    onDownload,
    markComplete,
  }
}

export default useQuestionnaire
