import { pyBackendAxiosInstance } from "../../api"
import type { GroupSettings } from "../../groups/types"
import type { AnswerMetadata } from "../../types/answerer"

// From export_usage_data.py and SQL queries.
export interface ReportingData {
  start_date: string
  created_at: string
  user_weekly_usage: {
    email: string
    week: string
    freq_ask_question: number
    freq_submit_questionnaire: number
    freq_extension_questionnaire: number
  }[]
  active_users: {
    email: string
    n_questions_30_days: number
    n_questions_7_days: number
    L30: number
    L7: number
  }[]
  answered_questions: {
    week: string
    total_questions: number
    answered_questions: number
  }[]
}

export const getReportingData = async (groupOid: string) => {
  const response = await pyBackendAxiosInstance.get<{
    reporting_data: ReportingData
  }>(`/groups/${groupOid}/reporting/data`)
  return response.data.reporting_data
}

export interface UpdateAdvancedSettingsRequest {
  settings?: Pick<GroupSettings, "import_questionnaire_on_complete">
  answer_metadata?: Pick<
    AnswerMetadata,
    | "company_name"
    | "global_custom_prompt"
    | "search_tools"
    | "default_search_tool"
  >
}

export const updateAdvancedSettings = async (
  groupOid: string,
  data: UpdateAdvancedSettingsRequest,
) => {
  const response = await pyBackendAxiosInstance.post<{ success: boolean }>(
    `/groups/${groupOid}/advanced-settings`,
    data,
  )
  return response.data
}
