import { captureConsoleIntegration, init, setUser } from "@sentry/react"
import type { User as AuthUser } from "firebase/auth"

import { SENTRY_DSN } from "./constants"
import { auth } from "./firebaseApp"

export function setupSentry(skipBrowserExtensionCheck: boolean = false) {
  if (SENTRY_DSN) {
    console.debug("Initializing Sentry with DSN", SENTRY_DSN)
    init({
      dsn: SENTRY_DSN,
      skipBrowserExtensionCheck,
      integrations: [captureConsoleIntegration({ levels: ["error"] })],
      enabled: process.env.NODE_ENV !== "development",
      release: __COMMIT_SHA__,
    })

    auth.onAuthStateChanged((user: AuthUser | null) => {
      if (user) {
        const { uid, email } = user
        if (email) {
          setUser({ email, id: uid, username: email })
        } else {
          setUser({ id: user.uid })
        }
      } else {
        setUser(null)
      }
    })
  }
}
