import { type FirestoreDataConverter, doc, getDoc } from "firebase/firestore"

import { db } from "./firebaseApp"

export interface FirebaseDocument {
  oid: string
}

const makeConverterImpl = <
  T extends FirebaseDocument,
>(): FirestoreDataConverter<T, Omit<T, "oid">> => ({
  toFirestore: (obj: T) => {
    const copy = { ...obj }
    // @ts-expect-error oid is not stored.
    delete copy.oid
    return copy
  },
  fromFirestore: (snapshot, options): T => {
    const data = snapshot.data(options)
    return {
      oid: snapshot.id,
      ...data,
    } as T
  },
})

const sharedConverter = makeConverterImpl<FirebaseDocument>()

// Create a converter for type T.
export const makeConverter = <
  T extends FirebaseDocument,
>(): FirestoreDataConverter<T, Omit<T, "oid">> =>
  // This implementation shares the converter for all types since it is actually
  // identical.
  sharedConverter as FirestoreDataConverter<T, Omit<T, "oid">>

export const getDocumentById = async <T extends FirebaseDocument>(
  ...docPath: string[]
): Promise<T> => {
  if (docPath.length === 0) {
    throw new Error("docPath is required")
  }

  if (docPath.length % 2 !== 0) {
    throw new Error("docPath should be a doc (even length), not collection")
  }

  // @ts-expect-error firestore types do not like spreading with the collection
  // as the first value.
  const docRef = doc(db, ...docPath).withConverter(makeConverter<T>())
  const docSnapshot = await getDoc(docRef)
  const data = docSnapshot.data()
  if (!data) {
    // TODO(mgraczyk): Use UserFacingError, but it needs to be an exception.
    throw new Error(`document does not exist: ${docPath.toString()}`)
  }
  return data
}
