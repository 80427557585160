import { Timestamp } from "firebase/firestore"
import { useCallback, useEffect, useReducer } from "react"
import { useDebounceCallback } from "usehooks-ts"

import useErrorPopup from "../../hooks/useErrorPopup"
import { getNodes } from "../../sources/api"
import type { Source } from "../../sources/types"
import { reducer } from "./reducer"
import { initialState } from "./state"

interface UseIntegrationPickerProps {
  source?: Source
}

export const useIntegrationPicker = ({ source }: UseIntegrationPickerProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { handleError } = useErrorPopup()

  const fetchNodes = useCallback(
    async (cursor?: string, searchMode = false) => {
      if (!source?.oid) {
        return { hasMore: false, nodes: undefined, nextCursor: undefined }
      }

      dispatch({ type: "SET_LOADING", payload: true })
      try {
        const response = await getNodes({
          sourceId: source.oid,
          parentId: state.currentPath || null,
          cursor,
          limit: searchMode ? 100 : 25,
        })
        const hasMore = !!response.data.nextCursor

        const items = response.data.items.map((item) => ({
          ...item,
          created_at: new Timestamp(
            item.created_at._seconds,
            item.created_at._nanoseconds,
          ) as FirebaseFirestore.Timestamp,
          updated_at: new Timestamp(
            item.updated_at._seconds,
            item.updated_at._nanoseconds,
          ) as FirebaseFirestore.Timestamp,
        }))

        dispatch({
          type: "ADD_NODES",
          payload: {
            items,
            nextCursor: response.data.nextCursor ?? undefined,
            hasMore,
          },
        })

        return {
          hasMore,
          nodes: items,
          nextCursor: response.data.nextCursor ?? undefined,
        }
      } catch (error) {
        handleError({ error, prefix: "Failed to fetch nodes" })
        dispatch({ type: "SET_ERROR", payload: "Failed to fetch nodes" })
        return { hasMore: false, nodes: undefined }
      } finally {
        dispatch({ type: "SET_LOADING", payload: false })
      }
    },
    [source?.oid, state.currentPath, handleError],
  )

  const debouncedLoadMore = useDebounceCallback(async () => {
    if (state.isLoading || !state.hasMore) {
      return { hasMore: false, nodesCount: 0 }
    }
    return await fetchNodes(state.cursor)
  }, 300)

  const handleSearch = useCallback(
    async (query: string) => {
      if (!source || !query.trim()) return
      try {
        const MAX_SEARCH_NODES = 400
        let totalNodes = state.nodes.length
        let localHasMore = state.hasMore
        let localCursor = state.cursor

        while (totalNodes < MAX_SEARCH_NODES && localHasMore) {
          const result = await fetchNodes(localCursor, true)
          if (!result) break
          const { hasMore, nodes, nextCursor } = result
          totalNodes += nodes?.length || 0
          localHasMore = hasMore
          localCursor = nextCursor
          if (!hasMore) break
          const matchFound = nodes?.some((node) =>
            node.title?.toLowerCase().includes(query.trim().toLowerCase()),
          )
          if (matchFound) {
            dispatch({ type: "SET_SEARCH_PAUSED", payload: true })
            break
          }
        }
      } catch {
        dispatch({ type: "SET_ERROR", payload: "Search failed" })
      }
    },
    [fetchNodes, source, state.nodes, state.hasMore, state.cursor],
  )

  const debouncedHandleSearch = useDebounceCallback(handleSearch, 300)

  const handleFolderClick = useCallback((external_id: string) => {
    dispatch({ type: "SET_CURRENT_PATH", external_id })
  }, [])

  const handleSyncChange = useCallback((oid: string) => {
    dispatch({ type: "TOGGLE_SYNC", oid })
  }, [])

  const cancelChanges = useCallback(() => {
    dispatch({ type: "RESET_CHANGES" })
  }, [])

  useEffect(() => {
    if (!source?.nodes_synced_at) return
    void fetchNodes()
  }, [source?.nodes_synced_at, state.currentPath, fetchNodes])

  return {
    state,
    loadMore: debouncedLoadMore,
    handleFolderClick,
    handleSyncChange,
    cancelChanges,
    handleSearch: debouncedHandleSearch,
  }
}
