import { getColumnLetter } from "../../sheets/utils"
import type { WorksheetRange } from "../../types/sheets"

const FeedTitleCTA: React.FC<{
  location?: WorksheetRange | null
  className?: string
}> = ({ location, className = "" }) => {
  const locationLabel = location
    ? `${getColumnLetter(location.firstColIndex)}${location.firstRowIndex}`
    : undefined
  return (
    <div className={"flex items-center gap-1 font-bold " + className}>
      {location && (
        <div className="text-primary m-0 p-0 font-bold">{locationLabel}</div>
      )}
      DISCUSSION
    </div>
  )
}

export default FeedTitleCTA
