import { pyBackendAxiosInstance } from "../../api"
import type { ColumnConfig } from "./types"

export interface RunAgentTablePromptsRequest {
  inputs: Record<string, string>
  columns: ColumnConfig[]
}

export type AgentTablePromptResults = Record<string, string>

export const runAgentTablePrompts = async (
  request: RunAgentTablePromptsRequest,
): Promise<AgentTablePromptResults> => {
  const res = await pyBackendAxiosInstance.post<AgentTablePromptResults>(
    "/runAgentTablePrompts",
    request,
  )
  return res.data
}

export interface CreateAgentTableRequest {
  name: string
  description?: string
  columns: ColumnConfig[]
}
