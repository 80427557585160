import { Alert, Button, Form, Input, Skeleton, Space, Switch } from "antd"
import { type DocumentReference, doc } from "firebase/firestore"
import { PlusIcon, Trash2Icon } from "lucide-react"
import { useCallback, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"

import { EMPTY_ARRAY } from "../../constants"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  GROUP_SETTINGS_COLLECTION,
  type Group,
  type GroupSettings,
} from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import type { AnswerMetadata, AnswererSearchTool } from "../../types/answerer"
import { ANSWER_METADATA_COLLECTION } from "../../types/common"
import {
  type UpdateAdvancedSettingsRequest,
  updateAdvancedSettings,
} from "./api"

interface FormDataType {
  settings: NonNullable<UpdateAdvancedSettingsRequest["settings"]>
  metadata: NonNullable<UpdateAdvancedSettingsRequest["answer_metadata"]> & {
    default_search_tool_list?: AnswererSearchTool[]
    search_tools?: AnswererSearchTool[]
  }
}

interface SearchToolListProps {
  name: string[]
  initialValue: AnswererSearchTool[]
  label: string
  tooltip: React.ReactNode
  allowMultiple: boolean
  addButtonText: string
}

const SearchToolList: React.FC<SearchToolListProps> = ({
  name,
  initialValue,
  label,
  tooltip,
  allowMultiple,
  addButtonText,
}) => {
  return (
    <Form.List name={name} initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <Form.Item label={label} tooltip={tooltip}>
          {fields.map((field) => (
            <div
              key={field.key}
              className="mt-2 rounded-md border border-gray-200 p-4 pt-1"
            >
              <div className="flex items-center justify-end">
                <Button
                  type="text"
                  danger
                  icon={<Trash2Icon size={18} />}
                  onClick={() => remove(field.name)}
                  title="Remove tool"
                  aria-label="Remove tool"
                />
              </div>

              <Form.Item
                {...field}
                label="Tool Name"
                name={[field.name, "name"]}
                rules={[
                  { required: true, message: "Please enter a tool name" },
                ]}
              >
                <Input placeholder="Tool name" />
              </Form.Item>

              <Form.Item
                {...field}
                label="Description"
                name={[field.name, "description"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter a description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Tool description"
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              </Form.Item>

              <Form.Item
                {...field}
                label="Tags Filter"
                className="hidden"
                name={[field.name, "tags_filter"]}
              >
                <Input.TextArea
                  readOnly
                  placeholder="Tags filter..."
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              </Form.Item>
            </div>
          ))}
          {fields.length === 0 || (allowMultiple && fields.length > 0) ? (
            <Button
              type="dashed"
              className="mt-2"
              onClick={() => add({ name: "", description: "" })}
              icon={<PlusIcon />}
            >
              {addButtonText}
            </Button>
          ) : null}
        </Form.Item>
      )}
    </Form.List>
  )
}

interface Props {
  group: Group
}

const settingsDocRef = (groupOid: string): DocumentReference<GroupSettings> =>
  doc(db, GROUP_SETTINGS_COLLECTION, groupOid).withConverter(
    makeConverter<GroupSettings>(),
  )

const metadataDocRef = (
  metadataOid: string,
): DocumentReference<AnswerMetadata> =>
  doc(db, ANSWER_METADATA_COLLECTION, metadataOid).withConverter(
    makeConverter<AnswerMetadata>(),
  )

const SettingsTab: React.FC<Props> = ({ group }) => {
  const [form] = Form.useForm<FormDataType>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const [settings, settingsLoading, settingsError] = useDocumentData(
    settingsDocRef(group.oid),
  )
  const [metadata, metadataLoading, metadataError] = useDocumentData(
    metadataDocRef(group.oid),
  )

  const onFinish = useCallback(
    async (values: FormDataType) => {
      setSubmitting(true)
      try {
        // Transform the default_search_tool_list to default_search_tool
        const formValues = { ...values }

        // Set default_search_tool based on the list
        if (
          formValues.metadata.default_search_tool_list &&
          formValues.metadata.default_search_tool_list.length > 0
        ) {
          formValues.metadata.default_search_tool =
            formValues.metadata.default_search_tool_list[0]
        } else {
          formValues.metadata.default_search_tool = null
        }

        // Remove the default_search_tool_list before sending to API
        const { default_search_tool_list, ...metadataToSend } =
          formValues.metadata
        void default_search_tool_list

        await updateAdvancedSettings(group.oid, {
          settings: formValues.settings,
          answer_metadata: metadataToSend,
        })

        handleSuccess("Updated settings")
      } catch (error) {
        handleError({ error, prefix: "Couldn't update settings" })
      } finally {
        setSubmitting(false)
      }
    },
    [group.oid, handleSuccess, handleError],
  )

  const loading = settingsLoading ?? metadataLoading
  const error = settingsError ?? metadataError
  if (error) {
    return (
      <Alert
        message="Could not load settings"
        showIcon
        description={error.message}
        type="error"
      />
    )
  }

  const initialValues = {
    settings: {
      import_questionnaire_on_complete:
        settings?.import_questionnaire_on_complete ?? false,
    },
    metadata: {
      company_name: metadata?.company_name ?? "",
      global_custom_prompt: metadata?.global_custom_prompt ?? "",
    },
  }
  return (
    <Skeleton active loading={loading}>
      <Form
        form={form}
        layout="vertical"
        className="w-full max-w-3xl"
        disabled={loading || submitting}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <h3>Advanced Settings</h3>
        <Form.Item
          label="Automatically import questionnaire on completion"
          tooltip="When a Questionnaire is marked as complete in the Questionnaire Assistant, Quilt will automatically import the responses from that questionnaire to the Answer Bank."
          name={["settings", "import_questionnaire_on_complete"]}
          valuePropName="checked"
          className=""
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Company Name"
          name={["metadata", "company_name"]}
          tooltip="The name of your company as you would like the model to write it."
        >
          <Input placeholder="Example Company, Inc..." />
        </Form.Item>
        <Form.Item
          label="Global Custom Prompt"
          name={["metadata", "global_custom_prompt"]}
          tooltip={`This prompt will be used everywhere for your group.
                    Use it to add important information or context, or control the style and voice.`}
        >
          <Input.TextArea
            placeholder="Our products include..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <SearchToolList
          name={["metadata", "default_search_tool_list"]}
          label="Questionnaire/Default Search Tool"
          initialValue={
            metadata?.default_search_tool
              ? [metadata.default_search_tool]
              : EMPTY_ARRAY
          }
          tooltip={
            <>
              The default search tool to use for questionnaires and non-chat
              surfaces.
              <br />
              This setting is not required, we will search all documents if not
              explicitly specified.
            </>
          }
          allowMultiple={false}
          addButtonText="Add Default Search Tool"
        />

        <SearchToolList
          name={["metadata", "search_tools"]}
          label="Chat Search Tools"
          initialValue={metadata?.search_tools ?? EMPTY_ARRAY}
          tooltip={
            <>
              Search tool used by chat surfaces, for example slack and the
              Knowledge Assistant.
              <br />
              If missing, we use the default search tool.
            </>
          }
          allowMultiple={true}
          addButtonText="Add Search Tool"
        />

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {submitting ? "Saving..." : "Save"}
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Skeleton>
  )
}

export default SettingsTab
