import deepEqual from "fast-deep-equal"
import { deleteDoc, doc, setDoc } from "firebase/firestore"

import { rowsColRef } from "./crud"
import type { AgentTableRow } from "./types"

/**
 * Synchronizes local rows with database rows by identifying and executing
 * create, update, and delete operations as needed.
 */
export const syncLocalRowsWithDbRows = async (
  groupOid: string,
  tableOid: string,
  dbRows: AgentTableRow[],
  rows: AgentTableRow[],
) => {
  // Only proceed if we have valid group and table IDs
  if (!groupOid || !tableOid) return

  // Create maps for easier lookup by oid
  const dbRowsMap = new Map(dbRows.map((row) => [row.oid, row]))
  const localRowsMap = new Map(rows.map((row) => [row.oid, row]))

  const rowsToCreate: AgentTableRow[] = []
  const rowsToUpdate: AgentTableRow[] = []
  const rowsToDelete: string[] = []

  // Find rows to create or update
  for (const row of rows) {
    const dbRow = dbRowsMap.get(row.oid)

    if (!dbRow) {
      // Row exists locally but not in DB - create it
      rowsToCreate.push(row)
    } else {
      // Row exists in both - check if it needs updating
      if (!deepEqual(row, dbRow)) {
        rowsToUpdate.push(row)
      }
    }
  }

  // Find rows to delete (exist in DB but not locally)
  for (const dbRow of dbRows) {
    if (!localRowsMap.has(dbRow.oid)) {
      rowsToDelete.push(dbRow.oid)
    }
  }
  if (!rowsToCreate.length && !rowsToUpdate.length && !rowsToDelete.length) {
    return
  }

  // Execute database operations
  const rowsCollection = rowsColRef(groupOid, tableOid)

  // Create new rows
  const createPromises = rowsToCreate.map((row) =>
    // Use the withConverter helper to convert the row object properly
    setDoc(doc(rowsCollection, row.oid), row),
  )

  // Update existing rows
  const updatePromises = rowsToUpdate.map((row) => {
    const rowRef = doc(rowsCollection, row.oid)
    // TODO(mgraczyk): Only write the updated fields?
    return setDoc(rowRef, row)
  })

  // Delete removed rows
  const deletePromises = rowsToDelete.map((rowOid) =>
    deleteDoc(doc(rowsCollection, rowOid)),
  )

  console.log("updating table with", {
    dbRows,
    rows,
    rowsToCreate,
    rowsToUpdate,
    rowsToDelete,
  })

  // Wait for all operations to complete
  await Promise.all([...createPromises, ...updatePromises, ...deletePromises])
}
