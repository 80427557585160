import { type MenuProps } from "antd"
import App from "antd/es/app"
import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import { doc, updateDoc } from "firebase/firestore"
import {
  ClipboardCheck,
  ExternalLink,
  Eye,
  FileDown,
  ImportIcon,
  MoreHorizontal,
  RotateCcw,
  Trash2,
} from "lucide-react"
import { useCallback, useState } from "react"
import { Link } from "react-router"

import { confirmAndExecute } from "../../components/confirmAndExecute"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../../types/common"
import { ANSWER_QUESTIONNAIRE_JOBS_COLLECTION } from "../../types/jobs"
import type { MimeType } from "../../types/mimetype"
import { canImportAsQuestionnaire, getDownloadMenuItems } from "./DownloadMenu"
import {
  type DownloadFormat,
  createSourceDocumentFromQuestionnaire,
  downloadCompleteAnswerQuestionnaireJob,
  reviewAnswerQuestionnaireJob,
  unmarkCompletedAnswerQuestionnaireJob,
} from "./api"

interface Props {
  groupOid: string
  oid: string
  exportedSourceDocumentOid: string | null
  canReview: boolean
  canDownload: boolean
  isCompleted: boolean
  disabled: boolean
  unapprovedCount: number
  originalMimeType: MimeType
}

const QuestionnaireCardMenu: React.FC<Props> = ({
  groupOid,
  oid,
  exportedSourceDocumentOid,
  canReview,
  canDownload,
  isCompleted,
  disabled,
  unapprovedCount,
  originalMimeType,
}) => {
  const { modal } = App.useApp()
  const { messageApi, handleSuccess, handleError } = useErrorPopup()
  const [modifying, setModifying] = useState(false)
  const [creatingSource, setCreatingSource] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)

  const onMarkCompleted = useCallback(async () => {
    setModifying(true)
    try {
      await reviewAnswerQuestionnaireJob(oid)
      handleSuccess("Marked questionnaire as completed")
    } catch (error) {
      handleError({ prefix: "Couldn't complete questionnaire", error })
      setModifying(false)
    }
  }, [oid, handleSuccess, handleError])

  const onUnmarkCompleted = useCallback(async () => {
    setModifying(true)
    try {
      await unmarkCompletedAnswerQuestionnaireJob(oid)
      handleSuccess("Marked questionnaire for re-review")
    } catch (error) {
      handleError({ prefix: "Couldn't send questionnaire for review", error })
      setModifying(false)
    }
  }, [oid, handleSuccess, handleError])

  const onRemove = useCallback(async () => {
    if (modifying) {
      return
    }

    const docRef = doc(
      db,
      GROUPS_COLLECTION,
      groupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
      oid,
    )
    setModifying(true)
    try {
      await updateDoc(docRef, { removal_requested: true })
      handleSuccess("Started removing questionnaire")
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove questionnaire" })
    } finally {
      setModifying(false)
    }
  }, [modifying, groupOid, oid, handleSuccess, handleError])

  const onDownload = useCallback(
    async (format: DownloadFormat) => {
      if (modifying) {
        return
      }

      setModifying(true)
      setDownloadLoading(true)
      try {
        void messageApi.info("Preparing questionnaire download")
        await downloadCompleteAnswerQuestionnaireJob(oid, format)
      } catch (error) {
        handleError({ error, prefix: "Couldn't download questionnaire" })
      } finally {
        setDownloadLoading(false)
        setModifying(false)
      }
    },
    [modifying, oid, messageApi, handleError],
  )

  const onDownloadConfirm = useCallback(
    ({ key: format }: { key: DownloadFormat }) => {
      if (unapprovedCount === 0) {
        void onDownload(format)
      } else {
        modal.confirm({
          title: "Answers Not Yet Approved",
          content: (
            <>
              {unapprovedCount} answers have not been approved by their owner.
              <br />
              Are you sure you want to continue downloading?
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk: () => onDownload(format),
        })
      }
    },
    [unapprovedCount, onDownload, modal],
  )

  const clickCreateSource = useCallback(async () => {
    if (creatingSource) {
      return
    }
    try {
      setCreatingSource(true)
      void messageApi.info("Importing...")
      await createSourceDocumentFromQuestionnaire(oid)
      handleSuccess(
        "Imported as source document, Answer Bank will update in the background",
      )
    } catch (error) {
      handleError({ error, prefix: "Couldn't import answers" })
    } finally {
      setCreatingSource(false)
    }
  }, [creatingSource, oid, messageApi, handleSuccess, handleError])

  const items: MenuProps["items"] = []

  if (canReview) {
    items.push({
      label: (
        <Link to={`/questionnaire-assistant/review/feed/${oid}`}>Review</Link>
      ),
      icon: <Eye size={16} />,
      key: "review",
    })
  }

  if (canDownload) {
    items.push({
      icon: <FileDown size={16} className="mt-0.5" />,
      label: downloadLoading ? "Downloading..." : "Download As",
      key: "download",
      children: getDownloadMenuItems(
        originalMimeType,
        onDownloadConfirm,
        downloadLoading,
      ),
    })
  }

  if (isCompleted) {
    if (canImportAsQuestionnaire(originalMimeType)) {
      if (exportedSourceDocumentOid) {
        items.push({
          label: (
            <Link
              to={`/source-documents/${exportedSourceDocumentOid}`}
              target="_blank"
            >
              Imported to Answer Bank
            </Link>
          ),
          icon: <ExternalLink size={16} />,
          key: "go-to-source",
        })
      } else {
        items.push({
          icon: <ImportIcon size={16} />,
          label: creatingSource
            ? "Importing..."
            : "Import Answers to Answer Bank",
          onClick: clickCreateSource as () => void,
          disabled: creatingSource,
          key: "create-source",
        })
      }
    }
    items.push({
      icon: <RotateCcw size={16} />,
      label: "Mark for Re-review",
      onClick: onUnmarkCompleted as () => void,
      key: "unmark-complete",
    })
  }

  if (canReview) {
    items.push({
      icon: <ClipboardCheck size={16} />,
      label: "Mark as Complete",
      onClick: confirmAndExecute(
        onMarkCompleted,
        "Are you sure you want to mark this questionnaire as complete?",
      ),
      key: "mark-complete",
    })
  }

  items.push({
    label: "Remove Questionnaire",
    icon: <Trash2 size={16} />,
    onClick: confirmAndExecute(
      onRemove,
      "Are you sure you want to remove this questionnaire?",
    ),
    key: "remove",
    danger: true,
  })

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        type="default"
        className="flex items-center"
        size="small"
        disabled={disabled}
      >
        <MoreHorizontal />
      </Button>
    </Dropdown>
  )
}

export default QuestionnaireCardMenu
