import Form from "antd/es/form"
import Input from "antd/es/input"
import Skeleton from "antd/es/skeleton"
import type { User as AuthUser } from "firebase/auth"
import { Bell, Unplug } from "lucide-react"
import { Link } from "react-router"

import ExternalLink from "../components/ExternalLink"
import GroupImage from "../components/Groups/GroupImage"
import Header from "../components/Header"
import {
  DATA_USE_DISCLOSURE_URL,
  PRIVACY_URL,
  SUPPORT_URL,
  TERMS_URL,
} from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useActiveGroup } from "../contexts/useActiveGroup"

const UserProfileForm: React.FC<{ authUser: AuthUser }> = ({ authUser }) => {
  return (
    <Form layout="vertical" name="user-profile-form" className="w-full">
      <Form.Item label={<span className="font-bold">Name</span>}>
        <Input
          disabled
          value={authUser.displayName ?? ""}
          title="Name is from Google, cannot be edited"
        />
      </Form.Item>
      <Form.Item label={<span className="font-bold">Email</span>}>
        <Input
          disabled
          value={authUser.email ?? ""}
          title="Email is from Google, cannot be edited"
        />
      </Form.Item>
    </Form>
  )
}

const ActiveGroupSection: React.FC = () => {
  const { numGroups, activeGroup } = useActiveGroup()
  return (
    <>
      <span className="font-bold">Your Group</span>
      <div className="flex items-center gap-4">
        <Skeleton
          className="w-96"
          active
          loading={!activeGroup}
          avatar
          paragraph={{ rows: 0 }}
        >
          <Link to="/group/settings" className="flex items-center gap-4">
            <GroupImage size={48} imageUrl={activeGroup?.image_url} />
            <div className="text-lg font-bold">{activeGroup?.name}</div>
          </Link>
          {numGroups > 1 && (
            <Link to="/group/switch" className="rounded-xl border p-2">
              Switch
            </Link>
          )}
        </Skeleton>
      </div>
    </>
  )
}

const LinksSection: React.FC = () => {
  return (
    <div className="flex flex-col text-gray-600">
      <ExternalLink href={SUPPORT_URL}>Quilt Help Center</ExternalLink>
      <ExternalLink href={TERMS_URL}>Terms of Use</ExternalLink>
      <ExternalLink href={PRIVACY_URL}>Privacy Policy</ExternalLink>
      <ExternalLink href={DATA_USE_DISCLOSURE_URL}>
        Data Use Disclosure
      </ExternalLink>
    </div>
  )
}

const UserProfilePage: React.FC = () => {
  const { authUser } = useActiveUserAuthorizationFromContext()
  return (
    <>
      <Header title="Your Profile" />
      <div className="m-8 max-w-[600px]">
        <h3 className="my-8">Profile</h3>
        <div className="mb-10 flex w-full">
          {authUser.photoURL && (
            <img
              className="mr-8 h-[100px] w-[100px] rounded-[100px]"
              width="100"
              height="100"
              src={authUser.photoURL}
              alt={`Photo of ${authUser.displayName}`}
            />
          )}
          <div className="w-full">
            <UserProfileForm authUser={authUser} />
            <ActiveGroupSection />
          </div>
        </div>
        <div className="bg-gray-25 mb-2 inline-flex w-full rounded-lg px-3 py-2 font-bold">
          <Bell className="mr-2" /> Notifications can be managed in the&nbsp;
          <Link
            className="text-purple-600 underline"
            to="/profile/notification-preferences"
          >
            Notifications Page
          </Link>
        </div>
        <div className="bg-gray-25 mb-16 inline-flex w-full rounded-lg px-3 py-2 font-bold">
          <Unplug className="mr-2" /> Integrations can be found on the&nbsp;
          <Link
            className="text-purple-600 underline"
            to="/group/settings/integrations"
          >
            Group Settings Page
          </Link>
        </div>
        <h3>Resources</h3>
        <LinksSection />
      </div>
    </>
  )
}

export default UserProfilePage
