import { Button, Form, Input } from "antd"
import { useCallback, useState } from "react"

import GroupImage from "../../components/Groups/GroupImage"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { updateGroup } from "../../groups/api"
import type { Group } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"

interface Props {
  group: Group
}

interface FormDataType {
  name: string
  image_url: string
}

const EditGroupForm: React.FC<Props> = ({ group }) => {
  const [form] = Form.useForm<FormDataType>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const { hasPerm } = useActiveUserAuthorizationFromContext()

  const imageUrl = Form.useWatch("image_url", form)

  const onFinish = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        await updateGroup({
          oid: group.oid,
          ...formData,
        })

        handleSuccess("Updated group")
      } catch (error) {
        handleError({ error, prefix: "Couldn't save group" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, group],
  )

  const initialValues = {
    name: group.name,
    image_url: group.image_url ?? "",
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className=""
      disabled={submitting || !hasPerm("admin")}
      style={{ width: 800 }}
      requiredMark="optional"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <h3>Basic Info</h3>
      <Form.Item label="Group Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Image URL"
        name="image_url"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Image Preview" required>
        <GroupImage imageUrl={imageUrl} size={48} />
      </Form.Item>

      <Button
        type="primary"
        disabled={submitting || !hasPerm("admin")}
        className="submit flex"
        title={
          !hasPerm("admin")
            ? "Contact your group admin to modify the group"
            : ""
        }
        htmlType="submit"
      >
        {submitting ? "Saving..." : "Save"}
      </Button>
    </Form>
  )
}

export default EditGroupForm
