import { Navigate, Route, Routes } from "react-router"

import type { QuiltFlags } from "../groups/types"
import KnowledgeItemPage from "../knowledge/KnowledgeItemPage"
import AgentTablePage from "../pages/AgentTable"
import AgentTableList from "../pages/AgentTable/AgentTableList"
import AssignmentsPage from "../pages/Assignments"
import ChatPage from "../pages/ChatPagev2"
import GroupSettingsPage from "../pages/GroupSettings"
import HomePage from "../pages/HomePage"
import KnowledgeItemsTable from "../pages/KnowledgeItemsTable"
import NotificationPreferences from "../pages/NotificationPreferences"
import NotificationsPage from "../pages/NotificationsPage"
import QuestionnaireAssistantPage from "../pages/QuestionnaireAssistant"
import ReferenceSearchPage from "../pages/ReferenceSearchPage"
import SetQuestionAnswerLayoutPage from "../pages/SetQuestionAnswerLayoutPage"
import UserDocumentPage from "../pages/UserDocumentPage"
import UserDocumentsPage from "../pages/UserDocumentsPage"
import UserProfilePage from "../pages/UserProfilePage"
import AcceptInvitePage from "./AcceptInvitePage"
import UserGroupsManager from "./Groups/UserGroupsManager"
import NotFoundPage from "./NotFoundPage"
import QuestionnaireReview from "./QuestionnaireReview"
import QuestionnaireAnswers from "./QuestionnaireReview/QuestionnaireAnswers"
import getLazy from "./getLazy"

const CoachingScenarioAdminPage = getLazy(
  () => import("../pages/CoachingScenarioPage"),
)
const CoachingScenarioAdminFormPage = getLazy(
  () => import("../pages/CoachingScenarioPage/ScenarioFormPage"),
)
const UserScenarios = getLazy(
  () => import("../pages/CoachingScenarioPage/UserScenarios"),
)

const UserScenario = getLazy(
  () => import("../pages/CoachingScenarioPage/UserScenario"),
)

const SlackInstallError = getLazy(() => import("./SlackInstallError"))
const SlackInstallSuccess = getLazy(() => import("./SlackInstallSuccess"))
const SlackLinkAccount = getLazy(() => import("./SlackLinkAccount"))
const SlackReinstallPage = getLazy(() => import("../pages/SlackReinstallPage"))
const PlansPage = getLazy(() => import("../billing/PlansPage"))
const AdminPage = getLazy(() => import("../quiltAdmin/AdminPage"))
const AgentChatPage = getLazy(() => import("../pages/AgentChat"))

const AppRoutes: React.FC<{ flags: QuiltFlags }> = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/agent-chat" element={<AgentChatPage />} />
      <Route path="/source-documents" element={<UserDocumentsPage />} />
      <Route
        path="/source-documents/set-layout"
        element={<SetQuestionAnswerLayoutPage />}
      />
      <Route path="/source-documents/:docOid" element={<UserDocumentPage />} />
      <Route
        path="/questionnaire-assistant"
        element={<QuestionnaireAssistantPage />}
      />
      <Route
        path="/questionnaire-assistant/review/:oid"
        element={<QuestionnaireReview />}
      />
      <Route
        path="/questionnaire-assistant/review/:questionnaireOid/responses"
        element={<QuestionnaireAnswers />}
      />
      <Route
        path="/questionnaire-assistant/review/feed/:oid"
        element={<QuestionnaireReview feedView />}
      />
      <Route path="/knowledge-items" element={<KnowledgeItemsTable />} />
      <Route path="/knowledge-items/:docOid" element={<KnowledgeItemPage />} />
      <Route path="/slack-link-account" element={<SlackLinkAccount />} />
      <Route
        path="/slack/success/:teamId/:appId"
        element={<SlackInstallSuccess />}
      />
      <Route path="/slack/error" element={<SlackInstallError />} />
      <Route path="/slack/reinstall" element={<SlackReinstallPage />} />
      <Route path="/assignments" element={<AssignmentsPage />} />
      <Route path="/reference-search" element={<ReferenceSearchPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/chat/:sessionOid" element={<ChatPage />} />
      <Route path="/group/settings" element={<GroupSettingsPage />} />
      <Route
        path="/group/settings/:settingsPage"
        element={<GroupSettingsPage />}
      />
      <Route path="/group/switch" element={<UserGroupsManager />} />
      <Route path="/profile" element={<UserProfilePage />} />
      <Route
        path="/profile/notification-preferences"
        element={<NotificationPreferences />}
      />
      <Route path="/notifications" element={<NotificationsPage />} />
      <Route path="/group/settings/billing/plans" element={<PlansPage />} />

      <Route
        path="/groups"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group/reporting"
        element={<Navigate to="/group/settings/reporting" replace />}
      />
      <Route
        path="/coaching-assistant"
        element={<Navigate to="/coaching/scenarios" />}
      />
      <Route
        path="/coaching-admin"
        element={<Navigate to="/coaching/admin" />}
      />
      <Route path="/coaching/admin" element={<CoachingScenarioAdminPage />} />
      <Route
        path="/coaching/admin/:scenarioOid"
        element={<CoachingScenarioAdminFormPage />}
      />
      <Route path="/coaching/scenarios" element={<UserScenarios />} />
      <Route
        path="/coaching/scenarios/:scenarioAttemptOid"
        element={<UserScenario />}
      />
      <Route path="/agent-tables" element={<AgentTableList />} />
      <Route path="/agent-tables/:tableOid" element={<AgentTablePage />} />

      {/* /slack is linked to from Slack "App Homepage" button */}
      <Route path="/slack" element={<Navigate to="/" replace />} />
      <Route path="/signup" element={<Navigate to="/" replace />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/invite/:id" element={<AcceptInvitePage />} />
      <Route path="/admin/*" element={<AdminPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
