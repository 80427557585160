import type { RadioChangeEvent } from "antd"
import Collapse from "antd/es/collapse"
import TextArea from "antd/es/input/TextArea"
import Radio from "antd/es/radio"
import Select from "antd/es/select"
import Switch from "antd/es/switch"
import Tooltip from "antd/es/tooltip"
import { useCallback } from "react"

import { ALL_LOCALES, type LocaleType } from "../../types/locale"
import SelectTags from "../SelectTags"
import type { Action } from "./reducer"
import type { AnyDocument } from "./state"

interface Props {
  className?: string
  documentIndex: number
  currentDocument: AnyDocument
  dispatch: React.Dispatch<Action>
}

const AdvancedMenu: React.FC<Props> = ({
  className = "",
  documentIndex,
  currentDocument,
  dispatch,
}) => {
  const {
    verbatimFromAnswerBank,
    selectedTags,
    tagOperator,
    locale,
    customPrompt,
  } = currentDocument

  const onVerbatimFromAnswerBankChange = useCallback(
    (value: boolean) => {
      dispatch({
        type: "SET_VERBATIM_FROM_ANSWER_BANK",
        value,
      })
    },
    [dispatch],
  )

  const onSelectedTagsChange = useCallback(
    (payload: string[]) => {
      dispatch({
        type: "SET_SELECTED_TAGS",
        documentIndex,
        payload,
      })
    },
    [dispatch, documentIndex],
  )

  const onLocaleChange = useCallback(
    (payload: LocaleType) => {
      dispatch({
        type: "SET_LOCALE",
        documentIndex,
        payload,
      })
    },
    [dispatch, documentIndex],
  )

  const onTagOperatorChange = useCallback(
    (e: RadioChangeEvent) => {
      dispatch({
        type: "SET_TAG_OPERATOR",
        documentIndex,
        payload: e.target.value as "AND" | "OR",
      })
    },
    [dispatch, documentIndex],
  )

  const onCustomPromptChange = useCallback(
    (payload: string) => {
      dispatch({
        type: "SET_CUSTOM_PROMPT",
        documentIndex,
        payload,
      })
    },
    [dispatch, documentIndex],
  )

  const collapseItems = [
    {
      label: "Advanced Options",
      key: "1",
      children: (
        <>
          <div>
            <label className="mr-2">
              Require answers to be verbatim from the Answer Bank:
            </label>
            <Switch
              checked={verbatimFromAnswerBank}
              onChange={onVerbatimFromAnswerBankChange}
            />
          </div>
          <div className="mt-6 flex items-center">
            <label className="mr-2">Tag Filter:</label>
            <SelectTags
              className="max-w-[500px] grow"
              value={selectedTags}
              onChange={onSelectedTagsChange}
            />

            <div className="ml-2">
              <Radio.Group onChange={onTagOperatorChange} value={tagOperator}>
                <Tooltip title="Only documents with ANY of these tags will be used">
                  <Radio.Button value="OR">Any</Radio.Button>
                </Tooltip>
                <Tooltip title="Only documents with ALL of these tags will be used">
                  <Radio.Button value="AND">All</Radio.Button>
                </Tooltip>
              </Radio.Group>
            </div>
          </div>
          <div className="mt-6 flex items-center">
            <label className="mr-2">Language:</label>
            <Select
              className="min-w-32"
              onChange={onLocaleChange}
              value={locale}
              options={ALL_LOCALES}
            />
          </div>
          <div className="mt-6">
            <label className="mr-2">Custom Prompt:</label>
            <TextArea
              className="rounded border border-solid border-gray-200 p-1"
              value={customPrompt ?? ""}
              onChange={(e) => onCustomPromptChange(e.target.value)}
            />
          </div>
        </>
      ),
    },
  ]

  return <Collapse className={className} items={collapseItems} />
}

export default AdvancedMenu
