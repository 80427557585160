import { Button, Divider, Modal, Tabs, Tooltip } from "antd"
import { CheckIcon, MoveLeftIcon, MoveRightIcon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useEventListener } from "usehooks-ts"

import { VOID_FUNCTION } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useSearchParamValue from "../../hooks/useSearchParamValue"
import type { GeneratedAnsweredQuestion } from "../../types/jobs"
import Discussions from "../Discussions/Discussions"
import CellHistory from "./CellHistory"
import ReferenceSelector from "./ReferencesPicker"
import { renderQuestionAnswer } from "./ResponseRenderers"
import type { QuestionnaireFeedAction } from "./types"
import type { AnswerWithDiscussion } from "./types"

interface Props {
  jobOid: string
  answerObj: AnswerWithDiscussion
  updatingAnswer: boolean
  takeAction: (action: QuestionnaireFeedAction) => Promise<void>
  loading: boolean
  answerIndex: number
  totalAnswers: number
  isApprover: boolean
}

const FocusModeView: React.FC<Props> = ({
  jobOid,
  answerObj,
  updatingAnswer,
  takeAction,
  loading,
  answerIndex,
  totalAnswers,
  isApprover,
}) => {
  const [, setFocusedOid] = useSearchParamValue("focusedOid")
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [selectedTab, setSelectedTab] = useState("references")
  const [editedPrimaryAnswer, setEditedPrimaryAnswer] = useState(
    answerObj.primary_answer?.text ?? "",
  )
  const [editedSecondaryAnswer, setEditedSecondaryAnswer] = useState(
    answerObj.secondary_answer?.text ?? "",
  )
  const [localLoading, setLocalLoading] = useState(false)

  // Keep edited answer in sync with the answerObj.
  useEffect(() => {
    setEditedPrimaryAnswer(answerObj.primary_answer.text)
  }, [answerObj.primary_answer.text])
  useEffect(() => {
    setEditedSecondaryAnswer(answerObj.secondary_answer?.text ?? "")
  }, [answerObj.secondary_answer?.text])

  const handleUpdateClick = async () => {
    await takeAction({
      type: "update-answer",
      answer: answerObj,
      primaryAnswer: editedPrimaryAnswer,
      secondaryAnswer: editedSecondaryAnswer,
    })
  }

  const handleActionAndNextClick = async () => {
    const isEdited =
      editedPrimaryAnswer !== answerObj.primary_answer.text ||
      (answerObj.secondary_answer != null &&
        editedSecondaryAnswer !== answerObj.secondary_answer.text)
    setLocalLoading(true)
    try {
      if (isEdited) {
        await handleUpdateClick()
      }

      const myUnresolvedDiscussionOids =
        answerObj.discussions
          ?.filter((d) => d.assignment?.uid === authUser.uid)
          ?.map((d) => d.oid) ?? []
      await takeAction({
        type: "resolve",
        discussionOids: myUnresolvedDiscussionOids,
      })

      if (isApprover) {
        await takeAction({
          type: "approve",
          answer: answerObj,
          skipContentCheck: isEdited,
        })
      }

      if (answerIndex === totalAnswers - 1) {
        setFocusedOid(null)
      } else {
        await takeAction({
          index: answerIndex + 1,
          type: "goto-answer",
        })
      }
    } finally {
      setLocalLoading(false)
    }
  }

  useEventListener("keydown", async ({ key, repeat }: KeyboardEvent) => {
    if (repeat) return

    switch (key) {
      case "ArrowLeft":
        await takeAction({
          index: answerIndex - 1,
          type: "goto-answer",
        })
        break
      case "ArrowRight":
        await takeAction({
          index: answerIndex + 1,
          type: "goto-answer",
        })
        break
      case "Enter":
        await handleUpdateClick()
        break
      default:
        return
    }
  })

  const exitFocusView = useCallback(() => {
    setFocusedOid(null)
  }, [setFocusedOid])

  const onAnswerChanged = useCallback(
    (newAnswer: GeneratedAnsweredQuestion) => {
      setEditedPrimaryAnswer(newAnswer.primary_answer.text)
      setEditedSecondaryAnswer(newAnswer.secondary_answer?.text ?? "")
      // TODO(mgraczyk): Handle updating references.
    },
    [],
  )

  const cancelKeys = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    // Do not take modal-level actions when the user is typing.
    if (e.key === "Enter" || e.key === "ArrowRight" || e.key === "ArrowLeft") {
      e.stopPropagation()
    }
  }, [])

  const showLoading = updatingAnswer || loading || localLoading

  const location = answerObj.primary_question.location
  const historyTabContent = (
    <>
      <h3 className="mb-2 text-lg font-semibold">History</h3>
      <div className={"grow overflow-y-auto " + (location ? "max-h-96" : "")}>
        <CellHistory
          answer_oid={answerObj.oid}
          group_oid={activeGroupOid}
          job_oid={jobOid}
        />
      </div>
      <h3 className="mb-2 text-lg font-semibold">Discussions</h3>
      <div className="min-h-48 grow overflow-y-auto" onKeyDown={cancelKeys}>
        <Discussions
          group_oid={activeGroupOid}
          discussions={answerObj.discussions}
          job_id={jobOid}
          location={location}
          response_oid={answerObj.oid}
          kind="SHEET"
          onClickLocation={VOID_FUNCTION}
          hideTitle
        />
      </div>
    </>
  )

  const focusTabView = (
    <Tabs
      className="w-1/2 px-4 lg:hidden"
      tabBarStyle={{ marginBottom: 0 }}
      items={[
        {
          key: "references",
          label: "References",
          // HACK: antd tabs has no fixed height so we guess here.
          children: (
            <div
              onKeyDown={cancelKeys}
              className="h-[calc(100vh-134px)] overflow-y-auto"
            >
              <ReferenceSelector
                jobOid={jobOid}
                answerOid={answerObj.oid}
                initialReferences={answerObj.references}
                onAnswerChanged={onAnswerChanged}
                className="w-full"
              />
            </div>
          ),
        },
        {
          key: "history-discussions",
          label: "History & Discussions",
          // HACK: antd tabs has no fixed height so we guess here.
          children: (
            <div className="h-[calc(100vh-134px)] overflow-y-auto">
              {historyTabContent}
            </div>
          ),
        },
      ]}
      activeKey={selectedTab}
      onChange={(key) => setSelectedTab(key)}
    />
  )

  const modalContent = (
    <div className="flex h-full">
      <div className="flex w-1/2 flex-col gap-2 border-r px-4 lg:w-1/3">
        <h3 className="text-lg font-semibold">
          Response {answerIndex + 1} / {totalAnswers}
        </h3>
        <div className="flex flex-wrap gap-2">
          <div className="flex gap-2">
            <Button
              type="primary"
              onClick={() =>
                takeAction({
                  index: answerIndex - 1,
                  type: "goto-answer",
                })
              }
              disabled={answerIndex === 0}
            >
              <MoveLeftIcon size={16} /> Previous
            </Button>

            <Button
              type="primary"
              onClick={() =>
                takeAction({
                  index: answerIndex + 1,
                  type: "goto-answer",
                })
              }
              disabled={answerIndex === totalAnswers - 1}
            >
              Next <MoveRightIcon size={16} />
            </Button>
          </div>
          <Tooltip
            title={
              isApprover ? (
                "Approve the response and go to next answer"
              ) : (
                <>
                  Resolve your unresolved discussions and go to next answer.
                  <br />
                  This will not resolve discussions assigned to other members.
                </>
              )
            }
          >
            <Button
              icon={<CheckIcon size={16} />}
              onClick={handleActionAndNextClick}
              className="bg-green-600 text-white hover:!border-green-800 hover:!bg-green-400 hover:!text-white disabled:!border-gray-200 disabled:!bg-gray-100 disabled:!text-gray-400"
              disabled={showLoading}
              loading={showLoading}
            >
              {isApprover ? "Approve" : "Resolve"} &amp; Next{" "}
              <MoveRightIcon size={16} />
            </Button>
          </Tooltip>
        </div>
        <Divider />
        <div onKeyDown={cancelKeys}>
          {renderQuestionAnswer(
            jobOid,
            answerObj,
            true,
            editedPrimaryAnswer,
            setEditedPrimaryAnswer,
            editedSecondaryAnswer,
            setEditedSecondaryAnswer,
          )}
        </div>
        <div>
          <Button
            icon={<CheckIcon size={16} />}
            onClick={handleUpdateClick}
            type="primary"
            disabled={showLoading}
            loading={showLoading}
          >
            Update
          </Button>
        </div>
      </div>
      {focusTabView}
      <div
        onKeyDown={cancelKeys}
        className="hidden w-1/3 border-r px-4 lg:flex"
      >
        <ReferenceSelector
          jobOid={jobOid}
          answerOid={answerObj.oid}
          initialReferences={answerObj.references}
          onAnswerChanged={onAnswerChanged}
          className=""
        />
      </div>
      <div className="hidden w-1/3 flex-col px-4 lg:flex">
        {historyTabContent}
      </div>
    </div>
  )

  return (
    <Modal
      open
      onCancel={exitFocusView}
      footer={null}
      destroyOnClose
      width="100%"
      className="!p-0"
      wrapClassName="m-6 [&_.ant-modal-content]:!p-0 [&_.ant-modal-body]:!h-[calc(100vh-64px)]"
      style={{ top: 0 }}
      styles={{
        body: {
          padding: 0,
          overflow: "hidden",
        },
        content: {
          padding: 0,
          overflow: "hidden",
        },
      }}
    >
      {modalContent}
    </Modal>
  )
}

export default FocusModeView
