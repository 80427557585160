import { openWindow, sleep } from "./utils"

export const openUrlInPopup = async (url: string, target: string) => {
  const width = 500
  const height = 700
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2
  const windowFeatures = `width=${width},height=${height},top=${top},left=${left},noreferrer"`
  const popup = await openWindow(url, target, windowFeatures)

  for (let i = 0; i < 100; i++) {
    if (popup.closed) {
      break
    }
    await sleep(1000)
  }
}

export const openUrlInWindow = async (url: string) => {
  const win = await openWindow(url)
  for (let i = 0; i < 100; i++) {
    if (win.closed) {
      break
    }
    await sleep(1000)
  }
}
