import {
  FileAudioIcon,
  FileQuestionIcon,
  FileSpreadsheetIcon,
  FileTextIcon,
  GlobeIcon,
  PresentationIcon,
  SlackIcon,
} from "lucide-react"

import { MimeType } from "../types/mimetype"

export const MimeTypeToDisplay: Record<
  MimeType,
  { name: string; icon: React.ReactElement }
> = {
  [MimeType.GOOGLE_DOC]: {
    name: "Google Doc",
    icon: <FileTextIcon />,
  },
  [MimeType.CSV]: {
    name: "CSV",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.GOOGLE_SLIDES]: {
    name: "Google Slides",
    icon: <PresentationIcon />,
  },
  [MimeType.GOOGLE_SHEETS]: {
    name: "Google Sheets",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.ODP]: {
    name: "OpenDoc Presentation",
    icon: <PresentationIcon />,
  },
  [MimeType.ODS]: {
    name: "OpenDoc Spreadsheet",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.ODT]: {
    name: "OpenDoc Text",
    icon: <FileTextIcon />,
  },
  [MimeType.HTML]: {
    name: "Website",
    icon: <GlobeIcon />,
  },
  [MimeType.PDF]: {
    name: "PDF",
    icon: <FileTextIcon />,
  },
  [MimeType.DOCX]: {
    name: "Word (docx)",
    icon: <FileTextIcon />,
  },
  [MimeType.DOC]: {
    name: "Word (doc)",
    icon: <FileTextIcon />,
  },
  [MimeType.TXT]: {
    name: "Text (txt)",
    icon: <FileTextIcon />,
  },
  [MimeType.RTF]: {
    name: "Text (rtf)",
    icon: <FileTextIcon />,
  },
  [MimeType.PPTX]: {
    name: "PowerPoint (pptx)",
    icon: <PresentationIcon />,
  },
  [MimeType.XLS]: {
    name: "Excel (xls)",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.XLSX]: {
    name: "Excel (xlsx)",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.XLSM]: {
    name: "Excel (xlsm)",
    icon: <FileSpreadsheetIcon />,
  },
  [MimeType.NOTION_PAGE]: {
    name: "Notion Page",
    // TODO(mgraczyk): Add Notion icon.
    icon: <FileTextIcon />,
  },
  [MimeType.MARKDOWN]: {
    name: "Markdown",
    icon: <FileTextIcon />,
  },
  [MimeType.CONFLUENCE_PAGE]: {
    name: "Confluence Page",
    // TODO(mgraczyk): Add Confluence icon.
    icon: <FileTextIcon />,
  },
  [MimeType.KNOWLEDGE_ITEM]: {
    name: "Knowledge Item",
    icon: <FileTextIcon />,
  },
  [MimeType.GONG_CALL]: {
    name: "Gong Call",
    icon: <FileAudioIcon />,
  },
  [MimeType.SLACK_THREAD]: {
    name: "Slack Thread",
    icon: <SlackIcon />,
  },
  [MimeType.UNKNOWN]: {
    name: "Unknown",
    icon: <FileQuestionIcon />,
  },
}

export const SupportedUserDocumentMimetypes = new Set([
  MimeType.CSV,
  MimeType.GOOGLE_DOC,
  MimeType.GOOGLE_SLIDES,
  MimeType.GOOGLE_SHEETS,
  MimeType.ODP,
  MimeType.ODS,
  MimeType.ODT,
  MimeType.HTML,
  MimeType.PDF,
  MimeType.DOCX,
  MimeType.DOC,
  MimeType.TXT,
  MimeType.MARKDOWN,
  MimeType.RTF,
  MimeType.PPTX,
  MimeType.XLS,
  MimeType.XLSX,
  MimeType.XLSM,
])
