import Avatar from "antd/es/avatar"

import { getBackgroundColor, getInitials } from "./utils"

interface UserBadgeProps {
  name: string
  photoURL?: string
  size?: "lg" | "sm" | "xs"
}

const UserAvatar: React.FC<UserBadgeProps> = ({
  name,
  photoURL,
  size = "lg",
}) => {
  const sizePx = size === "lg" ? 50 : size === "sm" ? 40 : 30
  return (
    <Avatar
      size={sizePx}
      className={getBackgroundColor(name)}
      style={{ minWidth: sizePx }}
      src={photoURL}
    >
      {getInitials(name)}
    </Avatar>
  )
}

export default UserAvatar
