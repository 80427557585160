import { Button, Popover, Skeleton, Tabs } from "antd"
import { CloudUploadIcon, GlobeIcon, PlusIcon, TextIcon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router"

import ConfluenceLogo from "../assets/img/confluence_logo.svg"
import DriveLogo from "../assets/img/drive_logo.svg"
import NotionLogo from "../assets/img/notion_logo.svg"
import AddDriveDocumentForm from "../components/AddDriveDocumentForm"
import AddKnowledgeItemForm from "../components/AddKnowledgeItemForm"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useKeydown } from "../hooks/events"
import useSources from "../hooks/useSources"
import AddPublicWebUrlForm from "./AddPublicWebUrl"
import UploadUserDocumentForm from "./UploadUserDocumentForm"

export const AddIntegrationDocumentForm: React.FC<{ name: string }> = ({
  name,
}) => {
  return (
    <div className="text-lg text-gray-800">
      Choose the documents to sync between {name} and Quilt by clicking
      &quot;Connect&quot; or &quot;Modify&quot; on{" "}
      <Link
        className="font-semibold hover:underline"
        to="/group/settings/integrations"
      >
        your integrations page.
      </Link>
    </div>
  )
}

interface PopoverContentProps {
  showAtlassian: boolean
  onClose: () => void
  isCompletedQuestionnaireActions: boolean
  loading: boolean
  className?: string
}

const PopoverContent: React.FC<PopoverContentProps> = ({
  className = "",
  showAtlassian,
  onClose,
  isCompletedQuestionnaireActions,
  loading,
}) => {
  useKeydown(
    27, // Escape
    onClose,
  )

  if (loading) {
    return <Skeleton active loading />
  }

  const items = [
    {
      label: (
        <div className="flex items-center">
          <CloudUploadIcon height="1rem" />
          Upload
        </div>
      ),
      key: "upload",
      children: (
        <UploadUserDocumentForm
          onClose={onClose}
          isCompletedQuestionnaireActions={isCompletedQuestionnaireActions}
        />
      ),
    },
    ...(isCompletedQuestionnaireActions
      ? []
      : [
          {
            label: (
              <div className="flex items-center">
                <GlobeIcon height="1rem" />
                Web Page(s)
              </div>
            ),
            key: "webpage",
            children: <AddPublicWebUrlForm onClose={onClose} />,
          },
        ]),
    {
      label: (
        <div className="flex items-center">
          <img
            src={DriveLogo}
            alt="Google Drive logo"
            className="mr-2 h-4 w-4"
          />
          Google Drive
        </div>
      ),
      key: "google-drive",
      children: (
        <AddDriveDocumentForm
          onClose={onClose}
          isCompletedQuestionnaireActions={isCompletedQuestionnaireActions}
        />
      ),
    },
    ...(!isCompletedQuestionnaireActions
      ? [
          {
            label: (
              <div className="flex items-center">
                <img
                  src={NotionLogo}
                  alt="Notion logo"
                  className="mr-2 h-4 w-4"
                />
                Notion
              </div>
            ),
            key: "notion",
            children: <AddIntegrationDocumentForm name="Notion" />,
          },
        ]
      : []),
    ...(showAtlassian && !isCompletedQuestionnaireActions
      ? [
          {
            label: (
              <div className="flex items-center">
                <img
                  src={ConfluenceLogo}
                  alt="Atlassian Confluence logo"
                  className="mr-2 h-4 w-4"
                />
                Confluence
              </div>
            ),
            key: "confluence",
            children: <AddIntegrationDocumentForm name="Confluence" />,
          },
        ]
      : []),
    ...(isCompletedQuestionnaireActions
      ? [
          {
            label: (
              <div className="flex items-center">
                <TextIcon height="1rem" />
                Inline Form
              </div>
            ),
            key: "knowledge-item-form",
            className: "flex items-center",
            children: <AddKnowledgeItemForm onClose={onClose} />,
          },
        ]
      : []),
  ]

  return <Tabs className={className} defaultActiveKey="upload" items={items} />
}

interface Props {
  buttonId?: string
  isCompletedQuestionnaireActions: boolean
}

const AddDocumentPickerButton: React.FC<Props> = ({
  buttonId,
  isCompletedQuestionnaireActions,
}) => {
  const [sources, sourcesLoading, sourcesError] = useSources()
  const { hasPerm } = useActiveUserAuthorizationFromContext()
  const [open, setOpen] = useState(false)

  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  useEffect(() => {
    if (sourcesError) {
      console.error(sourcesError)
    }
  }, [sourcesError])

  if (!hasPerm("doc.modify")) {
    return null
  }

  const hasAtlassian =
    sources?.some((source) => source.kind === "CONFLUENCE") ?? false

  const buttonText = isCompletedQuestionnaireActions
    ? "Import Items"
    : "Source Documents"

  return (
    <>
      <div id="knowledge-documents-actions" className="flex gap-2">
        <Popover
          content={
            <PopoverContent
              className="w-[600px]"
              showAtlassian={hasAtlassian}
              onClose={hide}
              isCompletedQuestionnaireActions={isCompletedQuestionnaireActions}
              loading={sourcesLoading}
            />
          }
          trigger="click"
          placement="bottomLeft"
          // This is to make sure the popover is lower than Google Drive picker which has a z-index of 1001
          zIndex={1000}
          open={open}
          onOpenChange={setOpen}
        >
          <Button
            id={buttonId}
            type="primary"
            icon={<PlusIcon />}
            title={buttonText}
            className="flex h-9 items-center justify-center font-bold"
          >
            {buttonText}
          </Button>
        </Popover>
      </div>
    </>
  )
}
export default AddDocumentPickerButton
