import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { Client } from "typesense"
import { useLocalStorage } from "usehooks-ts"

import { getTypesenseApiKey } from "./api"
import type { GetTypesenseApiKeyResponse } from "./types"

const REFRESH_BUFFER_MILLIS = 1000 * 60 * 60 * 24 // 24 hours

interface TypesenseSearchContextType {
  loading: boolean
  error?: Error
  collectionName?: string
  client?: Client
}

const TypesenseSearchContext = createContext<TypesenseSearchContextType>({
  loading: true,
})

const TypesenseSearchProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  const [client, setClient] = useState<Client>()
  const [storedResponse, setStoredResponse] =
    useLocalStorage<GetTypesenseApiKeyResponse>("quilt__typesenseApiKey", {
      expiresAt: 0,
      apiKey: "",
      collectionName: "",
      host: "",
      port: 0,
      protocol: "",
    })

  useEffect(() => {
    if (client) {
      return
    }

    void (async () => {
      let response: GetTypesenseApiKeyResponse
      try {
        if (
          storedResponse.apiKey &&
          storedResponse.expiresAt * 1000 > Date.now() + REFRESH_BUFFER_MILLIS
        ) {
          response = storedResponse
        } else {
          // Clean up history of login attempts.
          localStorage?.removeItem("typesense-loginhistory")
          response = await getTypesenseApiKey()
          setStoredResponse(response)
        }
      } catch (error) {
        setError(error as Error)
        return
      } finally {
        setLoading(false)
      }

      setClient(
        new Client({
          nodes: [
            {
              host: response.host,
              // @ts-expect-error empty string is ok, will just not set it.
              port: response.port ?? "",
              protocol: response.protocol,
            },
          ],
          apiKey: response.apiKey,
        }),
      )
    })()
  }, [client, storedResponse, setStoredResponse])

  const value = useMemo(
    () => ({
      loading,
      error,
      collectionName: storedResponse.collectionName,
      client: client,
    }),
    [loading, error, client, storedResponse.collectionName],
  )
  return (
    <TypesenseSearchContext.Provider value={value}>
      {children}
    </TypesenseSearchContext.Provider>
  )
}

const useTypesenseSearch = (): TypesenseSearchContextType => {
  return useContext(TypesenseSearchContext)
}

export { TypesenseSearchProvider, useTypesenseSearch }
