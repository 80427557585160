/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useCallback, useEffect, useState } from "react"

import type { PickerConfiguration } from "./types"
import useInjectScript from "./useInjectScript"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any

export default function useDrivePicker(): [
  (config: PickerConfiguration) => boolean,
] {
  const [loaded, error] = useInjectScript("https://apis.google.com/js/api.js")
  const [pickerApiLoaded, setpickerApiLoaded] = useState(false)

  const onPickerApiLoad = useCallback(() => {
    setpickerApiLoaded(true)
  }, [])

  // load the Drive picker api
  const loadApis = useCallback(() => {
    // @ts-expect-error window.gapi is not typed
    window.gapi.load("picker", { callback: onPickerApiLoad })
  }, [onPickerApiLoad])

  // get the apis from googleapis
  useEffect(() => {
    if (loaded && !error && !pickerApiLoaded) {
      loadApis()
    }
  }, [loaded, error, pickerApiLoaded, loadApis])

  // open the picker
  const openPicker = (config: PickerConfiguration) => {
    // if we didnt get token generate token.
    if (!config.token) {
      throw new Error("No token provided")
    }

    // if we have token and everything is loaded open the picker
    if (config.token && loaded && !error && pickerApiLoaded) {
      return createPicker(config)
    }
    return false
  }

  const createPicker = ({
    token,
    appId = "",
    supportDrives = false,
    developerKey,
    viewId = "DOCS",
    disabled,
    multiselect,
    setOrigin,
    showUploadView = false,
    showUploadFolders,
    setParentFolder = "",
    viewMimeTypes,
    customViews,
    locale = "en",
    setIncludeFolders,
    setSelectFolderEnabled,
    disableDefaultView = false,
    callbackFunction,
  }: PickerConfiguration) => {
    if (disabled) return false

    const view = new google.picker.DocsView(google.picker.ViewId[viewId])
    if (viewMimeTypes) view.setMimeTypes(viewMimeTypes)
    if (setIncludeFolders) view.setIncludeFolders(true)
    if (setSelectFolderEnabled) view.setSelectFolderEnabled(true)

    const uploadView = new google.picker.DocsUploadView()
    if (viewMimeTypes) uploadView.setMimeTypes(viewMimeTypes)
    if (showUploadFolders) uploadView.setIncludeFolders(true)
    if (setParentFolder) uploadView.setParent(setParentFolder)
    if (setParentFolder) view.setParent(setParentFolder)

    const picker = new google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token)
      .setDeveloperKey(developerKey)
      .setLocale(locale)

    if (setOrigin) {
      picker.setOrigin(setOrigin)
    }

    if (!disableDefaultView) {
      picker.addView(view)
    }

    if (customViews) {
      customViews.map((view) => picker.addView(view))
    }

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    }

    if (showUploadView) picker.addView(uploadView)

    if (supportDrives) {
      picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
    }

    const builtPicker = picker.build()
    builtPicker.setVisible(true)
    builtPicker.setCallback((data: google.picker.ResponseObject) => {
      callbackFunction(data)
      if (data.action === "cancel" || data.action === "picked") {
        builtPicker.dispose()
      }
    })
    return true
  }

  return [openPicker]
}
