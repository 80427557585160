import { Skeleton } from "antd"
import { memo, useMemo } from "react"

import type { ReportingData } from "./api"

export const calculateOverviewMetrics = (
  reportingData: ReportingData | null,
) => {
  if (!reportingData) return null

  const totalQuestionsAsked = reportingData.answered_questions.reduce(
    (sum, week) => sum + week.total_questions,
    0,
  )

  const totalQuestionsAnswered = reportingData.answered_questions.reduce(
    (sum, week) => sum + week.answered_questions,
    0,
  )

  const totalQuestionnairesUploaded = reportingData.user_weekly_usage.reduce(
    (sum, item) => sum + item.freq_submit_questionnaire,
    0,
  )

  const hoursSaved = (totalQuestionsAnswered * 3) / 60

  const totalUsers = reportingData.active_users.length
  const activeUsers = reportingData.active_users.reduce(
    (sum, user) => sum + (user.L30 > 0 ? 1 : 0),
    0,
  )
  const activeUsersPercentage = totalUsers
    ? Math.min(100, Math.round((activeUsers / totalUsers) * 100))
    : "NA"

  return {
    totalUsers,
    activeUsers,
    activeUsersPercentage,
    totalQuestionsAsked,
    totalQuestionsAnswered,
    totalQuestionnairesUploaded,
    hoursSaved: hoursSaved.toFixed(2),
  }
}

export const OverviewStats = memo(
  ({
    metrics,
    loading,
  }: {
    metrics: ReturnType<typeof calculateOverviewMetrics>
    loading: boolean
  }) => {
    // Currently we do not accurately track seats, so we show unlimited.
    const numSeatsStr = "Unlimited"

    const statCards = useMemo(
      () =>
        metrics
          ? [
              { label: "SEATS", value: numSeatsStr },
              { label: "TOTAL USERS", value: metrics.totalUsers },
              { label: "ACTIVE USERS (30D)", value: metrics.activeUsers },
              {
                label: "% ACTIVE USERS",
                value: `${metrics.activeUsersPercentage}%`,
              },
              { label: "TOTAL HOURS SAVED", value: metrics.hoursSaved },
              {
                label: "TOTAL QUESTIONS ASKED",
                value: metrics.totalQuestionsAsked.toLocaleString(),
              },
              {
                label: "TOTAL QUESTIONS ANSWERED",
                value: metrics.totalQuestionsAnswered.toLocaleString(),
              },
              {
                label: "TOTAL QUESTIONNAIRES UPLOADED",
                value: metrics.totalQuestionnairesUploaded.toLocaleString(),
              },
            ]
          : [],
      [metrics],
    )

    return (
      <Skeleton active paragraph={{ rows: 3 }} loading={loading}>
        <div className="mb-6 grid grid-cols-2 gap-4 md:grid-cols-4">
          {statCards.map((stat) => (
            <div key={stat.label} className="rounded-lg bg-white p-4 shadow-md">
              <div className="text-sm font-medium text-gray-500">
                {stat.label}
              </div>
              <div className="text-primary text-xl font-bold">{stat.value}</div>
            </div>
          ))}
        </div>
      </Skeleton>
    )
  },
)
