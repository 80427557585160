import type { MenuDividerType, MenuItemType } from "antd/es/menu/interface"
import type { User as AuthUser } from "firebase/auth"
import {
  ArrowLeftToLine,
  ArrowRightToLine,
  BookMarked,
  BookOpenText,
  CircleHelpIcon,
  DatabaseZapIcon,
  FilesIcon,
  HomeIcon,
  InboxIcon,
  ListIcon,
  MessageSquareMoreIcon,
  SheetIcon,
} from "lucide-react"

import type { QuiltPermission } from "../auth/permissions"
import type { QuiltFlags } from "../groups/types"
import HelpButton from "./HelpButton"
import SidebarUserIconMenu from "./SidebarUserIconMenu"

type NavigateFunction = (path: string) => void

type NavItem = (MenuItemType | MenuDividerType) & {
  key: string
  label?: React.ReactNode
  path?: string
  icon?: React.ReactNode
  children?: NavItem[]
}

export const getNavItems = (
  user: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  hasPerm: (perm: QuiltPermission) => boolean,
): NavItem[] => {
  const isQuiltAdmin = hasPerm("quiltAdmin")
  const isGroupAdmin = hasPerm("admin")

  const items = [
    {
      key: "home",
      path: "/",
      icon: <HomeIcon />,
      label: "Home",
    },
    {
      key: "source-documents",
      icon: <FilesIcon />,
      label: "Source Documents",
    },
    {
      key: "knowledge-items",
      icon: <ListIcon />,
      label: "Answer Bank",
    },
    {
      key: "",
      style: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
      },
      type: "divider" as const,
    },
    {
      key: "chat",
      icon: <MessageSquareMoreIcon />,
      label: "Knowledge Assistant",
    },
    {
      key: "questionnaire-assistant",
      icon: <SheetIcon />,
      label: "Questionnaire Assistant",
    },
    {
      key: "agent-tables",
      icon: <DatabaseZapIcon />,
      label: "Agent Tables",
    },
    {
      key: "coaching/scenarios",
      icon: <BookOpenText />,
      style: {
        display: flags.showCoachingScenarios ? undefined : "none",
      },
      label: "Coaching Scenarios",
    },
    {
      key: "coaching/admin",
      icon: <BookMarked />,
      style: {
        display:
          flags.showCoachingScenarios && isGroupAdmin ? undefined : "none",
      },
      label: "Coaching Admin",
    },
    {
      key: "admin",
      className: isQuiltAdmin ? "" : "!hidden",
      icon: <span className="lucide pl-1 !text-xl">✞</span>,
      label: "Quilt Admin Tools",
    },
  ]

  const withLeadingSlash = (path: string) =>
    path.startsWith("/") ? path : `/${path}`

  return items.map((item: NavItem) => ({
    ...item,
    onClick: item.key
      ? () => navigate(withLeadingSlash(item.path ?? item.key))
      : undefined,
  }))
}

export const getBottomNavItems = (
  authUser: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  collapsed: boolean,
  setCollapsed: (collapsed: boolean) => void,
): NavItem[] => {
  return [
    {
      key: "sider-trigger",
      onClick: () => setCollapsed(!collapsed),
      icon: collapsed ? (
        <ArrowRightToLine size="18px" />
      ) : (
        <ArrowLeftToLine size="18px" />
      ),
      label: collapsed ? "" : "Collapse",
      title: collapsed ? "Expand Sidebar" : undefined,
    },
    {
      key: "tasks",
      icon: <InboxIcon />,
      label: collapsed ? "" : "Tasks",
      title: collapsed ? "Tasks" : undefined,
      onClick: () => navigate("/assignments"),
    },
    {
      key: "help-button",
      icon: (
        <HelpButton
          user={authUser}
          className="flex h-full cursor-pointer flex-row items-center"
        >
          <CircleHelpIcon />
        </HelpButton>
      ),
      label: collapsed ? "" : "Get Help",
      title: collapsed ? "Get Help" : undefined,
    },
    {
      key: "user",
      style: { height: "4em", paddingLeft: "16px", display: "flex" },
      icon: (
        <SidebarUserIconMenu
          label={collapsed ? "" : "Settings & Profile"}
          flags={flags}
        />
      ),
      title: "Settings & Profile",
    },
  ]
}

export const getNarrowNavItems = (
  authUser: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  hasPerm: (perm: QuiltPermission) => boolean,
): NavItem[] => {
  return [
    ...getNavItems(authUser, navigate, flags, (perm: QuiltPermission) =>
      hasPerm(perm),
    ),
    {
      key: "",
      style: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
      },
      type: "divider" as const,
    },
    {
      key: "tasks",
      icon: <InboxIcon />,
      label: "Tasks",
      onClick: () => navigate("/assignments"),
    },
    {
      key: "help-button",
      icon: (
        <HelpButton
          user={authUser}
          className="flex h-full cursor-pointer flex-row items-center"
        >
          <CircleHelpIcon />
          <span className="ml-1 text-[14px]">Get Help</span>
        </HelpButton>
      ),
    },
    {
      key: "user",
      icon: (
        <div className="flex items-center">
          <SidebarUserIconMenu label="" flags={flags} />
          <span className="ml-1 text-[14px]">Settings &amp; Profile</span>
        </div>
      ),
    },
  ]
}
