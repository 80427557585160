import type { AuthError } from "firebase/auth"
import { useCallback, useEffect } from "react"
import {
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from "react-firebase-hooks/auth"

import { sendEventsAfterSignIn } from "../analytics/signin"
import GoogleLetterLogo from "../components/GoogleLetterLogo"
import MicrosoftLetterLogo from "../components/MicrosoftLetterLogo"
import SignInButton from "../components/SignInButton"
import { GOOGLE_EMAIL_OAUTH_SCOPE } from "../constants"
import { auth } from "../firebaseApp"
import type { SignInUIError } from "../types/signin"

const getErrorMessage = (
  provider: "google.com" | "microsoft.com",
  error: AuthError,
): string | undefined => {
  if (!error) {
    return
  }

  // TODO(mgraczyk): For some reason Firebase can't see the window close
  // event, so it just times out and sets this value. Would be nice to react
  // faster.
  if (error.message.includes("auth/popup-closed-by-user")) {
    // User closed, ignore error.
    return
  }

  if (error.message.includes("auth/account-exists-with-different-credential")) {
    const tokenResponse = (
      error?.customData as unknown as
        | {
            _tokenResponse: {
              providerId?: string
              verifiedProvider?: string[]
            }
          }
        | undefined
    )?._tokenResponse
    const previousName = tokenResponse?.verifiedProvider?.includes("google.com")
      ? "Google"
      : tokenResponse?.verifiedProvider?.includes("microsoft.com")
        ? "Microsoft"
        : "SSO"
    return `You previously signed in with ${previousName}, you may need to use ${previousName} instead`
  }

  if (error instanceof Error && error.message.includes("non-work email")) {
    return "Quilt only supports work emails. Please sign in with your work email or contact us for help."
  }

  return "Error signing in, try again later and contact Quilt via slack or support@quilt.app"
}

interface Props {
  actionText: string
  setSignInError: (error: SignInUIError | undefined) => void
}

const SocialLoginForm: React.FC<Props> = ({ actionText, setSignInError }) => {
  const [signInWithGoogle, , googleLoading, googleError] =
    useSignInWithGoogle(auth)
  const [signInWithMicrosoft, , microsoftLoading, microsoftError] =
    useSignInWithMicrosoft(auth)

  useEffect(() => {
    const message = googleError
      ? getErrorMessage("google.com", googleError)
      : microsoftError
        ? getErrorMessage("microsoft.com", microsoftError)
        : null
    if (!message) {
      return
    }
    console.error("Error signing in", message, googleError, microsoftError)
    setSignInError({ message })
  }, [googleError, microsoftError, setSignInError])

  const onClickGoogleSignIn = useCallback(async () => {
    const requiredScopes: string[] = [GOOGLE_EMAIL_OAUTH_SCOPE]
    const result = await signInWithGoogle(requiredScopes)
    sendEventsAfterSignIn(result)
  }, [signInWithGoogle])

  const onClickMicrosoftSignIn = useCallback(async () => {
    const requiredScopes: string[] = ["User.Read", "email", "openid", "profile"]
    const result = await signInWithMicrosoft(requiredScopes)
    sendEventsAfterSignIn(result)
  }, [signInWithMicrosoft])

  const googleText = `${actionText} with Google`
  const microsoftText = `${actionText} with Microsoft`
  return (
    <>
      <SignInButton
        logo={<GoogleLetterLogo />}
        loading={googleLoading}
        buttonText={googleText}
        onClick={onClickGoogleSignIn}
      />
      <SignInButton
        logo={<MicrosoftLetterLogo />}
        loading={microsoftLoading}
        buttonText={microsoftText}
        onClick={onClickMicrosoftSignIn}
      />
    </>
  )
}

export default SocialLoginForm
