import { useCallback, useEffect, useState } from "react"

import { getOauthStateParam } from "../../auth/api"
import {
  GOOGLE_DRIVE_DOCUMENTS_SCOPES,
  GOOGLE_DRIVE_DOCUMENTS_STRICT_SCOPES,
} from "../../constants"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import {
  getUserCredentials,
  removeUserCredentialEntry,
} from "../../credentials/api"
import {
  ATLASSIAN_PROVIDER,
  type CredentialMetadata,
  GONG_PROVIDER,
  GOOGLE_PROVIDER,
  LINEAR_PROVIDER,
  NOTION_PROVIDER,
  SALESFORCE_PROVIDER,
  ZOOM_PROVIDER,
} from "../../credentials/providerTypes"
import useGoogleAccessToken from "./../useGoogleAccessToken"
import { type OAUTH_PROVIDERS, doOauthFlow } from "./doOauthFlow"

const useUserCredential = () => {
  const { authUser, flags } = useActiveGroup()
  const [loadingError, setLoadingError] = useState<Error>()
  const [actionError, setActionError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(true)
  const [userCredentials, setUserCredentials] = useState<CredentialMetadata[]>(
    [],
  )
  const { getAccessToken: getGoogleAccessToken } = useGoogleAccessToken(
    authUser,
    flags.useStrictScopesForGoogleDrive
      ? GOOGLE_DRIVE_DOCUMENTS_STRICT_SCOPES
      : GOOGLE_DRIVE_DOCUMENTS_SCOPES,
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    setLoadingError(undefined)
    setActionError(undefined)
    try {
      const { data } = await getUserCredentials({})
      setUserCredentials(data.credentials)
      return data.credentials
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }
      setLoadingError(error)
      return null
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const removeCredentialEntry = useCallback(
    async (metadata: CredentialMetadata) => {
      setLoading(true)
      setActionError(undefined)
      try {
        await removeUserCredentialEntry({
          provider: metadata.provider,
          subProvider: metadata.subProvider,
        })
        await fetchData()
      } catch (error) {
        if (!(error instanceof Error)) {
          throw error
        }
        setActionError(error)
      } finally {
        setLoading(false)
      }
    },
    [fetchData],
  )

  const connectProvider = useCallback(
    async (provider: OAUTH_PROVIDERS) => {
      setLoading(true)
      setActionError(undefined)
      try {
        if (provider === GOOGLE_PROVIDER) {
          await getGoogleAccessToken()
        } else {
          const { data } = await getOauthStateParam({})
          await doOauthFlow(provider, data, {})
        }
        const fetchedCredentials = await fetchData()
        if (!fetchedCredentials?.some((c) => c.provider === provider)) {
          throw new Error("Connection not completed")
        }
      } catch (error) {
        if (!(error instanceof Error)) {
          throw error
        }
        console.error("Could not connect provider", provider, error)
        setActionError(error)
        throw error
      } finally {
        setLoading(false)
      }
    },
    [getGoogleAccessToken, fetchData],
  )

  const connectGoogleDrive = useCallback(
    () => connectProvider(GOOGLE_PROVIDER),
    [connectProvider],
  )

  const connectGong = useCallback(
    () => connectProvider(GONG_PROVIDER),
    [connectProvider],
  )

  const connectLinear = useCallback(
    () => connectProvider(LINEAR_PROVIDER),
    [connectProvider],
  )

  const connectZoom = useCallback(
    () => connectProvider(ZOOM_PROVIDER),
    [connectProvider],
  )

  const connectSalesforce = useCallback(
    () => connectProvider(SALESFORCE_PROVIDER),
    [connectProvider],
  )

  const connectNotion = useCallback(
    () => connectProvider(NOTION_PROVIDER),
    [connectProvider],
  )

  const connectAtlassian = useCallback(
    () => connectProvider(ATLASSIAN_PROVIDER),
    [connectProvider],
  )

  return {
    loadingError,
    actionError,
    loading,
    userCredentials,
    removeCredentialEntry,
    connectGoogleDrive,
    connectGong,
    connectLinear,
    connectZoom,
    connectSalesforce,
    connectNotion,
    connectAtlassian,
  }
}

export default useUserCredential
