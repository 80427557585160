import { pyBackendAxiosInstance } from "../api"
import { httpsCallable } from "../serverUtils"
import type { MimeType } from "../types/mimetype.js"
import type { QuestionAnswerLayout } from "../types/sheets"
import type { GetDocumentContentArgs } from "./requestTypes.js"

export const getDocumentUrl = httpsCallable<GetDocumentContentArgs, string>(
  "getDocumentUrl",
)

export const reindexUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/reindex-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}

export const redownloadUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/redownload-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}

export const retryUserDocuments = async (
  documentOids: string[],
  run_synchronously: boolean = false,
): Promise<void> => {
  await pyBackendAxiosInstance.post("/documents/retry-many", {
    document_oids: documentOids,
    run_synchronously,
  })
}

export const setQuestionAnswerLayout = async (
  documentOid: string,
  layout: Record<string, QuestionAnswerLayout>,
): Promise<void> => {
  await pyBackendAxiosInstance.post(
    `/user_documents/${documentOid}/set-layout`,
    {
      layout,
    },
  )
}

export interface GetPreviewResponse {
  contents: string
  mimetype: MimeType
}

export const getUserDocumentPreview = async (
  documentOid: string,
): Promise<GetPreviewResponse> => {
  const { data } = await pyBackendAxiosInstance.post<GetPreviewResponse>(
    `/user_documents/${documentOid}/preview`,
    {},
  )
  return data
}
