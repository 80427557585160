import type { SyncNode } from "../../sources/types"
import { uniqueByField } from "../../utils"
import type { FilePickerState } from "./state"

export type Action =
  | {
      type: "ADD_NODES"
      payload: { items: SyncNode[]; nextCursor?: string; hasMore: boolean }
    }
  | { type: "TOGGLE_SYNC"; oid: string }
  | { type: "RESET_CHANGES" }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload?: string }
  | { type: "SET_CURRENT_PATH"; external_id: string }
  | { type: "SET_SEARCH_PAUSED"; payload: boolean }

export function reducer(
  state: FilePickerState,
  action: Action,
): FilePickerState {
  switch (action.type) {
    case "ADD_NODES": {
      const allNodes = uniqueByField(
        [...state.nodes, ...action.payload.items],
        "oid",
      )

      return {
        ...state,
        nodes: allNodes,
        cursor: action.payload.nextCursor,
        hasMore: action.payload.hasMore,
      }
    }

    case "TOGGLE_SYNC": {
      const node = state.nodes.find((n) => n.oid === action.oid)
      if (!node) return state

      const newChangedFiles = new Map(state.changedFiles)
      if (newChangedFiles.has(node.oid)) {
        newChangedFiles.delete(node.oid)
      } else {
        newChangedFiles.set(
          node.oid,
          node.sync_selection === "INCLUDE" ? "EXCLUDE" : "INCLUDE",
        )
      }

      return {
        ...state,
        changedFiles: newChangedFiles,
      }
    }

    case "RESET_CHANGES": {
      return {
        ...state,
        changedFiles: new Map(),
      }
    }

    case "SET_SEARCH_PAUSED":
      return { ...state, searchPaused: action.payload }

    case "SET_CURRENT_PATH":
      return { ...state, currentPath: action.external_id, searchPaused: false }

    case "SET_LOADING":
      return { ...state, isLoading: action.payload }

    case "SET_ERROR":
      return { ...state, error: action.payload }

    default:
      return state
  }
}
