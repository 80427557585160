import type { GroupRole } from "../groups/types"
import type { MimeType } from "./mimetype"
import type { DirectlyCreatableSourceKind } from "./userDocument"

export const USER_DOCUMENTS_COLLECTION = "user_documents"
export const ANSWER_METADATA_COLLECTION = "answer_metadata"
export const QUESTIONNAIRE_INFO_COLLECTION = "questionnaire_info"
export const USER_DOCUMENTS_HISTORY_SUBCOLLECTION = "history"
export const INVITES_COLLECTION = "invites"
export const GROUPS_COLLECTION = "groups"
export const GROUP_MEMBERSHIP_SUBCOLLECTION = "members"
export const GROUP_TAGS_SUBCOLLECTION = "tags"
export const SUBSCRIPTIONS_COLLECTION = "subscriptions"

export interface IndexableDocument {
  oid?: string
  title: string
  source_kind: DirectlyCreatableSourceKind
  url: string
  external_document_id: string
  mimetype: MimeType

  parent_external_id?: string
  source_oid?: string
}

// Claims on the user token.
// From functions/src/auth/types.ts
export interface QuiltFirebaseClaims {
  activeGroupOid: string
  quiltAdmin?: boolean
  roles: {
    [groupOid: string]: GroupRole
  }
  icomHash?: string
  isDisabled?: boolean

  // If set, this user is being impersonated by a quilt admin.
  impersonatorEmail?: string
}
