import type { AnswerWithDiscussion } from "./types"

const sortByDefault = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
): number => {
  const aLoc = a.primary_question?.location
  const bLoc = b.primary_question?.location
  if (!aLoc || !bLoc) {
    return 0
  }
  if (aLoc.sheetName !== bLoc.sheetName) {
    return aLoc.sheetName! < bLoc.sheetName! ? -1 : 1
  }

  return aLoc.firstRowIndex > bLoc.firstRowIndex
    ? 1
    : aLoc.firstRowIndex === bLoc.firstRowIndex
      ? 0
      : -1
}

const createSortByPriorityFunction = (currentUserUid: string) => {
  return (a: AnswerWithDiscussion, b: AnswerWithDiscussion): number => {
    const isUserTaggedInA =
      a.discussions?.some(
        (d) => d.assignment?.uid === currentUserUid && !d.resolution,
      ) ?? false

    const isUserTaggedInB =
      b.discussions?.some(
        (d) => d.assignment?.uid === currentUserUid && !d.resolution,
      ) ?? false

    // If one has the user tagged and the other doesn't, prioritize the one with the tag
    if (isUserTaggedInA !== isUserTaggedInB) {
      return isUserTaggedInA ? -1 : 1
    }

    // Show in lexical order:
    //  (no text, edited or reviewed, confidence, document order)
    const hasTextDiff =
      (a.primary_answer.text !== "" ? 1 : 0) -
      (b.primary_answer.text !== "" ? 1 : 0)
    if (hasTextDiff !== 0) {
      return hasTextDiff
    }

    const editedStatusDiff =
      (a.last_edited_by || a.last_reviewed_by ? 1 : 0) -
      (b.last_edited_by || b.last_reviewed_by ? 1 : 0)
    if (editedStatusDiff !== 0) {
      return editedStatusDiff
    }

    const confidenceDiff = a.confidence - b.confidence
    if (confidenceDiff !== 0) {
      return confidenceDiff
    }

    return sortByDefault(a, b)
  }
}

const sortByConfidence = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
): number => {
  const confidenceDiff = b.confidence - a.confidence // High to low
  return confidenceDiff !== 0 ? confidenceDiff : sortByDefault(a, b)
}

type AnswerDiscussionSortFunction = (
  a: AnswerWithDiscussion,
  b: AnswerWithDiscussion,
) => number

interface SorterFunctions {
  priority: AnswerDiscussionSortFunction
  confidence: AnswerDiscussionSortFunction
  default: AnswerDiscussionSortFunction
}

export function getSorters(uid: string): SorterFunctions {
  return {
    priority: createSortByPriorityFunction(uid),
    confidence: sortByConfidence,
    default: sortByDefault,
  }
}
