import type { FirestoreError } from "firebase/firestore"
import { collection } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { EMPTY_ARRAY } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { GroupTag } from "../tags/types"
import { GROUPS_COLLECTION, GROUP_TAGS_SUBCOLLECTION } from "../types/common"

type ResultType = [GroupTag[], boolean, FirestoreError | undefined]

export const useGroupTags = (group_oid: string): ResultType => {
  const result = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_TAGS_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupTag>()),
  )
  return [result[0] ?? EMPTY_ARRAY, result[1], result[2]]
}

export const useActiveGroupTags = (): ResultType => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  return useGroupTags(activeGroupOid)
}
