import { Alert, Button } from "antd"
import { Rows3Icon, Table2Icon } from "lucide-react"
import { useCallback } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router"

import { EMPTY_ARRAY } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { getErrorMessage } from "../../errors"
import { GroupMembersProvider } from "../../hooks/useGroupMembers"
import { QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES } from "../../types/mimetype"
import { MimeType } from "../../types/mimetype"
import Header from "../Header"
import NotFoundPage from "../NotFoundPage"
import DownloadQuestionnaireButton from "./DownloadQuestionnaireButton"
import FeedLoadingState from "./FeedLoading"
import GridLoading from "./GridLoading"
import QuestionnaireReviewFeedWrapper from "./QuestionnaireReviewFeedWrapper"
import QuestionnaireReviewSheetWrapper from "./QuestionnaireReviewSheetWrapper"
import useQuestionnaire from "./hooks/useQuestionnaire"
import { isApproved } from "./utils"

interface QuestionnaireReviewProps {
  feedView?: boolean
}

const QuestionnaireReview: React.FC<QuestionnaireReviewProps> = ({
  feedView,
}) => {
  const { oid = "" } = useParams<{ oid: string }>()
  const navigate = useNavigate()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const {
    jobData,
    answerDocs,
    cellEdits,
    discussions,
    discussionsError,
    baseFileUrl,
    loading,
    error,
    downloading,
    modifying,
    onDownload,
    markComplete,
  } = useQuestionnaire(oid)

  const allAnswersApproved = answerDocs?.every(isApproved) ?? false
  const getUnapprovedCount = useCallback(() => {
    return answerDocs?.filter((answer) => !isApproved(answer)).length ?? 0
  }, [answerDocs])

  const navigateView = useCallback(() => {
    void navigate(
      feedView
        ? `/questionnaire-assistant/review/${oid}`
        : `/questionnaire-assistant/review/feed/${oid}`,
    )
  }, [navigate, oid, feedView])

  let headerContent: React.ReactNode | null = null
  let bodyContent: React.ReactNode
  if (loading) {
    bodyContent = feedView ? <FeedLoadingState /> : <GridLoading />
  } else if (error) {
    bodyContent = (
      <div className="mt-8">
        <Alert
          message="Error loading questionnaire"
          description={getErrorMessage({ error })}
          type="error"
        />
      </div>
    )
  } else if (!jobData || jobData.state === "REMOVING") {
    bodyContent = <NotFoundPage noHeader />
  } else if (jobData.state === "DONE") {
    bodyContent = (
      <div>
        The questionnaire has already been completed and cannot be edited.
      </div>
    )
  } else if (jobData.state !== "REVIEWING") {
    bodyContent = (
      <div>Sorry, this questionnaire cannot be reviewed at this time</div>
    )
  } else {
    const isSpreadsheetType =
      jobData.document_mimetype &&
      QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES.includes(
        jobData.document_mimetype,
      )
    headerContent = (
      <div className="align-center flex justify-end">
        {isSpreadsheetType && (
          <Button
            className="text-primary mx-3 flex h-9 w-32 items-center justify-center rounded-md bg-purple-50 px-4 font-bold"
            title={feedView ? "Sheet View" : "List View"}
            onClick={navigateView}
            icon={feedView ? <Table2Icon /> : <Rows3Icon />}
          >
            {feedView ? "Sheet View" : "List View"}
          </Button>
        )}
        <DownloadQuestionnaireButton
          allAnswersApproved={allAnswersApproved}
          onDownload={onDownload}
          disabled={modifying || downloading}
          loading={modifying}
          downloading={downloading}
          getUnapprovedCount={getUnapprovedCount}
          questionnaireMimeType={jobData.document_mimetype ?? MimeType.UNKNOWN}
        />

        <Button
          type="primary"
          title="Mark as Complete"
          className="w-42 ml-3 h-9 font-bold"
          onClick={markComplete}
          disabled={modifying}
          loading={modifying}
        >
          Mark as Complete
        </Button>
      </div>
    )
    if (feedView) {
      bodyContent = (
        <QuestionnaireReviewFeedWrapper
          url={baseFileUrl}
          job={jobData}
          answers={answerDocs ?? EMPTY_ARRAY}
          discussions={discussions ?? EMPTY_ARRAY}
          discussionsError={discussionsError}
        />
      )
    } else if (isSpreadsheetType) {
      bodyContent = (
        <QuestionnaireReviewSheetWrapper
          url={baseFileUrl}
          job={jobData}
          answers={answerDocs ?? EMPTY_ARRAY}
          edits={cellEdits ?? EMPTY_ARRAY}
          discussions={discussions ?? EMPTY_ARRAY}
          discussionsError={discussionsError}
        />
      )
    } else {
      bodyContent = (
        <div className="mt-8 flex flex-col items-center gap-2">
          <Alert
            message="This questionnaire is not a spreadsheet"
            type="error"
            showIcon
            description="Please use the feed view to review this questionnaire"
          />
          <Button type="primary" onClick={navigateView}>
            Go to Feed View
          </Button>
        </div>
      )
    }
  }

  return (
    <>
      <Header
        title="Questionnaire Editor"
        breadcrumbs={[
          {
            title: "Questionnaire Assistant",
            href: "/questionnaire-assistant",
          },
          {
            title: jobData?.title ?? oid,
            href: `/questionnaire-assistant/review/${oid}`,
          },
        ]}
        className={feedView ? "" : "border-none"}
      >
        {headerContent}
      </Header>
      <div className="flex min-h-0 w-full grow justify-center pl-6">
        <GroupMembersProvider group_oid={activeGroupOid}>
          {bodyContent}
        </GroupMembersProvider>
      </div>
    </>
  )
}

export default QuestionnaireReview
