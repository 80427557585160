import { Button } from "antd"
import { CheckIcon, ExpandIcon, PencilIcon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

import useSearchParamValue from "../../hooks/useSearchParamValue"
import { renderQuestionAnswer } from "./ResponseRenderers"
import type { QuestionnaireFeedAction } from "./types"
import type { AnswerWithDiscussion } from "./types"

interface Props {
  jobOid: string
  answerObj: AnswerWithDiscussion
  updatingAnswer: boolean
  takeAction: (action: QuestionnaireFeedAction) => Promise<void>
  loading: boolean
}

const QuestionAnswerBlock: React.FC<Props> = ({
  jobOid,
  answerObj,
  updatingAnswer,
  takeAction,
  loading,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [, setFocusedOid] = useSearchParamValue("focusedOid")
  const [editedPrimaryAnswer, setEditedPrimaryAnswer] = useState(
    answerObj.primary_answer?.text ?? "",
  )
  const [editedSecondaryAnswer, setEditedSecondaryAnswer] = useState(
    answerObj.secondary_answer?.text ?? "",
  )

  const handleUpdateClick = async () => {
    await takeAction({
      type: "update-answer",
      answer: answerObj,
      primaryAnswer: editedPrimaryAnswer,
      secondaryAnswer: editedSecondaryAnswer,
    })
    setIsEditing(false)
  }

  const enterFocusView = useCallback(() => {
    setFocusedOid(answerObj.oid)
  }, [setFocusedOid, answerObj.oid])

  // Keep edited answer in sync with the answerObj.
  useEffect(() => {
    setEditedPrimaryAnswer(answerObj.primary_answer.text)
  }, [answerObj.primary_answer.text])
  useEffect(() => {
    setEditedSecondaryAnswer(answerObj.secondary_answer?.text ?? "")
  }, [answerObj.secondary_answer?.text])

  const showLoading = updatingAnswer || loading
  return (
    <>
      {renderQuestionAnswer(
        jobOid,
        answerObj,
        isEditing,
        editedPrimaryAnswer,
        setEditedPrimaryAnswer,
        editedSecondaryAnswer,
        setEditedSecondaryAnswer,
      )}
      <div className="mt-4">
        {isEditing ? (
          <div className="flex">
            <Button
              icon={<CheckIcon />}
              onClick={handleUpdateClick}
              type="primary"
              size="small"
              disabled={showLoading}
              loading={showLoading}
            >
              Update
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              type="text"
              size="small"
              className="ml-2"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <>
            <Button
              icon={<PencilIcon />}
              onClick={() => setIsEditing(true)}
              size="small"
              className="text-xs"
              disabled={loading}
            >
              Edit
            </Button>
            <Button
              icon={<ExpandIcon />}
              onClick={enterFocusView}
              size="small"
              className="ml-2 text-xs"
            >
              Focus
            </Button>
          </>
        )}
      </div>
    </>
  )
}

export default QuestionAnswerBlock
