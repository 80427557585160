import { useEffect, useRef } from "react"
import type { FallbackProps } from "react-error-boundary"
import { useLocation } from "react-router"

import Error500Page from "./Error500Page"
import FullPageLoader from "./FullPageLoader"

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const location = useLocation()
  const errorLocation = useRef(location.pathname)
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary()
    }
  }, [location.pathname, resetErrorBoundary])

  if (!(error instanceof Error)) {
    return <Error500Page />
  }

  const missingJsErrorPattern = "failed to fetch dynamically imported module"
  if (error.message.toLowerCase().includes(missingJsErrorPattern)) {
    console.warn("missing js error", error)

    // Only refresh if the last time we did it was more than 1 minute ago
    const lastRefreshOnError = localStorage.getItem("quilt__lastRefreshOnError")
    const now = Date.now()
    if (!lastRefreshOnError || now > parseInt(lastRefreshOnError) + 60000) {
      localStorage.setItem("quilt__lastRefreshOnError", now.toString())
      window.location.reload()
      return <FullPageLoader />
    }
  }

  return <Error500Page />
}
export default ErrorFallback
