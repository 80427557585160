import ChatReference from "../components/ChatReference"
import { isVerbatimFromAnswerBank } from "../hooks/useEnrichedReference"
import type { AnswerReference } from "../types/answerer"

interface ChatReferenceListProps {
  references: AnswerReference[]
  isLast?: boolean
  className?: string
}

const ChatReferenceList: React.FC<ChatReferenceListProps> = ({
  references,
  isLast,
  className = "",
}) => {
  if (!references || references.length === 0) {
    return null
  }

  return (
    <div className={"flex items-center justify-start gap-2 " + className}>
      <div className="xs:block hidden text-[12px] text-gray-400">
        References:
      </div>
      {references.map((reference, idx) => (
        <ChatReference
          key={idx}
          idx={idx}
          reference={reference}
          loading={false}
          loadKnowledgeItemEagerly={isLast}
          isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
        />
      ))}
    </div>
  )
}

export default ChatReferenceList
