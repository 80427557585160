import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Mentions from "antd/es/mentions"
import Popover from "antd/es/popover"
import Tooltip from "antd/es/tooltip"
import { AtSignIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { createComments } from "../discussions/api"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"

interface BulkCommentFormProps {
  jobOid: string
  answerOids: string[]
  onClose: () => void
}

const BulkCommentForm: React.FC<BulkCommentFormProps> = ({
  jobOid,
  answerOids,
  onClose,
}) => {
  const [comment, setComment] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isMentionedAssigned, setIsMentionedAssigned] = useState(true)
  const { handleError, handleSuccess } = useErrorPopup()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)

  const mentionedMember = members?.find((member) =>
    Mentions.getMentions(comment)
      .map((m) => m.value)
      .includes(member.email),
  )

  const handleSubmit = async () => {
    if (!comment.trim()) {
      handleError({ error: "Please enter a comment" })
      return
    }
    const assignedMember = isMentionedAssigned ? mentionedMember : undefined
    const createCommentArgs = {
      requests: answerOids.map((answerOid) => ({
        group_oid: activeGroupOid,
        text: comment,
        assignment: assignedMember
          ? {
              uid: assignedMember.uid,
              email: assignedMember.email,
            }
          : undefined,
        kind: "SHEET" as const,
        job_id: jobOid,
        response_oid: answerOid,
      })),
    }

    setIsSubmitting(true)
    try {
      await createComments(createCommentArgs)
      handleSuccess("Comments added successfully")
      setComment("")
      onClose()
    } catch (error) {
      handleError({ error, prefix: "Failed to add comments" })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="w-[400px]">
      <Mentions
        autoSize
        allowClear
        placeholder="Use @ to mention team members by email..."
        options={members?.map((member) => ({
          value: member.email,
          label: member.email,
        }))}
        value={comment}
        onChange={setComment}
        rows={4}
        loading={loading}
      />
      <div className="mt-4 flex items-center justify-between">
        <div>
          <Button onClick={onClose} className="mr-2">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!comment.trim() || isSubmitting}
          >
            Add Comment
          </Button>
        </div>
      </div>
      {mentionedMember && (
        <Checkbox
          key={mentionedMember.uid}
          checked={isMentionedAssigned}
          onChange={(e) => {
            setIsMentionedAssigned(e.target.checked)
          }}
          className="mt-4 text-xs"
        >
          Assign to {mentionedMember.email}
        </Checkbox>
      )}
    </div>
  )
}

interface BulkCommentButtonProps {
  jobOid: string
  answerOids: string[]
  small?: boolean
}

const BulkCommentButton: React.FC<BulkCommentButtonProps> = ({
  jobOid,
  answerOids,
  small,
}) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={
        <BulkCommentForm
          jobOid={jobOid}
          answerOids={answerOids}
          onClose={hide}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Tooltip title="Ask team members to edit, review, or discuss the selected responses">
        <Button
          icon={<AtSignIcon />}
          className="flex items-center justify-center"
          size={small ? "small" : "middle"}
          disabled={answerOids.length === 0}
        >
          Mention
        </Button>
      </Tooltip>
    </Popover>
  )
}
export default BulkCommentButton
