import Avatar from "antd/es/avatar"

import { EMPTY_IMG_SRC } from "../../constants"

const GroupImage: React.FC<{ imageUrl?: string; size?: number }> = ({
  imageUrl,
  size = 96,
}) => {
  return (
    <Avatar
      className="rounded-xl border p-2 shadow-md"
      size={size}
      src={imageUrl || EMPTY_IMG_SRC}
    />
  )
}

export default GroupImage
