import Skeleton from "antd/es/skeleton"
import Tabs from "antd/es/tabs"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router"

import BillingPage from "../../billing/BillingPage"
import EditGroupForm from "../../components/Groups/EditGroupForm"
import GroupImage from "../../components/Groups/GroupImage"
import Header from "../../components/Header"
import { useActiveGroup } from "../../contexts/useActiveGroup"
import IntegrationsManager from "../../integrations/IntegrationsManager"
import GroupReportingPage from "./GroupReportingPage"
import MembersTab from "./MembersTab"
import SSOSettingsForm from "./SSOSettingsForm"
import SettingsTab from "./SettingsTab"
import TagsTab from "./TagsTab"

const GroupSettingsPage: React.FC = () => {
  const { settingsPage: activeTabKey } = useParams()
  const navigate = useNavigate()
  const { authUser, activeGroup, activeGroupLoading, hasPerm } =
    useActiveGroup()

  const onTabChange = useCallback(
    (key: string) => navigate(`/group/settings/${key}`),
    [navigate],
  )

  let body: React.ReactNode
  if (activeGroupLoading || !activeGroup) {
    body = <Skeleton className="m-12 w-full" active loading />
  } else {
    const tabItems = [
      {
        label: "Basic Info",
        key: "info",
        children: <EditGroupForm group={activeGroup} />,
      },
      {
        label: "Advanced Settings",
        key: "advanced",
        children: <SettingsTab group={activeGroup} />,
      },
      {
        label: "Tags",
        key: "tags",
        children: <TagsTab activeGroup={activeGroup} hasPerm={hasPerm} />,
      },
      {
        label: "Members",
        key: "members",
        children: <MembersTab user={authUser} activeGroup={activeGroup} />,
      },
      {
        label: "Integrations",
        key: "integrations",
        children: <IntegrationsManager />,
      },
    ]
    if (hasPerm("admin")) {
      tabItems.push({
        label: "Reporting",
        key: "reporting",
        children: <GroupReportingPage />,
      })
      tabItems.push({
        label: "Billing",
        key: "billing",
        children: <BillingPage />,
      })
      if (activeGroup.domain_names?.length) {
        // Domain name verification is required for SSO for security.
        tabItems.push({
          label: "SSO",
          key: "sso",
          children: <SSOSettingsForm group={activeGroup} />,
        })
      }
    }

    const activeKey = tabItems.some((item) => item.key === activeTabKey)
      ? activeTabKey
      : "info"
    body = (
      <Tabs
        className="grow"
        activeKey={activeKey}
        onChange={onTabChange}
        type="card"
        items={tabItems}
      />
    )
  }

  return (
    <>
      <Header
        title={
          <div className="align-center flex flex-row items-center gap-2">
            <GroupImage imageUrl={activeGroup?.image_url} size={64} />
            <h3 className="m-0">
              Group Settings: {activeGroup?.name ?? "Loading..."}
            </h3>
          </div>
        }
      />
      <div className="flex grow flex-col p-4">{body}</div>
    </>
  )
}

export default GroupSettingsPage
