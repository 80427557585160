import { Button, Skeleton } from "antd"
import { Download, FileBarChart2 } from "lucide-react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import type {
  CustomTooltipProps,
  SimpleDataPoint,
  StackedDataPoint,
} from "./types"

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload?.length) {
    if (
      payload[0].dataKey === "questionsAsked" ||
      payload[0].dataKey === "questionsAnswered"
    ) {
      const data = payload[0].payload as StackedDataPoint
      return (
        <div className="rounded border border-gray-200 bg-white p-2 shadow-md">
          <p className="font-semibold">{`Week of ${label}`}</p>
          <p className="text-blue-600">{`Questions Asked: ${data.questionsAsked}`}</p>
          <p className="text-red-500">{`Questions Answered: ${data.questionsAnswered}`}</p>
        </div>
      )
    } else {
      return (
        <div className="rounded border border-gray-200 bg-white p-2 shadow-md">
          <p className="font-semibold">{`Week of ${label}`}</p>
          <p className="text-amber-600">{`Count: ${payload[0].value}`}</p>
        </div>
      )
    }
  }
  return null
}

interface TimelineChartProps {
  data?: SimpleDataPoint[] | StackedDataPoint[]
  title: string
  downloadFileName: string
  loading: boolean
  isStacked?: boolean
}

const TimelineChart: React.FC<TimelineChartProps> = ({
  data,
  title,
  downloadFileName,
  loading,
  isStacked = false,
}) => {
  const chartData: SimpleDataPoint[] | StackedDataPoint[] = data || []

  const maxValue: number = isStacked
    ? Math.max(
        ...(chartData as StackedDataPoint[]).map(
          (item) => item.questionsAsked || 0,
        ),
        0,
      )
    : Math.max(
        ...(chartData as SimpleDataPoint[]).map((item) => item.count || 0),
        0,
      )
  const yAxisMax: number = Math.ceil(maxValue * 1.1)

  const handleExport = (): void => {
    if (!chartData.length) {
      return
    }

    try {
      let headers: string[]
      let csvContent: string

      if (isStacked) {
        headers = ["Week", "Questions Asked", "Questions Answered"]
        csvContent = chartData
          .map((item) => {
            const typedItem = item as StackedDataPoint
            return `"${typedItem.weekStart}",${typedItem.questionsAsked},${typedItem.questionsAnswered}`
          })
          .join("\n")
      } else {
        headers = ["Week", "Count"]
        csvContent = chartData
          .map((item) => {
            const typedItem = item as SimpleDataPoint
            return `"${typedItem.weekStart}",${typedItem.count}`
          })
          .join("\n")
      }

      const csvData = `${headers.join(",")}\n${csvContent}`
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" })

      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", downloadFileName)
      document.body.appendChild(link)
      link.click()

      setTimeout(() => {
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }, 100)
    } catch (error) {
      console.error("Error exporting data:", error)
      alert("Failed to export data. Please try again.")
    }
  }
  return (
    <div className="my-2 w-full rounded-lg bg-white p-4 shadow-md">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-center text-xl font-bold">{title}</h2>
        <Button
          onClick={handleExport}
          icon={<Download size={18} />}
          className="flex items-center gap-1 text-gray-700 transition-colors hover:text-amber-600"
          aria-label="Export data"
        >
          <span>Export</span>
        </Button>
      </div>

      <div className="relative h-96 w-full">
        {loading ? (
          <div className="absolute inset-0 flex flex-col p-4">
            <Skeleton.Input active style={{ width: "100%", height: "320px" }} />
            <div className="mt-4">
              <Skeleton.Input active style={{ width: "60%" }} />
            </div>
          </div>
        ) : chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="weekStart"
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                interval={0}
                height={70}
              />
              <YAxis
                domain={[0, yAxisMax]}
                allowDecimals={false}
                tick={{ fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />

              {isStacked ? (
                <>
                  <Legend />
                  <Bar
                    dataKey="questionsAsked"
                    fill="#4285F4"
                    name="# Questions asked"
                    radius={[2, 2, 0, 0]}
                  />
                  <Bar
                    dataKey="questionsAnswered"
                    fill="#EA4335"
                    name="# Questions answered *"
                    radius={[2, 2, 0, 0]}
                  />
                </>
              ) : (
                <Bar
                  dataKey="count"
                  fill="#F59E0B"
                  name="Count"
                  radius={[2, 2, 0, 0]}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500">
            <FileBarChart2 size={48} />
            <p className="mt-2">No data available</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimelineChart
