import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import Layout from "antd/es/layout"
import Menu from "antd/es/menu"
import { MenuIcon } from "lucide-react"
import { useMemo } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Link, useLocation, useNavigate } from "react-router"
import { useLocalStorage } from "usehooks-ts"

import QuiltLogo from "../components/QuiltLogo"
import { TourProvider } from "../components/Tour/TourContext"
import { EMPTY_ARRAY } from "../constants"
import { ActiveUserAuthorizationProvider } from "../contexts/ActiveUserAuthorizationContext"
import { ActiveGroupProvider, useActiveGroup } from "../contexts/useActiveGroup"
import { TypesenseSearchProvider } from "../search/useTypesenseSearch"
import AppRoutes from "./AppRoutes"
import ErrorFallback from "./ErrorFallback"
import { getBottomNavItems, getNarrowNavItems, getNavItems } from "./NavMenus"
import SubscriptionExpiredBanner from "./SubscriptionExpiredBanner"
import "./TopLevelPageContainer.css"

const { Sider, Content } = Layout

const logError = (error: Error, info: unknown) => {
  console.error("Error boundary", error, info)
}

const TopLevelPageRouter: React.FC = () => {
  const [collapsed, setCollapsed] = useLocalStorage<boolean>(
    "quilt__sidebarCollapsed",
    false,
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { authUser, hasPerm, flags } = useActiveGroup()

  const [topNavItems, bottomNavItems, narrowNavItems] = useMemo(
    () => [
      getNavItems(authUser, navigate, flags, hasPerm),
      getBottomNavItems(authUser, navigate, flags, collapsed, setCollapsed),
      getNarrowNavItems(authUser, navigate, flags, hasPerm),
    ],
    [authUser, navigate, flags, hasPerm, setCollapsed, collapsed],
  )

  const cleanedPathname = pathname.startsWith("/")
    ? pathname.slice(1)
    : pathname
  const selectedPageKey = (
    topNavItems.find(
      (item) =>
        item.key &&
        (item.path === cleanedPathname || cleanedPathname.startsWith(item.key)),
    ) ?? topNavItems[0]
  ).key

  return (
    <Layout className="h-screen bg-white">
      <Sider
        width="250px"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        trigger={null}
        theme="light"
        className="!bg-gray-25 max-sm:hidden"
      >
        <div className="flex h-full flex-col">
          <Link to="/">
            <QuiltLogo
              className="mx-[24px] my-5"
              width={collapsed ? "26px" : "80px"}
              small={collapsed}
            />
          </Link>
          <Menu
            id="sidebar-main-menu"
            theme="light"
            mode="inline"
            selectedKeys={[selectedPageKey]}
            items={topNavItems}
            className="bg-gray-25 !border-none"
          />
          <Menu
            id="sidebar-bottom-menu"
            className="bg-gray-25 mt-auto"
            theme="light"
            mode="inline"
            selectedKeys={EMPTY_ARRAY}
            items={bottomNavItems}
          />
        </div>
      </Sider>
      <Layout className="flex h-screen overflow-y-hidden bg-white">
        <div className="flex items-center justify-between p-2 pb-0 sm:hidden">
          <QuiltLogo className="" width="80px" />
          <Dropdown menu={{ items: narrowNavItems }} trigger={["click"]}>
            <Button>
              <MenuIcon />
            </Button>
          </Dropdown>
        </div>
        <Content className="flex h-full flex-col overflow-auto">
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <TourProvider>
              <TypesenseSearchProvider>
                <AppRoutes flags={flags} />
              </TypesenseSearchProvider>
            </TourProvider>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}

const TopLevelPageContainer: React.FC = () => (
  <ActiveUserAuthorizationProvider>
    <ActiveGroupProvider>
      <TopLevelPageRouter />
      <SubscriptionExpiredBanner />
    </ActiveGroupProvider>
  </ActiveUserAuthorizationProvider>
)

export default TopLevelPageContainer
