import type { User as AuthUser } from "firebase/auth"
import { serverTimestamp } from "firebase/firestore"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { GROUPS_COLLECTION } from "../../types/common"
import type { AgentTable, AgentTableRow } from "./types"

const AGENT_TABLES_COLLECTION = "agent_tables"
const AGENT_TABLE_ROWS_COLLECTION = "agent_table_rows"

// Define a type for creating/updating that doesn't include the fields
// that are automatically managed
type Mutation = Omit<
  AgentTable,
  "oid" | "created_at" | "updated_at" | "created_by"
>

export const colRef = (groupOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    AGENT_TABLES_COLLECTION,
  ).withConverter(makeConverter<AgentTable>())

export const rowsColRef = (groupOid: string, tableOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    AGENT_TABLES_COLLECTION,
    tableOid,
    AGENT_TABLE_ROWS_COLLECTION,
  ).withConverter(makeConverter<AgentTableRow>())

// Returns the oid of the newly created agent table.
export const createAgentTable = async (
  authUser: AuthUser,
  groupOid: string,
  table: Mutation,
): Promise<string> => {
  const newTable = {
    ...table,
    oid: "",
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    created_by: {
      uid: authUser.uid,
      email: authUser.email!,
    },
  }

  // The converter will handle the oid field
  const result = await addDoc(colRef(groupOid), newTable)

  return result.id
}

export const updateAgentTable = async (
  authUser: AuthUser,
  groupOid: string,
  table: Mutation & { oid: string },
): Promise<void> => {
  const updates = {
    ...table,
    updated_at: serverTimestamp(),
  }

  await updateDoc(doc(colRef(groupOid), table.oid), updates)
}

export const deleteAgentTable = async (
  groupOid: string,
  tableOid: string,
): Promise<void> => {
  // First delete the rows.
  const rows = await getDocs(rowsColRef(groupOid, tableOid))
  for (const row of rows.docs) {
    await deleteDoc(row.ref)
  }

  // Then the table itself.
  await deleteDoc(doc(colRef(groupOid), tableOid))
}
