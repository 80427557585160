import {
  Button,
  Checkbox,
  Empty,
  Input,
  Popover,
  Radio,
  Select,
  Table,
  Tag,
  Typography,
} from "antd"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import type { ColumnsType } from "antd/es/table"
import { collection, limit, or, query, where } from "firebase/firestore"
import { FilterIcon, XCircleIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useSearchParams } from "react-router"

import { withHeader } from "../../components/Header"
import { dateColumnProps } from "../../components/table/dateColumn"
import useTableScroll from "../../components/table/useTableScroll"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type Discussion,
  type SheetDiscussion,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import type { KnowledgeItem } from "../../knowledge/types"
import {
  GROUPS_COLLECTION,
  USER_DOCUMENTS_COLLECTION,
} from "../../types/common"
import type { NonKnowledgeItemUserDocument } from "../../types/userDocument"

const { Text } = Typography
const { Search } = Input
const { Option } = Select

type TaskItemType =
  | "QUESTIONNAIRE_DISCUSSION"
  | "KNOWLEDGE_ITEM_DISCUSSION"
  | "USER_DOCUMENT_DISCUSSION"
  | "KNOWLEDGE_ITEM"
  | "USER_DOCUMENT"

type TaskItemFilterType = "questionnaire" | "knowledge" | "source"

interface TaskItem {
  oid: string
  type: TaskItemType
  filterType: TaskItemFilterType
  title: string
  status: "Done" | "Todo"
  date: FirebaseFirestore.Timestamp | null
  assignedBy?: string
  assignedAt?: FirebaseFirestore.Timestamp | null
  resolvedAt?: FirebaseFirestore.Timestamp | null
  jobId?: string
  discussionOid?: string
}

interface TaskFilterState {
  type: string | null
  status: string | null
  assignedBy: string | null
}

const DISCUSSION_KIND_TO_TASK_ITEM_TYPE: Record<
  Discussion["kind"],
  TaskItemType
> = {
  SHEET: "QUESTIONNAIRE_DISCUSSION",
  USER_DOCUMENT: "USER_DOCUMENT_DISCUSSION",
  KNOWLEDGE_ITEM: "KNOWLEDGE_ITEM_DISCUSSION",
}

const TASK_ITEM_TYPE_TO_FILTER_TYPE: Record<TaskItemType, TaskItemFilterType> =
  {
    QUESTIONNAIRE_DISCUSSION: "questionnaire",
    KNOWLEDGE_ITEM_DISCUSSION: "knowledge",
    USER_DOCUMENT_DISCUSSION: "source",
    KNOWLEDGE_ITEM: "knowledge",
    USER_DOCUMENT: "source",
  }

function useTaskFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterState = useMemo<TaskFilterState>(
    () => ({
      type: searchParams.get("type") || null,
      status: searchParams.get("status") || null,
      assignedBy: searchParams.get("assignedBy") || null,
    }),
    [searchParams],
  )

  const resetFilters = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  const setFilterValue = useCallback(
    (key: keyof TaskFilterState, value: string | null) => {
      const newParams = new URLSearchParams(searchParams)
      if (value === null) {
        newParams.delete(key)
      } else {
        newParams.set(key, value)
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams],
  )

  const activeFilterCount = Object.values(filterState).filter(
    (value) => value !== null,
  ).length

  return { filterState, resetFilters, setFilterValue, activeFilterCount }
}

const TaskFiltersPopover: React.FC<{
  taskItems: TaskItem[]
  filterState: TaskFilterState
  setFilterValue: (key: keyof TaskFilterState, value: string | null) => void
  resetFilters: () => void
  activeFilterCount: number
}> = ({
  taskItems,
  filterState,
  setFilterValue,
  resetFilters,
  activeFilterCount,
}) => {
  const assignedByOptions = useMemo(() => {
    const assignedBySet = new Set<string>()
    for (const task of taskItems) {
      if (task.assignedBy) {
        assignedBySet.add(task.assignedBy)
      }
    }
    return Array.from(assignedBySet)
  }, [taskItems])

  const typeOptions: { label: string; value: TaskItemFilterType | null }[] =
    useMemo(
      () => [
        { label: "All", value: null },
        { label: "Questionnaire", value: "questionnaire" },
        { label: "Answer Bank", value: "knowledge" },
        { label: "Source Document", value: "source" },
      ],
      [],
    )

  const content = (
    <div className="flex w-64 flex-col gap-4">
      <div>
        <Text strong>Type</Text>
        <Radio.Group
          className="mt-1 flex flex-col"
          value={filterState.type === null ? null : filterState.type}
          onChange={(e) => {
            setFilterValue("type", e.target.value as string | null)
          }}
          options={typeOptions}
        />
      </div>

      <div>
        <Checkbox
          checked={filterState.status === "All"}
          onChange={(e: CheckboxChangeEvent) => {
            setFilterValue("status", e.target.checked ? "All" : null)
          }}
        >
          Include Done Tasks
        </Checkbox>
      </div>

      <div>
        <Text strong>Assigned By</Text>
        <Select
          className="mt-1 w-full"
          placeholder="Select person"
          value={filterState.assignedBy || null}
          onChange={(value) => setFilterValue("assignedBy", value || null)}
          allowClear
        >
          {assignedByOptions.map((person) => (
            <Option key={person} value={person}>
              {person}
            </Option>
          ))}
        </Select>
      </div>

      <Button type="primary" onClick={resetFilters}>
        Reset Filters
      </Button>
    </div>
  )

  return (
    <Popover
      content={content}
      title="Filter Tasks"
      trigger="click"
      placement="bottomRight"
    >
      <Button
        type={activeFilterCount > 0 ? "primary" : "default"}
        icon={<FilterIcon size={16} />}
      >
        {activeFilterCount > 0 ? `Filters (${activeFilterCount})` : "Filters"}
      </Button>
    </Popover>
  )
}

const DiscussionsComponent: React.FC = () => {
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [searchTerm, setSearchTerm] = useState("")
  const { filterState, setFilterValue, resetFilters, activeFilterCount } =
    useTaskFilters()

  const [assignedDiscussions, assignedDiscussionsLoading] = useCollectionData(
    query(
      collection(
        db,
        GROUPS_COLLECTION,
        activeGroupOid,
        DISCUSSION_COLLECTION,
      ).withConverter(makeConverter<SheetDiscussion>()),
      where("assignment.uid", "==", authUser.uid),
      limit(500),
    ),
  )

  const [assignedUserDocuments, assignedUserDocumentsLoading] =
    useCollectionData(
      query(
        collection(db, USER_DOCUMENTS_COLLECTION).withConverter(
          makeConverter<NonKnowledgeItemUserDocument | KnowledgeItem>(),
        ),
        or(
          where("last_assigned_to.uid", "==", authUser.uid),
          where("last_reviewed_by.uid", "==", authUser.uid),
        ),
        limit(500),
      ),
    )
  const [tableContainerRef, tableScrollProp] = useTableScroll()

  const taskItems: TaskItem[] = useMemo(() => {
    return [
      ...(assignedDiscussions ?? []).map((discussion) => ({
        oid: discussion.oid,
        type: DISCUSSION_KIND_TO_TASK_ITEM_TYPE[discussion.kind],
        filterType:
          TASK_ITEM_TYPE_TO_FILTER_TYPE[
            DISCUSSION_KIND_TO_TASK_ITEM_TYPE[discussion.kind]
          ],
        title: discussion.assignment?.message ?? "Discussion",
        status: discussion.resolution ? ("Done" as const) : ("Todo" as const),
        date: discussion.created_at,
        assignedBy: discussion.author_email,
        assignedAt: discussion.assignment?.timestamp,
        resolvedAt: discussion.resolution?.timestamp,
        kind: discussion.kind,
        jobId: discussion.job_id,
        location: discussion.location,
      })),
      ...(assignedUserDocuments ?? []).map((item) => ({
        oid: item.oid,
        type: item.is_knowledge_item
          ? ("KNOWLEDGE_ITEM" as const)
          : ("USER_DOCUMENT" as const),
        filterType: item.is_knowledge_item
          ? ("knowledge" as const)
          : ("source" as const),
        title: item.is_knowledge_item
          ? item.content.question.primary
          : (item.title ?? "Untitled Source Document"),
        status: item.last_reviewed_by ? ("Done" as const) : ("Todo" as const),
        date: item.last_reviewed_at || item.created_at,
        assignedBy: item.last_assigned_by?.email,
        assignedAt: item.last_assigned_at,
        resolvedAt: item.last_reviewed_at,
      })),
    ]
  }, [assignedDiscussions, assignedUserDocuments])

  const filteredTaskItems = useMemo(() => {
    let filtered = taskItems

    if (searchTerm) {
      const lowercaseSearchTerm = searchTerm.toLowerCase()
      filtered = filtered.filter(
        (item) =>
          item.title.toLowerCase().includes(lowercaseSearchTerm) ||
          item.assignedBy?.toLowerCase().includes(lowercaseSearchTerm),
      )
    }

    if (filterState.type) {
      filtered = filtered.filter((item) => item.filterType === filterState.type)
    }

    // Status filter: if not "All", show only Todo items
    if (filterState.status !== "All") {
      filtered = filtered.filter((item) => item.status === "Todo")
    }
    // If status is "All", we don't filter by status at all

    if (filterState.assignedBy) {
      filtered = filtered.filter(
        (item) => item.assignedBy === filterState.assignedBy,
      )
    }

    return filtered
  }, [taskItems, searchTerm, filterState])

  const loading = assignedDiscussionsLoading || assignedUserDocumentsLoading

  const getItemUrl = (item: TaskItem) => {
    if (item.type === "QUESTIONNAIRE_DISCUSSION") {
      return `/questionnaire-assistant/review/feed/${item.jobId}?discussionAssignedTo=${authUser.uid}`
    } else if (item.type === "USER_DOCUMENT_DISCUSSION") {
      return `/source-documents/${item.oid}?discussion_oid=${item.oid}`
    } else if (item.type === "KNOWLEDGE_ITEM_DISCUSSION") {
      return `/knowledge-items/${item.oid}?discussion_oid=${item.oid}`
    } else if (item.type === "KNOWLEDGE_ITEM") {
      return `/knowledge-items/${item.oid}`
    } else if (item.type === "USER_DOCUMENT") {
      return `/source-documents/${item.oid}`
    }
    return "#"
  }

  const columns: ColumnsType<TaskItem> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (title: string, record) => (
        <div>
          <a
            className="text-purple-800 hover:underline"
            href={getItemUrl(record)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "filterType",
      key: "filterType",
      render: (filterType: TaskItemFilterType) => (
        <Tag
          color={
            filterType === "questionnaire"
              ? "green"
              : filterType === "knowledge"
                ? "blue"
                : "orange"
          }
        >
          {filterType === "questionnaire"
            ? "Questionnaire"
            : filterType === "knowledge"
              ? "Answer Bank"
              : "Source Document"}
        </Tag>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: TaskItem["status"]) => (
        <Tag color={status === "Done" ? "green" : "orange"}>{status}</Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 120,
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      key: "assignedBy",
      sorter: (a, b) => (a.assignedBy ?? "").localeCompare(b.assignedBy ?? ""),
      width: 180,
    },
    dateColumnProps({
      title: "Date Added",
      dataIndex: "assignedAt",
      width: 160,
      sortable: true,
    }),
  ]

  return (
    <div className="flex grow flex-col overflow-y-hidden p-8">
      <div className="mb-4 flex gap-2">
        <Search
          placeholder="Search by title or assigned by..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full flex-grow"
        />
        <TaskFiltersPopover
          taskItems={taskItems}
          filterState={filterState}
          setFilterValue={setFilterValue}
          resetFilters={resetFilters}
          activeFilterCount={activeFilterCount}
        />
      </div>

      <div className="mb-2 flex items-center justify-start gap-2 font-bold text-gray-600">
        {loading ? (
          <div className="leading-7">&nbsp;</div>
        ) : (
          <div className="leading-7">
            Showing {filteredTaskItems.length.toLocaleString()} of{" "}
            {taskItems.length.toLocaleString()} tasks
          </div>
        )}
        {activeFilterCount > 0 && (
          <Button
            size="small"
            type="link"
            onClick={resetFilters}
            icon={<XCircleIcon size={14} />}
          >
            Clear filters
          </Button>
        )}
      </div>

      <Table
        ref={tableContainerRef}
        scroll={tableScrollProp}
        virtual
        className="min-w-[700px] grow overflow-y-hidden"
        size="small"
        bordered
        dataSource={filteredTaskItems}
        columns={columns}
        rowKey="oid"
        loading={loading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              description={
                searchTerm || activeFilterCount > 0
                  ? "No tasks match your search or filters"
                  : "No Todo tasks"
              }
            />
          ),
        }}
      />
    </div>
  )
}

export default withHeader({
  title: "Assigned Tasks",
  subtitle: "Tasks that have been assigned to you.",
})(DiscussionsComponent)
