import type { PickOverUnion } from "../types/typeHelpers"

export const SOURCES_COLLECTION = "sources"
export const SYNC_NODES_SUBCOLLECTION = "sync_nodes"

export interface SyncNode {
  oid: string
  external_id: string
  parent_external_id: string | null
  title: string | null
  file_last_modified: string | null
  uri: string | null
  is_folder: boolean
  is_file: boolean
  path: string[] | null
  sync_selection: "INCLUDE" | "EXCLUDE" | null
  sync_last_modified_by: {
    uid: string
    email: string
  } | null
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
}

interface SourceBase {
  oid: string
  active: boolean

  sync_end: FirebaseFirestore.Timestamp | null
  last_synced_at: FirebaseFirestore.Timestamp | null

  removal_requested: boolean

  external_id: string

  group_oid: string
  creator_uid?: string | null
  creator_email?: string | null
  credential_oid?: string | null

  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  nodes_synced_at?: FirebaseFirestore.Timestamp | null
}

export interface GongSource extends SourceBase {
  kind: "GONG"
  source_meta: {
    start_time: FirebaseFirestore.Timestamp | Date
    filter_spec: GongFilterSpec
  }
}

export interface GoogleDriveSource extends SourceBase {
  kind: "GOOGLE_DRIVE"
  source_meta: object
}

export interface NotionSource extends SourceBase {
  kind: "NOTION"
  source_meta: object
}

export interface ConfluenceSource extends SourceBase {
  kind: "CONFLUENCE"
  source_meta: object
}

interface ThreadTsPredicate {
  field: "thread_ts"
  operator: ">="
  value: FirebaseFirestore.Timestamp | Date
}

export interface SlackThreadUser {
  id: string
  name: string
}

export interface SourceSlackChannel {
  channel_id: string
  name: string
}

interface ThreadParticipantIdPredicate {
  field: "thread_user_id"
  operator: "in"
  value: string[]
}

interface SlackFilters {
  start_time?: ThreadTsPredicate | null
  participants?: ThreadParticipantIdPredicate | null
}

export interface SlackSource extends SourceBase {
  kind: "SLACK"
  source_meta: {
    channels: SourceSlackChannel[]

    default_filters: SlackFilters
  }
}

export type Source =
  | GongSource
  | SlackSource
  | NotionSource
  | ConfluenceSource
  | GoogleDriveSource

export type SourceUpdateOrCreateRequest = PickOverUnion<
  Source,
  "kind" | "source_meta"
>

interface FieldPredicateBase {
  AND?: undefined
  OR?: undefined
}

interface GongStartDatePredicate extends FieldPredicateBase {
  field: "started"
  operator: ">="
  value: FirebaseFirestore.Timestamp | Date
}

interface GongUserIdsPredicate extends FieldPredicateBase {
  field: "primaryUserId"
  operator: "in"
  value: string[]
}

interface GongNamePredicate extends FieldPredicateBase {
  field: "title"
  operator: "contains"
  value: string
}

interface GongAndFilter {
  AND: GongFilterPredicate[]
  OR?: undefined
}
interface GongOrFilter {
  AND?: undefined
  OR: GongFilterPredicate[]
}

export type GongFilterPredicate =
  | GongStartDatePredicate
  | GongUserIdsPredicate
  | GongNamePredicate

export type GongFilterSpec = GongAndFilter | GongOrFilter | GongFilterPredicate

export interface GetNodesRequest {
  sourceId: string
  cursor?: string
  parentId: string | null
  limit?: number
}

export interface GetNodesResponse {
  items: SyncNode[]
  nextCursor: string | null
}

export type SyncNodeSerialized = Omit<SyncNode, "created_at" | "updated_at"> & {
  created_at: { _seconds: number; _nanoseconds: number }
  updated_at: { _seconds: number; _nanoseconds: number }
}

export interface GetNodesSerializedResponse {
  items: SyncNodeSerialized[]
  nextCursor: string | null
}
