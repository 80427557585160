import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import type { CellClassParams, ColDef } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-balham.css"
import Radio from "antd/es/radio"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { read as xlsxRead } from "xlsx"

import LoadingSpinner from "../../components/LoadingSpinner"
import { focusCell } from "../../components/QuestionnaireReview/focusCell"
import type { SheetRangeDocumentReference } from "../../documents/types"
import { getErrorMessage } from "../../errors"
import { getNonemptyCols } from "../../sheets/formatGrid"
import { getWorksheetJsonData } from "../../sheets/sheetsJsHelpers"
import { cellNames, parseWorksheetRange } from "../../sheets/utils"
import { MimeType } from "../../types/mimetype"
import { sortStringsByLength } from "../../utils"

interface RowData {
  [key: string]: string | number
}

interface SheetData {
  name: string
  data: RowData[]
}

const SheetsViewer: React.FC<{
  url: string
  previewMimetype: MimeType.XLS | MimeType.XLSX | MimeType.XLSM | MimeType.CSV
  referenceLocation?: SheetRangeDocumentReference
}> = ({ url, previewMimetype, referenceLocation }) => {
  const [sheetsData, setSheetsData] = useState<SheetData[]>([])
  const [error, setError] = useState<React.ReactNode>()
  const [loading, setLoading] = useState<boolean>(true)
  const gridRef = useRef<AgGridReact<RowData>>(null)
  const [selectedSheet, setSelectedSheet] = useState<string>("")

  const grid = sheetsData.find((sheet) => sheet.name === selectedSheet)?.data

  const parsedLocations = useMemo(
    () => referenceLocation?.location.map(parseWorksheetRange),
    [referenceLocation],
  )

  const highlightLocations = useMemo(() => {
    const result = new Set<string>()
    if (!referenceLocation) return result
    for (const location of referenceLocation.location) {
      const parsedLocation = parseWorksheetRange(location)
      if (parsedLocation?.sheetName !== selectedSheet) continue
      for (const cellName of cellNames(parsedLocation)) {
        result.add(cellName)
      }
    }
    return result
  }, [referenceLocation, selectedSheet])

  const shouldHighlight = useCallback(
    (rowIndex: number, columnId: string) => {
      // need to add 1 to rowIndex because ag-grid is 0-indexed
      return highlightLocations.has(`${columnId}${rowIndex + 1}`)
    },
    [highlightLocations],
  )

  const columnKeys = useMemo(
    () => {
      if (!grid) return []
      // Sort so that columns are in the right order.
      return getNonemptyCols(grid).sort(sortStringsByLength)
    },
    // Do not recompute columnKeys when grid changes, since it is only used to compute columnDefs
    // However, it does depend on sheet.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSheet],
  )

  const columnDefs: ColDef[] = useMemo(() => {
    const selectedSheetData = sheetsData.find(
      (sheet) => sheet.name === selectedSheet,
    )
    if (!selectedSheetData || selectedSheetData.data.length === 0) return []
    return [
      {
        headerName: "",
        valueGetter: ({ node }) => (node?.rowIndex ?? 0) + 1,
        minWidth: 48,
        maxWidth: 48,
        width: 48,
        pinned: "left",
        sortable: false,
        rowDrag: false,
        filter: false,
        cellClass: "select-none",
      },
      ...columnKeys.map((key) => ({
        headerName: key,
        field: key,
        wrapText: true,
        autoHeight: true,
        editable: false,
        sortable: false,
        cellDataType: "text",
        cellClassRules: {
          "bg-yellow-25 hover:bg-yellow-50 hover:border-yellow-600 !border-yellow-500 cursor-pointer":
            (props: CellClassParams) =>
              shouldHighlight(props.rowIndex, props.column.getColId()),
        },
      })),
    ]
  }, [sheetsData, selectedSheet, shouldHighlight, columnKeys])

  useEffect(() => {
    const fetchAndProcessSheet = async () => {
      try {
        const response = await fetch(url)
        const arrayBuffer = await response.arrayBuffer()
        const workbook = xlsxRead(arrayBuffer, {
          cellFormula: false,
          cellHTML: true,
        })
        const allSheetsData: SheetData[] = workbook.SheetNames.filter(
          (s) =>
            !workbook.Workbook?.Sheets?.find((ws) => ws.name === s)?.Hidden,
        ).map((sheetName) => ({
          name: sheetName,
          data: getWorksheetJsonData<RowData>(workbook, sheetName),
        }))
        setSheetsData(allSheetsData)

        const selectedSheetName =
          parsedLocations?.find(
            (location) =>
              location?.sheetName &&
              workbook.SheetNames.includes(location.sheetName),
          )?.sheetName ?? workbook.SheetNames[0]
        setSelectedSheet(selectedSheetName)
      } catch (error) {
        setError(
          getErrorMessage({ error, prefix: "Couldn't load the document" }),
        )
      } finally {
        setLoading(false)
      }
    }
    void fetchAndProcessSheet()
  }, [url, parsedLocations])

  useEffect(() => {
    if (!gridRef.current?.api) return
    const range = parsedLocations?.find(
      (location) => location?.sheetName === selectedSheet,
    )
    if (!range) return
    const { firstColIndex, firstRowIndex } = range
    focusCell(firstRowIndex, firstColIndex, gridRef.current.api)
  }, [parsedLocations, selectedSheet])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>
  }

  if (!sheetsData.length) {
    return <div className="text-center">No data to display.</div>
  }

  return (
    <div className="relative flex w-full flex-1 grow flex-col overflow-auto p-2">
      <AgGridReact
        ref={gridRef}
        className="quilt-questionnaire-editor ag-theme-balham"
        rowData={grid}
        columnDefs={columnDefs}
        enableCellTextSelection
        modules={[ClientSideRowModelModule]}
        suppressMovableColumns
      />
      {previewMimetype === MimeType.CSV ? null : (
        <Radio.Group
          value={selectedSheet}
          onChange={(e) => setSelectedSheet(String(e.target.value))}
          options={sheetsData.map((sheet) => sheet.name)}
          optionType="button"
          buttonStyle="solid"
          className="sheet-pages-selector"
        />
      )}
    </div>
  )
}

export default SheetsViewer
