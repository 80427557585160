import { useCallback } from "react"
import { useNavigate, useParams } from "react-router"

import { type ChatWithSummarySessionKind } from "../../chat/types"
import { randomId } from "../../utils"
import { ChatSessionsContext, useSessionProviderImpl } from "./sessionsProvider"

// Create a stable global value so we use the same default on rerender.
// TODO(mgraczyk): Remove and replace with a conspicuous value.
let initialRandomSessionId = randomId()

export const UrlPathParamChatSessionsProvider: React.FC<{
  sourceKind: ChatWithSummarySessionKind
  children: React.ReactNode
}> = ({ sourceKind, children }) => {
  const { sessionOid = initialRandomSessionId } = useParams<{
    sessionOid: string
  }>()
  const navigate = useNavigate()
  const setSelectedSessionId = useCallback(
    (newSessionOid: string) => {
      void navigate(`/chat/${newSessionOid}`)

      // Reset so that we get a new session when we navigate back.
      initialRandomSessionId = randomId()
    },
    [navigate],
  )

  const value = useSessionProviderImpl(
    sourceKind,
    sessionOid,
    setSelectedSessionId,
  )

  return (
    <ChatSessionsContext.Provider value={value}>
      {children}
    </ChatSessionsContext.Provider>
  )
}
