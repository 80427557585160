import { useMemo } from "react"

import { EMPTY_ARRAY } from "../../constants"
import type { SheetDiscussion } from "../../discussions/types"
import type { StoredGeneratedAnsweredQuestion } from "../../types/jobs"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import type { WorksheetRange } from "../../types/sheets"
import { getOrAppendArrayMap } from "../../utils"
import QuestionnaireReviewFeed from "./QuestionnaireReviewFeed"
import type { AnswerWithDiscussion } from "./types"

const locationToKey = (location: WorksheetRange): string => {
  return `${location.sheetName}-${location.firstRowIndex}-${location.firstColIndex}`
}

interface Props {
  url: string
  job: AnswerQuestionnaireJob
  answers: StoredGeneratedAnsweredQuestion[]
  discussions: SheetDiscussion[]
  discussionsError: Error | undefined
}

const QuestionnaireReviewFeedWrapper: React.FC<Props> = ({
  job,
  answers,
  discussions,
  discussionsError,
}) => {
  const answersWithDiscussions = useMemo((): AnswerWithDiscussion[] => {
    if (!discussions) return answers

    const discussionMap = new Map<string, SheetDiscussion[]>()
    for (const discussion of discussions) {
      if (discussion.location) {
        getOrAppendArrayMap(
          discussionMap,
          locationToKey(discussion.location),
          discussion,
        )
      }
      if (discussion.response_oid) {
        getOrAppendArrayMap(discussionMap, discussion.response_oid, discussion)
      }
    }

    return answers
      .sort(
        (b, a) =>
          (b.primary_question.location?.firstRowIndex ?? 0) -
          (a.primary_question.location?.firstRowIndex ?? 0),
      )
      .map((answer) => {
        const location = answer.primary_answer.location
        const relatedDiscussions =
          discussionMap.get(answer.oid) ??
          (location
            ? discussionMap.get(locationToKey(location))
            : EMPTY_ARRAY) ??
          EMPTY_ARRAY

        return {
          ...answer,
          discussions: relatedDiscussions,
        }
      })
  }, [answers, discussions])

  return (
    <QuestionnaireReviewFeed
      answers={answersWithDiscussions}
      job={job}
      discussionsError={discussionsError}
    />
  )
}

export default QuestionnaireReviewFeedWrapper
