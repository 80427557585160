import { httpsCallable } from "../serverUtils"
import type {
  CommentCreationManyRequest,
  CommentCreationRequest,
  CommentDeleteRequest,
  DiscussionDeleteRequest,
  DiscussionResolveManyRequest,
} from "./types"

export const createComment =
  httpsCallable<CommentCreationRequest>("createComment")

export const createComments =
  httpsCallable<CommentCreationManyRequest>("createComments")

export const deleteDiscussion =
  httpsCallable<DiscussionDeleteRequest>("deleteDiscussion")

export const deleteComment =
  httpsCallable<CommentDeleteRequest>("deleteComment")

export const resolveDiscussions =
  httpsCallable<DiscussionResolveManyRequest>("resolveDiscussions")
