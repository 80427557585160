import { getUserClaims } from "../auth/utils"
import { ANALYTICS_PRODUCT } from "../constants"
import { auth } from "../firebaseApp"
import { createAnalyticsEvents } from "./api"
import type {
  AnalyticsEventArgs,
  AnalyticsEventCreationRequest,
} from "./types.js"

const _PENDING_EVENTS: AnalyticsEventCreationRequest[] = []

const postEventsToServer = async (): Promise<void> => {
  const events = _PENDING_EVENTS.splice(0, _PENDING_EVENTS.length)
  if (events.length === 0) {
    return
  }
  if (auth.currentUser) {
    const token = await getUserClaims(auth.currentUser)
    if (token.impersonatorEmail) {
      console.debug("Skipping analytics because user is impersonated", events)
      return
    }
  }

  await createAnalyticsEvents({ events })
}

function getCookieValue(name: string): string | undefined {
  const cookie = document.cookie.split("; ").find((cookie) => {
    return cookie.startsWith(`${name}=`)
  })
  if (cookie) {
    return cookie.split("=")[1]
  }
  return undefined
}

const getFbc = (): string | undefined => {
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
  // First get from the url, then cookie.
  const urlParams = new URLSearchParams(window.location.search)
  const fbcUrlParam = urlParams.get("fbclid")
  if (fbcUrlParam) {
    return fbcUrlParam
  }
  return getCookieValue("_fbc")
}

const getTransientData = (
  e: AnalyticsEventArgs,
): AnalyticsEventCreationRequest["transient_data"] => ({
  // PAGEVIEW events fire before the location changes.
  // TODO(mgraczyk): Remove sensitive info (token) from url?
  url: e.event_type === "PAGEVIEW" ? e.event_data.url : window.location.href,
  locale: navigator.language,
  referrer: document.referrer,
  user_agent: navigator.userAgent,
  fbp: getCookieValue("_fbp"),
  fbc: getFbc(),
  ga: getCookieValue("_ga"),
  cioanonid: getCookieValue("_cioanonid"),
  ajs_anonymous_id: getCookieValue("ajs_anonymous_id"),
  analytics_session_id: getCookieValue("analytics_session_id"),
})

const sendAnalyticsEvent = (analyticsEventArgs: AnalyticsEventArgs): void => {
  if (import.meta.env.VITE_ANALYTICS_DISABLED) {
    console.debug(
      "Skipping analytics because analytics disabled",
      analyticsEventArgs,
    )
    return
  }

  _PENDING_EVENTS.push({
    ...analyticsEventArgs,
    product: ANALYTICS_PRODUCT,
    client_timestamp: new Date().getTime() * 1000,
    transient_data: getTransientData(analyticsEventArgs),
  })
  setTimeout(postEventsToServer, 2000)
}

export { sendAnalyticsEvent }
