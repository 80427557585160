/*
A page on the frontend that allows the user to set the QuestionAnswerLayout for multiple user documents.

You should take the document oid's from a URL query parameter as a comma-separated list.
The page should use the existing hooks and components:
  * Fetch the UserDocuments with useDocumentData
  * QuestionnaireWorkflowWizard to render the questionnaire layout form (with isCompletedQuestionnaire true)
  * Then `handleDocumentProcessing` should call `setQuestionAnswerLayout` for each document
*/
import { collection, query, where } from "@firebase/firestore"
import { getBytes, ref as storageRef } from "@firebase/storage"
import { Alert, Skeleton } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useNavigate, useSearchParams } from "react-router"

import Header from "../components/Header"
import NotFoundPage from "../components/NotFoundPage"
import QuestionnaireWorkflowWizard from "../components/QuestionnaireWorkflowWizard"
import { fileDataToWizardDocument } from "../components/QuestionnaireWorkflowWizard"
import type {
  AnyDocument,
  FileDocument,
} from "../components/QuestionnaireWorkflowWizard/state"
import { getGoogleSheetsDocuments } from "../components/QuestionnaireWorkflowWizard/useIndexableGoogleDriveDocument"
import { getQuestionAnswerLayouts } from "../components/QuestionnaireWorkflowWizard/utils"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { setQuestionAnswerLayout } from "../documents/api"
import { firebaseStorage } from "../firebaseApp"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import useGoogleAccessToken from "../hooks/useGoogleAccessToken"
import { USER_DOCUMENTS_COLLECTION } from "../types/common"
import type { IndexableDocument } from "../types/common"
import { MimeType } from "../types/mimetype"
import type { UserDocument } from "../types/userDocument"

// Use global to avoid recreating array on rerender.
const REQUIRED_SCOPES = ["https://www.googleapis.com/auth/drive.readonly"]

const getUploadedDocument = async (
  doc: UserDocument,
): Promise<FileDocument> => {
  const path = doc.indexable_file_uri
  if (!path) {
    throw new Error("No indexable file uri found for document")
  }

  if (!doc.mimetype) {
    throw new Error("No mimetype found for document")
  }

  const objectRef = storageRef(firebaseStorage, path)
  const fileContents = await getBytes(objectRef)
  const fileName = doc.title ?? ""
  const fileType = doc.mimetype

  return fileDataToWizardDocument(
    {
      oid: doc.oid,
      fileContents,
      fileType,
      fileName,
    },
    true,
  )
}

const SetQuestionAnswerLayoutPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const docOidsParam = searchParams.get("docOids")
  const docOids = useMemo(() => docOidsParam?.split(",") || [], [docOidsParam])
  const { handleError } = useErrorPopup()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [processingError, setProcessingError] = useState<Error | null>(null)
  const [documents, setDocuments] = useState<AnyDocument[]>([])
  const { authUser } = useActiveUserAuthorizationFromContext()

  const { getAccessToken } = useGoogleAccessToken(
    authUser,
    REQUIRED_SCOPES,
    "USER",
  )

  // Fetch all user documents when the page loads
  const [userDocs, userDocsLoading, userDocsError] = useCollectionData(
    docOids.length === 0
      ? undefined
      : query(
          collection(db, USER_DOCUMENTS_COLLECTION),
          where("__name__", "in", docOids),
        ).withConverter(makeConverter<UserDocument>()),
  )

  // Load document data when we have user docs.
  useEffect(() => {
    if (!userDocs || userDocs.length === 0) {
      return
    }
    const loadDocuments = async () => {
      setLoading(true)
      try {
        if (!userDocs.every((doc) => doc.is_completed_questionnaire)) {
          throw new Error("All documents must be completed questionnaires")
        }

        // Collect all valid documents for processing
        const indexableDocuments: IndexableDocument[] = userDocs.map(
          (doc) =>
            ({
              oid: doc.oid,
              source_kind: doc.source_kind,
              url: doc.document_url,
              title: doc.title ?? "",
              external_document_id: doc.external_document_id,
              mimetype: doc.mimetype,
              // TODO(mgraczyk): Fix type and remove this cast.
            }) as IndexableDocument,
        )

        const fromGoogleDrive: IndexableDocument[] = userDocs
          .filter((doc) => doc.source_kind === "GOOGLE_DRIVE")
          .map((doc) => ({
            kind: "GOOGLE_DRIVE" as const,
            oid: doc.oid,
            source_kind: "GOOGLE_DRIVE" as const,
            url: doc.document_url,
            title: doc.title ?? "",
            external_document_id: doc.external_document_id,
            mimetype: doc.mimetype ?? MimeType.UNKNOWN,
          }))
        const fromFileUpload = userDocs.filter(
          (doc) => doc.source_kind === "FILEUPLOAD",
        )

        // TODO(mgraczyk): Consolidate loading user documents.
        let documents: AnyDocument[] = []
        if (fromGoogleDrive.length > 0) {
          // Get Google access token
          const googleAccessToken = await getAccessToken()
          const googleDriveDocuments = await getGoogleSheetsDocuments(
            googleAccessToken,
            fromGoogleDrive,
          )
          documents = [...documents, ...googleDriveDocuments]

          // Open the modal once the documents are processed
          setProcessingError(null)
        }
        if (fromFileUpload.length > 0) {
          const uploadedDocuments = await Promise.all(
            fromFileUpload.map(getUploadedDocument),
          )
          documents = [...documents, ...uploadedDocuments]
        }

        if (documents.length !== indexableDocuments.length) {
          throw new Error("Some documents could not be processed")
        }

        setDocuments(documents)
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Failed to process documents"
        setProcessingError(new Error(errorMessage))
        handleError({ error, prefix: "Failed to process documents" })
      } finally {
        setLoading(false)
      }
    }

    void loadDocuments()
  }, [userDocs, getAccessToken, handleError])

  // Handle setting the QuestionAnswerLayout for all documents
  const handleDocumentProcessing = useCallback(
    async (document: AnyDocument) => {
      if (!document.oid) {
        throw new Error("Document oid is required")
      }

      // TODO(mgraczyk): Get the oid from the document.
      const layout = getQuestionAnswerLayouts(document)
      await setQuestionAnswerLayout(document.oid, layout)
    },
    [],
  )

  // Render loading state
  if (loading || userDocsLoading) {
    return (
      <>
        <Header
          title="Set Question Answer Layout"
          breadcrumbs={[
            { title: "Documents", href: "/source-documents" },
            { title: "Set Layout", href: "" },
          ]}
        />
        <div className="flex flex-col justify-center p-6">
          <h2>Loading documents...</h2>
          <Skeleton active loading />
        </div>
      </>
    )
  }

  // Check if we have valid docOids
  const hasValidParams = docOids.length > 0

  // Render not found state
  if (userDocs?.length === 0 || !hasValidParams) {
    return (
      <>
        <Header
          title="Documents Not Found"
          breadcrumbs={[
            { title: "Documents", href: "/source-documents" },
            { title: "Set Layout", href: "" },
          ]}
        />
        <NotFoundPage />
      </>
    )
  }

  const anyError = userDocsError ?? processingError

  return (
    <>
      <Header
        title="Set Question Answer Layout"
        breadcrumbs={[
          { title: "Documents", href: "/source-documents" },
          { title: "Set Layout", href: "" },
        ]}
      />

      <div className="p-8">
        <div className="mb-4">
          <h2 className="text-xl font-medium">
            Configure Question-Answer Layout
          </h2>
          <p className="text-gray-600">
            Map the fields in your documents to questions and answers.
          </p>
        </div>

        {anyError && (
          <Alert
            message="Error"
            description={anyError.message}
            type="error"
            showIcon
            className="mb-4"
            closable
            onClose={() => setProcessingError(null)}
          />
        )}

        {documents.length > 0 && (
          <QuestionnaireWorkflowWizard
            documents={documents}
            onClose={() => {
              setDocuments([])
              setProcessingError(null)
              void navigate("/source-documents")
              // TODO(mgraczyk): Navigate to source documents page or elsewhere.
            }}
            handleDocumentProcessing={handleDocumentProcessing}
            isCompletedQuestionnaire={true}
          />
        )}
      </div>
    </>
  )
}

export default SetQuestionAnswerLayoutPage
