import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Popover from "antd/es/popover"
import { DownloadIcon } from "lucide-react"
import { useState } from "react"

import type { Group, GroupMembership } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"

const DownloadMembersForm: React.FC<{
  group: Group
  members: GroupMembership[] | undefined
  membersLoading: boolean
}> = ({ group, members, membersLoading }) => {
  // Form to select whether or not to include Role.
  const [includeRole, setIncludeRole] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()

  const onDownload = () => {
    try {
      setDownloading(true)

      const header = includeRole ? "Email,Role" : "Email"
      const csv = [
        header,
        ...(members || []).map((member) =>
          includeRole ? `${member.email},${member.role}` : member.email,
        ),
      ].join("\n")
      const blob = new Blob([csv], { type: "text/csv" })
      const url = URL.createObjectURL(blob)
      const downloadFilename = `Quilt-${group.name}-members.csv`
      try {
        const a = document.createElement("a")
        a.href = url
        a.download = downloadFilename
        a.click()
      } finally {
        URL.revokeObjectURL(url)
      }
      handleSuccess("Downloaded members to " + downloadFilename)
    } catch (error) {
      handleError({ error, prefix: "Couldn't download member list" })
    } finally {
      setDownloading(false)
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <Checkbox
        checked={includeRole}
        onChange={(e) => setIncludeRole(e.target.checked)}
      >
        Include Role
      </Checkbox>
      <Button
        type="default"
        loading={membersLoading}
        disabled={membersLoading || downloading}
        onClick={onDownload}
      >
        <DownloadIcon size={16} /> Download
      </Button>
    </div>
  )
}

interface Props {
  group: Group
  members: GroupMembership[] | undefined
  membersLoading: boolean
}
const DownloadMembersButton: React.FC<Props> = ({
  group,
  members,
  membersLoading,
}) => {
  return (
    <Popover
      content={
        <DownloadMembersForm
          group={group}
          members={members}
          membersLoading={membersLoading}
        />
      }
      title="Download Members"
      trigger="click"
    >
      <Button type="default">Download Members</Button>
    </Popover>
  )
}

export default DownloadMembersButton
