import Button from "antd/es/button"

import { getColumnLetter } from "../../sheets/utils"
import type { WorksheetRange } from "../../types/sheets"

interface Props {
  location?: WorksheetRange | null
  className?: string
  onClickLocation: (location: WorksheetRange) => void
}

const TitleCTA: React.FC<Props> = ({
  location,
  onClickLocation,
  className = "",
}) => {
  const locationLabel = location
    ? `${getColumnLetter(location.firstColIndex)}${location.firstRowIndex}`
    : undefined
  return (
    <div className={"flex items-center font-bold " + className}>
      {location && (
        <Button
          size="small"
          className="m-0 p-0 font-bold"
          type="link"
          onClick={() => onClickLocation(location)}
        >
          {locationLabel}
        </Button>
      )}
      <span className="ml-1">DISCUSSION</span>
    </div>
  )
}

export default TitleCTA
